import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpider } from '@fortawesome/free-solid-svg-icons'
import { useLoaderData } from 'react-router-dom'
import VulnerabilityModal from '../../components/VulnerabilityModal'
import VulnerabilitiesTable from '../../components/VulnerabilitiesTable'

const FactoryVulnerabilitiesExample = () => {
  const vulns = useLoaderData()
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="card shadow-sm p-0">
      <div className="card-header d-flex justify-content-between align-items-center bg-danger text-light">
        <span>
          <FontAwesomeIcon className='mx-1' icon={faSpider} /> <b className='mx-1'>Vulnerabilities</b>
        </span>
      </div>
      <div className="card-body py-0 px-2">
        {
          vulns.length > 0
            ? <VulnerabilitiesTable vulns={vulns} setCurrentVuln={setCurrentVuln} setIsOpen={setIsOpen}/>
            : <p className="text-muted p-4"><small>&#127881; Woo hoo! No vulnerabilities found! &#127881;</small></p>
        }
      </div>

      <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>

    </div>
  )
}

export default FactoryVulnerabilitiesExample
