import React, { useContext } from 'react'
import { Auth } from 'aws-amplify'
import AuthContext from '../context/auth'
import { Button } from 'react-bootstrap'

const LogoutButton = () => {
  const { setAuth } = useContext(AuthContext)
  const logOut = async () => {
    await Auth.signOut()
    setAuth('unauthorized')
  }

  return (
    <Button
      variant='danger'
      className="btn-block"
      onClick={() =>
        logOut()
      }
    >
      Log Out
    </Button>
  )
}

export default LogoutButton
