import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTerminal, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

class PrivateFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentYear: dayjs().year().toString() }
  }

  render() {
    return (
      <footer className="position-sticky py-2 text-white-50 w-100" style={{ backgroundColor: '#131240' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-lg-start">Copyright &copy; Traxiom 2022-{this.state.currentYear}</div>
            {/* <div className="col-lg-4 my-3 my-lg-0">
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-linkedin-in"></i></a>
            </div> */}
            <div className="col-lg-6 text-lg-end">
              <span className="badge bg-success text-light me-3"> <FontAwesomeIcon icon={faWindowMaximize}/> 2.0.0</span>
              <span className="badge bg-secondary text-light me-3"> <FontAwesomeIcon icon={faTerminal}/> 1.0.0</span>
              {/* <a className="link-light text-decoration-none me-3" href="https://traxiom.docs.apiary.io" target="_blank" rel="noreferrer">API</a> */}
              <a className="link-light text-decoration-none me-3" href="https://docs.traxiom.io" target="_blank" rel="noreferrer">Docs</a>
              <a className="link-light text-decoration-none me-3" href="https://docs.traxiom.io/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default PrivateFooter
