import http from './http'

export const fetchVuln = (id) => {
  return http(`/vuln/${id}`)
    .then(response => {
      const flat = flattenResponse(response.data)
      return flat
    })
    .catch(error => {
      console.log(error)
    })
}

export const flattenResponse = (response) => {
  const tvf = []
  for (const responseItem of response) {
    if (responseItem.tvf.length > 0) {
      for (const tvfItem of responseItem.tvf) {
        tvf.push(tvfItem)
      }
    }
  }
  return tvf
}

export const fetchVulnsForSource = (id, source) => {
  return http(`/vuln/${id}`)
    .then(response => {
      const flat = flattenResponseForSource(response.data, source)
      return flat
    })
    .catch(error => {
      console.log(error)
    })
}

export const flattenResponseForSource = (response, source) => {
  const tvf = []
  for (const responseItem of response) {
    if (responseItem.tvf.length > 0 && responseItem.sourceName === source) {
      for (const tvfItem of responseItem.tvf) {
        tvf.push(tvfItem)
      }
    }
  }
  return tvf
}
