import React from 'react'
import PropTypes from 'prop-types'

export const Tag = ({ title, color, textColor }) => {
  const customStyles = {
    color: `${textColor}`,
    backgroundColor: `${color}80`,
    border: `1px solid ${color}`
  }

  return (
    <>
      <span
        style={customStyles}
        className={`rounded-pill tag-pill ${title.length > 30 ? 'text-wrap' : ''}`}
      >
        {title}
      </span>
    </>
  )
}

Tag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string
}
