import React from 'react'
import PropTypes from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import { VulnListItem } from './VulnListItem'

export const SbomVulns = ({ vulns, ...props }) => {
  return (
    <ListGroup variant='flush' >
      { vulns && Object.entries(vulns).map(([key, value]) => (
        <VulnListItem key={key} packageName={key} vulnData={value} {...props} />
      ))
      }
    </ListGroup>
  )
}

SbomVulns.propTypes = {
  vulns: PropTypes.object
}
