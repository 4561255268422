import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tag } from '../Tags/Tag'
import TagTooltip from '../Tags/TagTooltip'
import tagDataHelper from '../../utils/tagDataHelper'

export const TagWrapCell = ({ row, orgTags }) => {
  const { tags: repoTags } = row
  const tags = useMemo(() => { return tagDataHelper({ repoTags, orgTags }) }, [repoTags, orgTags])
  return (
    <div className='custom-wrap'>
      {tags?.map((tag, index) => (
        <TagTooltip key={`${row.repo}-${index}`}>
          <Tag href={`/tags/${row.orgId}/labels/${tag.labelName}`} title={tag.labelName} {...tag} className='m-1' />
        </TagTooltip>
      ))}
    </div>
  )
}
TagWrapCell.propTypes = {
  row: PropTypes.object,
  orgTags: PropTypes.array
}
