import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Form, InputGroup, Alert, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import SubmitButton from '../SubmitButton'

function Step2({ username }) {
  const { register, handleSubmit, getValues, setError, clearErrors, formState: { errors } } = useForm({
    defaultValues: {
      code: '',
      password: '',
      passwordConfirmation: ''
    },
    criteriaMode: 'all',
    mode: 'onChange'
  })
  const [isVisiblePassword, setIsVisiblePassvord] = useState(false)
  const [loading, setLoading] = useState(false)
  const toggleVisibility = () => setIsVisiblePassvord(!isVisiblePassword)
  const navigate = useNavigate()

  const onSubmit = (data) => {
    clearErrors('root.serverError')
    forgotPasswordSubmit(data.code, data.password)
  }

  const resendCode = async () => {
    setLoading(true)
    try {
      await Auth.forgotPassword(username)
      setLoading(false)
    } catch (error) {
      console.log('resend code error', error)
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }

  async function forgotPasswordSubmit(code, password) {
    setLoading(true)
    try {
      // Confirm a new password using a confirmation Code
      await Auth.forgotPasswordSubmit(username, code, password)
      setLoading(false)
      navigate('/login', { replace: true })
    } catch (error) {
      console.log('error signing in', error)
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Container as={Form} onSubmit={handleSubmit(onSubmit)} fluid className='d-grid gap-3'>

        <Form.Group>
          <Form.Control
            className='custom-input__login'
            type='number'
            pattern='\d*'
            autoFocus
            placeholder='Code'
            isInvalid={errors.code}
            { ...register('code', {
              required: {
                value: true,
                message: 'Please enter valid code'
              }
            }) }
          />
          <Form.Control.Feedback type='invalid' >{errors.code?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <InputGroup hasValidation className='px-0'>
            <Form.Control
              className='custom-input__login'
              type={isVisiblePassword ? 'text' : 'password'}
              placeholder='New password'
              autoComplete='new-password'
              id='new-password'
              autoCapitalize='none'
              aria-describedby='new-password'
              isInvalid={errors.password}
              {...register('password', {
                required: 'Password is required!',
                minLength: {
                  value: 14,
                  message: 'Password should contain at least 14 symbols'
                },
                validate: (value) => {
                  return (
                    [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
                      pattern.test(value)
                    ) || 'Password must include lowercase, uppercase, number, and symbol characters'
                  )
                }
              })}
            />
            <Button onClick={toggleVisibility}><FontAwesomeIcon width={26} icon={isVisiblePassword ? faEyeSlash : faEye}/></Button>
            <Form.Control.Feedback type='invalid' >{errors.password?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <InputGroup hasValidation className='px-0'>
            <Form.Control
              className='custom-input__login'
              type={isVisiblePassword ? 'text' : 'password'}
              placeholder='Repeat new password'
              autoComplete='new-password'
              isInvalid={errors.passwordConfirmation}
              {...register('passwordConfirmation', {
                required: 'Please confirm password!',
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues()
                    return password === value || 'Passwords should match!'
                  }
                }
              })}
            />
            <Button onClick={toggleVisibility}><FontAwesomeIcon width={26} icon={isVisiblePassword ? faEyeSlash : faEye}/></Button>
            <Form.Control.Feedback type='invalid' >{errors.passwordConfirmation?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton
          rule={errors.code?.message || errors.password?.message || errors.passwordConfirmation?.message}
          loading={loading}
        >
          Submit
        </SubmitButton>

        <Form.Text onClick={resendCode} className='hoverable-link__login'>
          {loading &&
            <Spinner
              as='span'
              animation='border'
              size='sm'
              variant='secondary'
              className='me-2'
              role='status'
              aria-hidden='true'
            /> }
          Resend Code
        </Form.Text>
      </Container>
    </>
  )
}

Step2.propTypes = {
  username: PropTypes.string,
  resendCode: PropTypes.func
}

export default Step2
