import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'
import CustomProgress from '../components/CustomProgress'
import useCognitoAuth from '../hooks/useCognitoAuth'
import useSCM from '../hooks/useSCM'
import ButtonModal from '../components/ButtonModal'
import ReposTable from '../components/ReposTable'
import { DeleteModal } from '../components/DeleteModal/DeleteModal'
import { fetchReposAndTags, deleteRepos, fetchRepos } from '../services/repos'
import { useNavigate } from 'react-router-dom'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import ActionButton from '../components/MultiSelectFeatureComponents/TableOptions/ActionButton'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'

const FactoryRepos = () => {
  const cognito = useCognitoAuth()
  const scm = useSCM()
  const navigate = useNavigate()
  const [reposLoading, setReposLoading] = useState(true)
  const [repos, setRepos] = useState([])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [currentRepo, setCurrentRepo] = useState('')
  const orgTags = useRef([])
  const isGitlabServiceOff = !useFeatureIsOn('gitlab-get-service')
  const isGithubRepogetEnable = useFeatureIsOn('repoget-github')
  const isBitbucketRepogetEnable = useFeatureIsOn('repoget-bitbucket')

  const [editTable, setEditTable] = useState(false)
  const toggleEditTable = () => setEditTable(prevState => (!prevState))

  const handleFetchReposAndTags = async () => {
    if (!cognito.loading && cognito.orgId) {
      setReposLoading(true)
      const data = await fetchReposAndTags({ cognito })
      const { repos, tags, loading } = data
      orgTags.current = tags
      setRepos(repos)
      setReposLoading(loading)
    }
  }

  const onReposChangeHandler = async () => {
    if (!cognito.loading && cognito.orgId) {
      setReposLoading(true)
      try {
        const { data: repos } = await fetchRepos({ orgId: cognito.orgId })
        setRepos(repos)
        setReposLoading(false)
        setEditTable(false)
      } catch (error) {
        console.error('[onReposChangeHandler][error]', error)
      } finally {
        setReposLoading(false)
      }
    }
  }

  const deleteModalHandler = (repo) => {
    setCurrentRepo(repo)
    setShow(true)
  }
  const deleteRepo = async () => {
    handleClose()
    try {
      await deleteRepos({
        email: cognito.email,
        orgId: cognito.orgId,
        currentRepo: currentRepo
      })
      onReposChangeHandler()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleFetchReposAndTags()
  }, [cognito.loading, cognito.orgId])
  const isTokenExists = scm.bitbucketToken || scm.githubToken || scm.gitlabToken

  const navigateSelectRepo = (scmRoute) => {
    navigate(`/repositories/select-repos/${scmRoute}`)
  }

  return (
    <div className="card p-0 shadow-sm w-100">
      <div className="card-header ps-1 ps-sm-3 d-flex justify-content-between align-items-center flex-wrap">
        <div className={`${isTokenExists ? 'col-12' : 'col-10'} col-sm py-1 mw-fit`}>
          <div className='py-2 py-sm-0 px-2 px-sm-0'>
            <FontAwesomeIcon className='mx-1' icon={faCode} width={20} /> <b className='mx-1'>Organization SBoMs</b>
          </div>
        </div>
        <div className={`${isTokenExists ? 'col-12' : 'col-auto'} col-sm-auto d-flex justify-content-start justify-content-sm-between align-items-center`}>
          { isTokenExists
            ? <div className='d-flex flex-row align-items-center flex-grow-1'>
              {scm.githubToken &&
                <ButtonModal
                  // controls availability, depending on the readiness of repoget to handle the process with github repos
                  disabled={!isGithubRepogetEnable}
                  onClick={() => navigateSelectRepo('github')}
                  icon={faGithub}
                />
              }
              {scm.gitlabToken &&
                <ButtonModal
                  disabled={isGitlabServiceOff}
                  onClick={() => navigateSelectRepo('gitlab')}
                  icon={faGitlab}
                />
              }
              {scm.bitbucketToken &&
                <ButtonModal
                  // controls availability, depending on the readiness of repoget to handle the process with bitbucket repos
                  disabled={isBitbucketRepogetEnable}
                  onClick={() => navigateSelectRepo('bitbucket')}
                  icon={faBitbucket}
                />
              }
            </div>
            : null
          }
          <>
            { isTokenExists ? <div className="vr bg-dark bg-opacity-25 mx-3" /> : null }
            <div className="position-relative">
              <ActionButton title='Edit' icon={faPenToSquare} onClick={toggleEditTable} className={'dark-link-button'} variant='link' />
            </div>
          </>
        </div>
      </div>
      <div className="card-body py-0 px-2">
        {reposLoading
          ? <CustomProgress className='py-4'/>
          : <ReposTable
            deleteHandler={deleteModalHandler}
            repos={repos}
            orgTags={orgTags.current}
            cognito={cognito}
            edit={editTable}
            toggleEdit={toggleEditTable}
            updateTable={onReposChangeHandler}
          />
        }
      </div>
      <DeleteModal
        deleteItem={deleteRepo}
        handleClose={handleClose}
        currentItem={currentRepo}
        show={show}
      />
    </div>
  )
}

export default FactoryRepos
