import React from 'react'
import { Badge } from 'react-bootstrap'

export const severities = {
  critical: <Badge bg="danger">C</Badge>,
  high: <Badge bg="warning" text="dark">H</Badge>,
  moderate: <Badge bg="info">M</Badge>,
  low: <Badge bg="secondary">L</Badge>,
  info: <Badge bg="dark">I</Badge>
}
