import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Form, InputGroup, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import AuthContext from '../../context/auth'
import SubmitButton from './SubmitButton'

function CompleteNewPassword({ user, clearUser }) {
  const { setAuth } = useContext(AuthContext)
  const { email } = user.challengeParam.userAttributes

  const { register, handleSubmit, clearErrors, setError, formState: { errors }, getValues } = useForm({
    defaultValues: {
      username: email,
      password: '',
      passwordConfirmation: ''
    },
    criteriaMode: 'all',
    mode: 'onSubmit'
  })
  const [isVisiblePassword, setIsVisiblePassvord] = useState(false)
  const toggleVisibility = () => setIsVisiblePassvord(!isVisiblePassword)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    clearErrors('root.serverError')
    completeNewPassword(data.password)
  }

  async function completeNewPassword(newPassword) {
    // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
    setLoading(true)
    try {
      const authUser = await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
      )
      setLoading(false)
      // at this time the user is logged in if no MFA required
      setAuth(authUser)
      navigate('/', { replace: true })
    } catch (error) {
      // console.log('signing in [error]', error)
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }
  /*
    Passwords must contain at least one character from each of the following four categories:

    Lowercase letters (a-z)

    Uppercase letters (A-Z)

    Numbers (0-9)

    Non-alphanumeric characters (~!@#$%^&*_-+=`|\(){}[]:;"'<>,.?/)
  */

  return (
    <>
      <h3 className='login-header__login'>Create new password</h3>
      <Container as={Form} onSubmit={handleSubmit(onSubmit)} fluid className='d-grid gap-3'>
        <input
          style={{ display: 'none' }}
          type='email'
          autoComplete='email'
          spellCheck='false'
          autoCorrect='off'
          autoCapitalize='none'
          tabIndex='-1'
          aria-hidden='true'
          readOnly
          value={email}
          {...register('username')}
        />
        <Form.Group>
          {/* <Form.Label>New password</Form.Label> */}
          <InputGroup hasValidation className='px-0'>
            <Form.Control
              className='custom-input__login'
              type={isVisiblePassword ? 'text' : 'password'}
              placeholder='New password'
              isInvalid={errors.password}
              autoComplete='new-password'
              id='new-password'
              autoCapitalize='none'
              aria-autocomplete='list'
              aria-describedby='new-password'
              {...register('password', {
                required: 'Password is required!',
                minLength: {
                  value: 14,
                  message: 'Password should contain at least 14 symbols'
                },
                validate: (value) => {
                  return (
                    [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
                      pattern.test(value)
                    ) || 'Password must include lowercase, uppercase, number, and symbol characters'
                  )
                }
              })}
            />
            <Button onClick={toggleVisibility}><FontAwesomeIcon width={26} icon={isVisiblePassword ? faEyeSlash : faEye}/></Button>
            <Form.Control.Feedback type='invalid' >{errors.password?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          {/* <Form.Label>Repeat new password</Form.Label> */}
          <InputGroup hasValidation className='px-0'>
            <Form.Control
              className='custom-input__login'
              type={isVisiblePassword ? 'text' : 'password'}
              placeholder='Repeat new password'
              autoComplete='new-password'
              isInvalid={errors.passwordConfirmation}
              {...register('passwordConfirmation', {
                required: 'Please confirm password!',
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues()
                    return password === value || 'Passwords should match!'
                  }
                }
              })}
            />
            <Button onClick={toggleVisibility}><FontAwesomeIcon width={26} icon={isVisiblePassword ? faEyeSlash : faEye}/></Button>
            <Form.Control.Feedback type='invalid' >{errors.passwordConfirmation?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton
          rule={errors.password?.message || errors.passwordConfirmation?.message}
          loading={loading}
        >
          Confirm
        </SubmitButton>

        <Form.Text onClick={clearUser} className='hoverable-link__login mt-0'>Back to Sign In</Form.Text>
      </Container>
    </>
  )
}

CompleteNewPassword.propTypes = {
  user: PropTypes.object.isRequired,
  clearUser: PropTypes.func
}

export default CompleteNewPassword
