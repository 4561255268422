import React, { useContext } from 'react'
import { Tag } from './Tag'
import TagTooltip from './TagTooltip'
import { Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SbomTagsContext } from '../../hooks/SbomTagsContext'
import tagDataHelper from '../../utils/tagDataHelper'

export const SbomTagsInLine = ({ orgTags, orgId }) => {
  const repoTags = useContext(SbomTagsContext)
  const tags = tagDataHelper({ repoTags, orgTags })

  return (
    <Stack
      direction='horizontal'
      className='p-2 flex-wrap'
      gap={1}>
      {tags?.map((tag, index) => (
        <TagTooltip key={`${tag.labelName}-${index}`}>
          <Tag href={`/tags/${orgId}/labels/${tag.labelName}`} title={tag.labelName} {...tag}/>
        </TagTooltip>
      ))}
    </Stack>
  )
}

SbomTagsInLine.propTypes = {
  orgTags: PropTypes.array,
  orgId: PropTypes.string
}
