import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { useNavigate, useParams } from 'react-router-dom'
// import { fetchVuln } from '../utils/vulnHelpers'
import _ from 'lodash'
import SbomList from './SbomList'
import CustomProgress from './CustomProgress'
import VulnerabilityModal from './VulnerabilityModal'
import { fetchVulnsForSource } from '../utils/vulnHelpers'
import { SbomTabs } from './Sboms/SbomTabs'

function fetchVendorSbom(props) {
  const { vendor, orgId } = props
  return http('/vendor', 'get', {
    name: vendor,
    orgId: orgId
  })
    .then(response => {
      const sbom = response.data.sbom
      const prettySPDX = JSON.stringify(sbom, null, 2)
      return {
        sbom,
        prettySPDX
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export const VendorSbomDetail = () => {
  const navigate = useNavigate()
  const { vendor } = useParams()

  const cognito = useCognitoAuth()
  const [spdx, setSpdx] = useState()
  const [packages, setPackages] = useState()
  const [vulns, setVulns] = useState()
  const [reposLoading, setReposLoading] = useState(true)
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      Promise.all([
        fetchVendorSbom({
          vendor: vendor,
          orgId: cognito.orgId
        }),
        fetchVulnsForSource(cognito.orgId, vendor)
        // fetchVuln(cognito.orgId)
      ]).then((result) => {
        const [sboms, vulns] = result
        // const flattenVulns = flattenResponse(vulns, sboms.source || '')
        // const vulnsByName = _.keyBy(flattenVulns, 'packageName')
        const { prettySPDX, sbom } = sboms
        const vulnsByName = _.keyBy(vulns, 'packageName')
        setSpdx(prettySPDX)
        setVulns(vulnsByName)
        setPackages(sbom.packages)
        setReposLoading(false)
      })
    }
  }, [cognito.orgId, cognito.loading])

  return (
    <div className="card p-0 shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center bg-succes">
        <div className='col'>
          <FontAwesomeIcon
            onClick={() => navigate(-1)}
            className='btn btn-sm me-1'
            icon={faArrowLeftLong} />
          <span>
            <b className='mx-1'>{vendor}</b>
          </span>
        </div>
      </div>
      <div className="card-body">
        <SbomTabs
          idHelper={`sbom-tabs-${vendor}`}
          spdx={spdx}
        >
          {reposLoading
            ? <CustomProgress className='pt-4 pb-2'/>
            : packages
              ? <SbomList
                setCurrentVuln={setCurrentVuln}
                setIsOpen={setIsOpen}
                packages={packages}
                vulns={vulns}
              />
              : <p>No package manifests found in this repository.</p>}
        </SbomTabs>
      </div>
      <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div>
  )
}

VendorSbomDetail.propTypes = {
  vendor: PropTypes.any
}
