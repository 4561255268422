import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const NavItem = ({ route, icon, title, isCollapsed }) => {
  const renderTooltip = (props) => (
    <Tooltip id={`button-tooltip-${title}`} {...props}>
      {title}
    </Tooltip>
  )

  return (
    <>
      <OverlayTrigger
        trigger={isCollapsed ? ['hover', 'focus'] : false}
        placement='right'
        delay={{ show: 600, hide: 300 }}
        overlay={renderTooltip}
      >
        <NavLink to={route}
          className={'item'}
        >
          <FontAwesomeIcon icon={icon} className='item-icon' width={20} height={20}/>
          <span className='item-title'>{title}</span>
        </NavLink>
      </OverlayTrigger>
    </>
  )
}

NavItem.propTypes = {
  route: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  active: PropTypes.bool,
  isCollapsed: PropTypes.bool
}

export default NavItem
