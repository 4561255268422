import React from 'react'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// -- prevents api functions from being triggered if the service is not supported or does not exist --
export const ResolveSCM = ({ children }) => {
  const { scm: scmService } = useParams()
  const scmServiceVariants = ['github', 'gitlab', 'bitbucket']

  if (!scmServiceVariants.includes(scmService)) {
    return <Navigate to={'/repositories'} replace />
  }
  if (scmService === 'github') {
    return <IfFeatureEnabled feature='repoget-github'>{children || <Outlet />}</IfFeatureEnabled>
  }
  if (scmService === 'bitbucket') {
    return <IfFeatureEnabled feature='repoget-bitbucket'>{children || <Outlet />}</IfFeatureEnabled>
  }

  return children || <Outlet />
}

ResolveSCM.propTypes = {
  children: PropTypes.node
}
