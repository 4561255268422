import { React, useEffect, useState, useRef } from 'react'
import { Card, Button, Stack, FormCheck, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useLoaderData, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { triggerProcess, getNewRepos, addRepoToOrgCol, addRepoToSbomCol, searchNewRepos, getCachedRepos } from '../../services/repos'
import { floor } from 'lodash'
import { ProgressLoadingBar } from '../ProgressLoadingBar'
import { ServerSearchComponent } from './SearchComponent'
import NoDataComponent from './NoDataComponent'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { AddedLabel } from './AddedLabel'

const symbolAlert = <>All repositories have been added. &#x1F389;</>
const catchAlert = 'No repositories to add.'

const CustomProgress = () => (<div className='spinner-div'><Spinner animation="border" variant="dark" /></div>)

const FORMAT_DATE = 'MM/DD/YYYY hh:mm:ssa'
const INITIAL_PERPAGE = 50
export const paginationRowsPerPageOptions = [10, 25, 50, 100, 150, 200]

export function checkProgress(totalRepos, totalReposRead) {
  if (totalRepos === totalReposRead) {
    return 100
  }
  const roundedPercent = floor(totalReposRead / totalRepos * 100)
  return roundedPercent
}
export function paginationQueryHelper (page, perPage) {
  const skip = perPage * (page - 1)
  const params = skip === 0 && perPage === 10 ? undefined : { skip: skip, limit: perPage }
  return params
}

const SelectRepos = () => {
  const { scm, cognito } = useLoaderData()
  const [repos, setRepos] = useState([])
  const [createdOn, setCreatedOn] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(INITIAL_PERPAGE)
  const [page, setPage] = useState(1)
  const [message, setMessage] = useState(catchAlert)
  const [checkedRepos, setCheckedRepos] = useState([])
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [taskId, setTaskId] = useState(null)
  const [isServerPagination, setIsServerPagination] = useState(true)
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [resetSearch, setResetSearch] = useState(false)
  const navigate = useNavigate()
  const refreshRef = useRef(false)

  // --- progress in percents ---
  const [progressLoading, setProgressLoading] = useState({
    status: 'idle',
    value: null
  })
  // -- params.scm = github | gitlab | bitbucket --
  const { scm: scmService } = useParams()

  const scmRenderTitle = {
    github: 'GitHub',
    gitlab: 'GitLab',
    bitbucket: 'BitBucket'
  }

  const hostnameHelper = `${scmService}Hostname`
  const tokenHelper = `${scmService}Token`

  const addRepos = async () => {
    setLoading(true)
    for (const checkedRepo of checkedRepos) {
      try {
        await addRepoToOrgCol({
          orgId: cognito.orgId,
          repo: checkedRepo.location,
          userId: cognito.userId,
          repoId: checkedRepo.repoId,
          email: cognito.email,
          provider: scmService,
          hostname: scm[hostnameHelper] ? scm[hostnameHelper] : `https://${scmService}.com`
        })
      } catch (err) {
        console.log('repo:', err)
      }
      try {
        await addRepoToSbomCol({
          orgId: cognito.orgId,
          repo: checkedRepo.location,
          repoId: checkedRepo.repoId,
          token: scm[tokenHelper],
          userId: cognito.userId,
          provider: scmService,
          hostname: scm[hostnameHelper] ? scm[hostnameHelper] : `https://${scmService}.com`
        })
      } catch (err) {
        console.log('sbom:', err)
        continue
      }
    }
    setSearchParams(undefined)
    setClearSelectedRows(true)
    setIsServerPagination(true)
    await getReposCache(1, perPage)
    setCheckedRepos([])
    setResetSearch(true)
    setClearSelectedRows(false)
    setResetSearch(false)
  }
  const rowDisabledCriteria = row => row.added
  const columns = [
    {
      id: 'repos',
      name: <p className='fs-6 fw-bold m-0'>{scmRenderTitle?.[scmService]} Repositories</p>,
      selector: row => row.location
    },
    {
      id: 'added',
      selector: row => row.added,
      cell: row => row?.added && <AddedLabel />,
      grow: 0
    }
  ]
  const customStyles = {
    subHeader: {
      style: {
        padding: 0
      }
    },
    cells: {
      style: {
        fontSize: 16
      }
    }
  }
  const onGetBack = () => {
    setCheckedRepos([])
    navigate('/repositories')
  }

  const triggerRepos = async () => {
    refreshRef.current = true
    setLoading(true)
    setSearchParams(undefined)
    setResetSearch(true)
    setIsServerPagination(true)
    setClearSelectedRows(true)
    setCheckedRepos([])
    setResetPaginationToggle(!resetPaginationToggle)

    try {
      const response = await triggerProcess({ orgId: cognito.orgId, scmService })
      const { taskId: processTaskId } = response?.data

      if (response.status === 200 && processTaskId) {
        setTaskId(processTaskId)
        getFreshRepos(page, perPage, processTaskId)
      }
      setMessage(catchAlert)
    } catch (error) {
      console.error('in repos trigger [error]', error)
      setProgressLoading({ status: 'error', value: null })
      setMessage(catchAlert)
      setLoading(false)
    }
    setClearSelectedRows(false)
    setResetSearch(false)
    refreshRef.current = false
  }

  const getReposCache = async (page, perPage) => {
    setLoading(true)
    const params = paginationQueryHelper(page, perPage)
    try {
      const {
        data: { totalNumRepos, newRepos, status, createdOn, taskId }
      } = await getCachedRepos({ orgId: cognito.orgId, scmService, params })
      setCreatedOn(dayjs(createdOn).format(FORMAT_DATE))
      setTaskId(taskId)
      setRepos(newRepos)
      setTotalRows(totalNumRepos)
      setLoading(false)
      setProgressLoading({
        status,
        value: 100
      })
      if (newRepos?.length === 0 && status === 'success') {
        setMessage(symbolAlert)
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        return triggerRepos()
      }
      console.error('in repos GET cache [error]', error)
      setProgressLoading({ status: 'error', value: null })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (cognito.orgId) {
      getReposCache(page, perPage)
    }
  }, [cognito.orgId])

  const getFreshRepos = async (page, perPage, taskId) => {
    const params = paginationQueryHelper(page, perPage)
    setLoading(true)
    try {
      const {
        data: { totalNumRepos, totalNumReposRead, newRepos, status, createdOn }
      } = await getNewRepos({ orgId: cognito.orgId, taskId, params })
      setCreatedOn(dayjs(createdOn).format(FORMAT_DATE))
      if (status === 'in progress' || status === 'queued') {
        const percent = checkProgress(totalNumRepos, totalNumReposRead)
        return setProgressLoading({
          status,
          value: status === 'queued' ? null : percent
        })
      }
      if (status === 'success') {
        setRepos(newRepos)
        setTotalRows(totalNumRepos)
        setLoading(false)
        setProgressLoading({
          status,
          value: 100
        })
        if (newRepos?.length === 0) {
          setMessage(symbolAlert)
        }
      }
    } catch (error) {
      console.error('getFreshRepos [error]', error)
      setProgressLoading({ status: 'error', value: null })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (progressLoading.status === 'in progress' || progressLoading.status === 'queued') {
      const repeat = setInterval(() => {
        getFreshRepos(page, perPage, taskId)
      }, 2000)
      return () => clearInterval(repeat)
    }
  }, [progressLoading.status, progressLoading.value])

  const handleSelectChange = ({ selectedRows }) => {
    setCheckedRepos(selectedRows)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    if (isServerPagination) {
      getReposCache(page, newPerPage)
    }
    setPerPage(newPerPage)
  }
  const handlePageChange = (page) => {
    if (isServerPagination) {
      getReposCache(page, perPage)
    }
    setPage(page)
  }
  const onSearch = async (searchTerm) => {
    setLoading(true)
    setSearchParams({ search: searchTerm })
    try {
      const { data: { newRepos, numSearchResults } } = await searchNewRepos({ orgId: cognito?.orgId, taskId, searchTerm })
      setResetPaginationToggle(!resetPaginationToggle)
      setPage(1)
      setRepos(newRepos)
      setTotalRows(numSearchResults)
      setIsServerPagination(false)
    } catch (error) {
      console.error('Select repos [search error]', error)
    } finally {
      setLoading(false)
    }
  }

  const onClean = async () => {
    setSearchParams(undefined)
    setIsServerPagination(true)
    setClearSelectedRows(true)
    setCheckedRepos([])
    setResetPaginationToggle(!resetPaginationToggle)
    if (refreshRef.current === false) {
      await getReposCache(1, perPage)
    }
    setClearSelectedRows(false)
  }

  return (
    <Card
      className='p-0'>
      <Card.Header>
        <Stack direction="horizontal" gap={3}>
          <div className='mx-1 fw-bold'>
            Select {scmRenderTitle?.[scmService]} Repositories
          </div>
          <Button
            className='ms-auto shadow-none'
            variant='secondary'
            onClick={onGetBack}>
            Back
          </Button>
          <Button
            className="shadow-none"
            variant='success'
            disabled={!checkedRepos.length}
            onClick={addRepos}>
            Apply
          </Button>
        </Stack>
      </Card.Header>
      <Card.Body>
        <ProgressLoadingBar
          error={progressLoading.status === 'error'}
          queued={progressLoading.status === 'queued'}
          loading={progressLoading.value}
        />
        {progressLoading.status === 'success' && <Stack direction='horizontal' gap={2} className='mx-1 py-2 mb-2'>
          { createdOn?.length
            ? <>
              <div className='fw-bold'>Cache was created:</div>
              <div className='font-monospace'>{createdOn}</div>
            </>
            : null
          }
          <Button variant='outline-primary' onClick={triggerRepos} className='ms-auto'>
            <FontAwesomeIcon icon={faRefresh} width={24} />
            <span className='d-none d-md-inline-flex ms-2'>
              Refresh cache
            </span>
          </Button>
        </Stack>}
        <DataTable
          noDataComponent={
            <NoDataComponent message={message}>
              <Button variant='outline-primary' onClick={triggerRepos}>
                <FontAwesomeIcon icon={faRefresh} width={24} />
                <span className='ms-2'>
                  Refresh cache
                </span>
              </Button>
            </NoDataComponent>
          }
          noHeader
          subHeader={progressLoading.status === 'success'}
          subHeaderComponent={
            <ServerSearchComponent
              searchString={searchParams.get('search')}
              onSearch={onSearch}
              onClean={onClean}
              reset={resetSearch}
            />
          }
          progressPending={loading}
          progressComponent={<CustomProgress />}
          customStyles={customStyles}
          columns={columns}
          selectableRows
          selectableRowDisabled={rowDisabledCriteria}
          onSelectedRowsChange={handleSelectChange}
          selectableRowsComponent={FormCheck}
          data={repos}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          pagination
          paginationPerPage={INITIAL_PERPAGE}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationServer={isServerPagination}
          clearSelectedRows={clearSelectedRows}
        />
      </Card.Body>
    </Card>
  )
}

export default SelectRepos
