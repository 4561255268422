import { GrowthBook } from '@growthbook/growthbook-react'

// -- Growthbook Feature Flags SDK config settings --
const apiHost = process.env.REACT_APP_GROWTHBOOK_API_HOST || 'https://cdn.growthbook.io'
const prodClientKey = process.env.REACT_APP_PROD_GROWTHBOOK_CLIENT_KEY || 'sdk-UJWkIFs4blOvgGH'
const devClientKey = process.env.REACT_APP_DEV_GROWTHBOOK_CLIENT_KEY || 'sdk-9c0HeOG2fMQOpqXQ'

const clientKey = process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'test' ? devClientKey : prodClientKey

const growthbook = new GrowthBook({
  apiHost,
  clientKey,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key
    })
  }
})

export default growthbook
