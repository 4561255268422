import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCode } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

const OrgBomItem = (props) => {
  const { orgBom } = props
  const { orgId, packages, updatedOn } = orgBom
  const navigate = useNavigate()

  return (
    <>
      <tbody>
        <tr>
          <td style={{ width: '45%' }}>
            {orgId}
          </td>
          <td className='text-center' style={{ width: '10%' }}>
            <button
              className="btn"
              style={{ padding: 0, border: 'none', background: 'none' }}
              type="button"
              onClick={() => navigate(`/orgBom/${orgId}`)}
            >
              <FontAwesomeIcon icon={faFileCode} />
            </button>
          </td>
          <td className='text-center' style={{ width: '20%' }}>
            <span className="badge bg-success">{packages}</span>
          </td>
          <td style={{ width: '25%' }}>
            {dayjs(updatedOn).format('MM/DD/YYYY HH:mm:ss')}
          </td>
        </tr>
      </tbody>
    </>
  )
}

OrgBomItem.propTypes = {
  orgBom: PropTypes.any
}

export default OrgBomItem
