import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { ButtonTooltip } from './ButtonTooltip'

const ActionButton = ({ onClick, icon, title, className, variant = 'light', tooltipPlacement }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const CSSstyles = 'shadow-none'

  return (
    <ButtonTooltip title={title} placement={tooltipPlacement}>
      <Button
        variant={variant}
        size={isMobile ? 'sm' : ''}
        className={className ? CSSstyles.concat(' ', className) : CSSstyles}
        onClick={onClick}
      >
        {icon && <FontAwesomeIcon icon={icon} width={20} height={20} />}
      </Button>
    </ButtonTooltip>
  )
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object
  ]),
  title: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string
}

export default ActionButton
