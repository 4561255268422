import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useNavigate,
  useLocation
} from 'react-router-dom'
import VerifyEmail from './VerifyEmail'
import VerifyCode from './VerifyCode'

function VerifyUserAttritutes({ user }) {
  const [codeSent, setCodeSent] = useState('idle')
  const { email } = user.attributes

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const navigateToLocation = async (location) => navigate(location, { replace: true })

  if (codeSent === 'SUCCESS') {
    return (
      <VerifyCode from={from} navigate={navigateToLocation} />
    )
  }

  return (
    <VerifyEmail email={email} setCodeSent={setCodeSent} from={from} />
  )
}

VerifyUserAttritutes.propTypes = {
  setVerificationFalse: PropTypes.func,
  user: PropTypes.object
}

export default VerifyUserAttritutes
