import React from 'react'
import PropTypes from 'prop-types'
import { Col, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const MobileSideBar = ({ list }) => {
  return (
    <Col
      xs={12}
      className={'gx-0 mb-4'}
    >
      <Nav>
        {list.map((item) => (
          <NavLink to={item.route} key={item.title}>
            {({ isActive }) => (
              <div className={`nav-link sidebar-link position-relative ${isActive ? 'active' : ''}`}>
                {item.title}
              </div>
            )}
          </NavLink>
        ))}
      </Nav>
    </Col>
  )
}

MobileSideBar.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object)
}

export default MobileSideBar
