import React, { useState, useEffect, useRef } from 'react'
import useCognitoAuth from '../../hooks/useCognitoAuth'
import { Form, Button, InputGroup, Spinner, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { ErrorAlert } from '../Alerts/ErrorAlert'
import http from '../../utils/http'
import CustomProgress from '../CustomProgress'
import PublicUrlTable from './PublicURLTable'
import { deleteOss, fetchOss, fetchOssAndTags } from '../../services/oss'
import { DeleteModal } from '../DeleteModal'
import { useMediaQuery } from 'react-responsive'

export const PublicURL = () => {
  const cognito = useCognitoAuth()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [url, setUrl] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [buttonTitle, setButtonTitle] = useState('Confirm')
  const [ossLoading, setOssLoading] = useState(true)
  const [ossUrls, setOssUrls] = useState([])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [currentOssUrl, setCurrentOssUrl] = useState('')
  const orgTags = useRef([])

  const handleChange = (e) => {
    setErrorMessage('')
    setShowError(false)
    setButtonTitle('Confirm')
    setUrl(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage('')
    setShowError(false)
    setButtonTitle('Confirm')
    try {
      const timeStampEpoch = Date.now()
      const timeStamp = new Date(timeStampEpoch)
      const timeStampISO = timeStamp.toISOString()
      await http('/oss', 'put', {}, {
        orgId: cognito.orgId,
        url: url,
        userId: cognito.userId,
        email: cognito.email,
        addedOn: timeStampISO,
        provider: 'oss'
      })
      setButtonTitle('Success')
    } catch (error) {
      setShowError(true)
      setErrorMessage(error.response ? error.response.data.error : error.message)
      console.log(error)
    }
    try {
      const urlObject = new URL(url)
      const urlHostname = urlObject.hostname
      await http('/sbom', 'put', undefined, {
        orgId: cognito.orgId,
        url: url,
        userId: cognito.userId,
        provider: 'oss',
        hostname: urlHostname
      })
    } catch (error) {
      setShowError(true)
      setErrorMessage(error.message)
      console.log(error)
    } finally {
      setLoading(false)
      onReposChangeHandler()
    }
  }

  const onReposChangeHandler = async () => {
    if (!cognito.loading && cognito.orgId) {
      setOssLoading(true)
      try {
        const { data: oss } = await fetchOss({ orgId: cognito.orgId })
        setOssUrls(oss)
        setOssLoading(false)
      } catch (error) {
        console.log('[onReposChangeHandler][error]', error)
      } finally {
        setOssLoading(false)
      }
    }
  }

  const deleteModalHandler = (url) => {
    setCurrentOssUrl(url)
    setShow(true)
  }

  const deleteOssUrl = async () => {
    handleClose()
    try {
      await deleteOss({
        orgId: cognito.orgId,
        currentOssUrl: currentOssUrl
      })
      onReposChangeHandler()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchOssUrlsAndTags = async () => {
    if (!cognito.loading && cognito.orgId) {
      setOssLoading(true)
      const data = await fetchOssAndTags({ cognito })
      const { oss, tags, loading } = data
      orgTags.current = tags
      setOssUrls(oss)
      setOssLoading(loading)
    }
  }

  useEffect(() => {
    handleFetchOssUrlsAndTags()
  }, [cognito.loading, cognito.orgId])

  const SubmitButton = () => (
    <Button
      type='submit'
      className='loading-button text-white'
      disabled={loading}
      variant={buttonTitle === 'Success' ? 'success' : 'info'}
      onClick={onSubmit}
    >
      {loading ? <span><Spinner animation="border" variant="light" size='sm' /></span> : buttonTitle}
    </Button>
  )

  const InputUrl = () => (
    <Form.Control
      className='shadow-none'
      type="url"
      placeholder="https://github.com/userNameOrOrg/publicRepo"
      autoFocus
      value={url}
      onChange={handleChange}
    />
  )

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <span className="h2"><FontAwesomeIcon icon={faLink}/> Open Source Software</span>
      </div>
      <Stack direction='vertical' gap={3}>
        <div>Generate SBOMs and track vulnerabilities in open source software.</div>
        <Form onSubmit={onSubmit} className='my-2'>
          <Form.Group>
            <Form.Label>Public Repo URL</Form.Label>
            { isMobile
              ? <div className='text-center d-flex flex-column gap-3'>
                <InputUrl />
                <SubmitButton />
              </div>
              : <InputGroup>
                <InputGroup.Text id="basic-addon3">
                  URL
                </InputGroup.Text>
                <InputUrl />
                <SubmitButton />
              </InputGroup>}
          </Form.Group>
        </Form>
        <ErrorAlert show={showError} setShow={setShowError} message={errorMessage} className='mb-0'/>
        <div className='position-relative'>
          {ossLoading
            ? <CustomProgress className='py-4'/>
            : <PublicUrlTable
              deleteHandler={deleteModalHandler}
              publicUrls={ossUrls}
              orgTags={orgTags.current}
              updateTable={onReposChangeHandler}
              cognito={cognito}
            />
          }
        </div>
        <DeleteModal
          deleteItem={deleteOssUrl}
          handleClose={handleClose}
          currentItem={currentOssUrl}
          show={show}
        />
      </Stack>
    </>
  )
}
