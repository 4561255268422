import PropTypes from 'prop-types'
import http from '../utils/http'
import { fetchTags } from './tags'
import dayjs from 'dayjs'

export const fetchRepos = async ({ orgId }) => {
  try {
    const repos = await http(`/repo/${orgId}`)
    return repos
  } catch (error) {
    console.error('[fetchRepos][error]', error)
  }
}

fetchRepos.propTypes = {
  orgId: PropTypes.string
}

export const fetchReposAndTags = async ({ cognito }) => {
  try {
    if (!cognito.loading && cognito.orgId) {
      const response = await Promise.all([
        fetchRepos({ orgId: cognito.orgId }),
        fetchTags({ orgId: cognito.orgId })
      ])
      const [{ data: repos }, { data: tags }] = response
      return { repos, tags, loading: false }
    }
  } catch (error) {
    console.error('[useReposAndTags][error]', error)
    return { repos: [], tags: [], loading: false }
  }
}

fetchReposAndTags.propTypes = {
  cognito: PropTypes.any
}

export const deleteRepos = async ({ email, orgId, currentRepo }) => {
  try {
    return await Promise.all([
      http('/repo', 'delete', undefined, {
        email: email,
        orgId: orgId,
        repo: currentRepo
      }),
      http('/sbom', 'delete', undefined, {
        orgId: orgId,
        repo: currentRepo
      }),
      http('/vuln', 'delete', undefined, {
        sourceName: currentRepo
      })
    ])
  } catch (err) {
    return console.log(err)
  }
}

deleteRepos.propTypes = {
  email: PropTypes.string,
  currentRepo: PropTypes.string,
  orgId: PropTypes.string
}

export const deleteManyRepos = async ({ orgId, repos }) => {
  const data = {
    orgId,
    repos
  }

  const response = await Promise.all([
    http('/repo/multiple', 'delete', undefined, data),
    http('/sbom/multiple', 'delete', undefined, data),
    http('/vuln/multiple', 'delete', undefined, {
      sourceNames: repos
    })
  ])

  return response
}

deleteManyRepos.propTypes = {
  repos: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  orgId: PropTypes.string.isRequired
}

// --- repoget trigger refresh api --- //
// POST repo/:orgId/cache/retrieve/:scmService where scmService will be gitlab, github, or bitbucket
export const triggerProcess = async ({ orgId, scmService }) => {
  const response = await http(`/repo/${orgId}/cache/retrieve/${scmService}`, 'post', {}, {})
  // console.log('triggerProcess [taskId]', response.data)
  return response
}

triggerProcess.propTypes = {
  orgId: PropTypes.string.isRequired,
  scmService: PropTypes.oneOf(['gitlab', 'github', 'bitbucket']).isRequired
}

// --- repoget cache api --- //
//  GET /api/repo/:orgId/cache/scm/:scm_service_name
export const getCachedRepos = async ({ orgId, scmService, params }) => {
  const response = await http(`/repo/${orgId}/cache/scm/${scmService}`, 'get', params, {})
  // console.log('triggerProcess [taskId]', response.data)
  return response
}

getCachedRepos.propTypes = {
  orgId: PropTypes.string.isRequired,
  scmService: PropTypes.oneOf(['gitlab', 'github', 'bitbucket']).isRequired,
  params: PropTypes.shape({
    skip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

// --- repoget refreshed repos api --- //
export const getNewRepos = async ({ orgId, taskId, params }) => {
  const response = await http(`/repo/${orgId}/cache/${taskId}`, 'get', params, {})
  // console.log('getNewRepos [data.status]', response?.data?.status)
  return response
}

getNewRepos.propTypes = {
  orgId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    skip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

export const searchNewRepos = async ({ orgId, taskId, searchTerm }) => {
  const response = await http(`/repo/${orgId}/cache/search/${taskId}?search=${searchTerm}`)
  // console.log('searchNewRepos [response]', response)
  return response
}
searchNewRepos.propTypes = {
  orgId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string
}

// --- Adds a repository to an org’s collection --- //
// -- PUT /api/repo/ --
// Request Body: { orgId, repo, repoId, userId, email, addedOn, provider, hostname }

export const addRepoToOrgCol = async (data) => {
  const addedOn = dayjs().toISOString()
  const response = await http('/repo', 'put', {}, { ...data, addedOn })
  return response
}

addRepoToOrgCol.propTypes = {
  orgId: PropTypes.string.isRequired,
  repo: PropTypes.string.isRequired,
  repoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  hostname: PropTypes.string.isRequired
}

export const addRepoToSbomCol = async (data) => {
  const response = await http('/sbom', 'put', {}, data)
  return response
}
addRepoToSbomCol.propTypes = {
  orgId: PropTypes.string.isRequired,
  repo: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  provider: PropTypes.oneOf(['gitlab', 'github', 'bitbucket']).isRequired,
  hostname: PropTypes.string.isRequired
}

// --- Deletes a repository from an orgs repo collection --- //
// -- DELETE /api/repo --
// Request Body: { orgId, repo }
