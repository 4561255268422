import React from 'react'
import OrgBoM from '../components/OrgBom'

const FactoryOrgBom = () => {
  return (
    <OrgBoM />
  )
}

export default FactoryOrgBom
