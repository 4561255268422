import React from 'react'
import { Button, Col, Form, Row, Stack } from 'react-bootstrap'
import Select from 'react-select'
import ColorPicker from '../ColorPicker'
import PropTypes from 'prop-types'
import _, { map } from 'lodash'

export const ColorFormLayout = ({ children }) => (
  <Form.Group controlId="Color">
    <Form.Label>Color</Form.Label>
    {children}
  </Form.Group>
)

ColorFormLayout.propTypes = {
  children: PropTypes.node
}

export const ButtonsSection = ({ disabled, onCancel, onSubmit, editor }) => {
  return (
    <Stack direction='horizontal' className='justify-content-end'>
      <Button
        variant='secondary'
        className='me-2'
        onClick={onCancel}>Cancel</Button>
      <Button
        variant='success'
        disabled={disabled}
        onClick={onSubmit}>{editor ? 'Save' : 'Create'}</Button>
    </Stack>
  )
}
ButtonsSection.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  editor: PropTypes.bool
}

export const ColorSection = ({ handleChange, color, state }) => (
  <Row>
    <Col xs='auto'>
      <ColorPicker handleChange={handleChange} name='color' color={state.color}/>
    </Col>
    <Col>
      <Form.Control
        onChange={handleChange}
        type="text"
        value={color}
        placeholder={state.color}
        name='color'/>
    </Col>
  </Row>
)

ColorSection.propTypes = {
  handleChange: PropTypes.func,
  color: PropTypes.string,
  state: PropTypes.object
}

const colorVar = '--bs-primary'

export const styledBSTheme = (theme) => {
  return ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: `var(${colorVar})`,
      primary25: `rgba(var(${colorVar}-rgb), 0.25)`,
      primary50: `rgba(var(${colorVar}-rgb), 0.50)`,
      primary75: `rgba(var(${colorVar}-rgb), 0.75)`,
      danger: 'var(--bs-danger)',
      dangerLight: 'rgba(var(--bs-danger), 0.50)'
    }
  })
}

export const selectStyles = {
  control: (styles, state) => {
    return ({
      ...styles,
      boxShadow: state.isFocused ? `0 0 0 var(--bs-focus-ring-width) rgba(var(${colorVar}-rgb), 0.25)` : 'none'
    })
  },
  groupHeading: (styles) => ({
    ...styles,
    textTransform: 'capitalize',
    padding: '.6rem 1rem',
    lineHeight: '1',
    fontSize: '100%',
    borderBottom: '1px solid var(--bs-border-color-translucent)'
  })
}

const formatGroupLabel = (data) => {
  return (
    <div className='small text-secondary'>{data.label}</div>
  )
}

export function groupFormattedUsers(users) {
  return _.chain(users)
    .groupBy('role')
    .map(function(value, key) {
      return ({
        label: key,
        options: map(value, (user) => ({
          value: user.email,
          label: user.email,
          group: user.role
        }))
      })
    })
    .value()
}

export const SelectOwner = ({ label, name, value, options, handleSelectChange }) => {
  return (
    <Form.Group controlId={label.replaceAll(' ', '_')}>
      <Form.Label>{label}</Form.Label>
      <Select
        components={{ IndicatorSeparator: null }}
        formatGroupLabel={formatGroupLabel}
        options={groupFormattedUsers(options)}
        name={name}
        // unstyled
        styles={selectStyles}
        value={value}
        menuPlacement={'auto'}
        onChange={(newValue, secondArg) => handleSelectChange(newValue, secondArg.name)}
        theme={styledBSTheme}
      />
    </Form.Group>
  )
}

SelectOwner.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired
}
