import React from 'react'
import AuthNav from '../AuthNav'
import './styles.scss'
import { Navbar, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const PrivateNav = () => {
  return (
    <Navbar expand='lg' className='navbar-dark' id="privateNav" sticky='top'>
      <Container>
        <Navbar.Brand href="https://traxiom.io">
          <img src="/static/image/Final_Logo_light.svg" alt="Brand Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive">
          Menu
          <FontAwesomeIcon icon={faBars} className="ms-1" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarResponsive">
          <AuthNav />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default PrivateNav
