import PropTypes from 'prop-types'
import http from '../utils/http'

export const fetchVendors = ({ cognito, setVendors, setVendorsLoading }) => {
  if (!cognito.loading && cognito.orgId) {
    http(`/vendor/${cognito.orgId}`)
      .then(response => {
        setVendors(response.data)
        setVendorsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

fetchVendors.propTypes = {
  cognito: PropTypes.any,
  setVendors: PropTypes.func,
  setVendorsLoading: PropTypes.func
}

export const deleteVendors = async ({ orgId, vendor }) => {
  try {
    return await http('/vendor', 'delete', undefined, {
      orgId: orgId,
      name: vendor
    })
  } catch (error) {
    return console.log(error)
  }
}

deleteVendors.propTypes = {
  cognito: PropTypes.any,
  vendor: PropTypes.string
}
