import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

export const ErrorAlert = ({ show, setShow, message }) => {
  if (show) {
    return (
      <Alert variant="danger" className="text-center" onClose={() => setShow(false)} dismissible>
        {message}
      </Alert>
    )
  }
  return ''
}

ErrorAlert.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  message: PropTypes.string
}
