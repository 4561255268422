import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export const ButtonTooltip = ({ children, title, placement }) => {
  return (
    <OverlayTrigger
      placement={placement || 'top-end'}
      overlay={
        <Tooltip id={`tooltip-${title}`}>
          {title}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

ButtonTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  title: PropTypes.string,
  placement: PropTypes.string
}
