import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import { LoginFooter } from './LoginFooter'
import './login.scss'

const LOGIN_TITLE = 'Improve your Software Supply Chain security.'

export const PageLayout = () => {
  return (
    <>
      {/* <div className='background__login'/> */}
      <Container fluid className='h-100 px-0'>
        <Row className='h-100 gx-0'>
          <Col xs={12} lg={5} className='form-side-container__login gx-4'>
            <Link to={'https://traxiom.io'} style={{ zIndex: 9999 }}>
              <div className='logo__login'>
                <Image thumbnail className='border-0' src='/static/image/logo.png' alt='Traxiom' height={75}/>
              </div>
            </Link>
            <Container className='container-wrapper__login'>
              <Outlet />
            </Container>
            <LoginFooter />
          </Col>
          <Col lg className='d-none d-lg-flex justify-content-center align-items-center px-5 position-relative'>
            <div className='background__login' />
            <h3 className='display-3 pretty-text__login'>{LOGIN_TITLE}</h3>
          </Col>
        </Row>
      </Container>
    </>
  )
}
