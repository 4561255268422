import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { LoginPage, PageLayout, ResetPassword } from '../components/LoginPage'
import ProtectedRoute from '../components/ProtectedRoute'
import FactoryLayout from '../views/factoryLayout'
import Profile from '../views/profileLayout'

import { factoryRoutes } from './factoryRouter'
import { AuthProvider } from './AuthProvider'
import { profileRoutes } from './profileRouter'
import { ErrorPage404 } from '../views/ErrorPage404'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    errorElement: <ErrorPage404 />,
    children: [
      // -- routes available to all users --
      {
        element: <PageLayout />,
        children: [
          { path: '/login', element: <LoginPage /> },
          { path: '/reset_password', element: <ResetPassword /> }
        ]
      },
      // -- protected routes: only for authorized users --
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: '/',
            element: <FactoryLayout />,
            children: factoryRoutes
          },
          {
            path: 'profile',
            element: <Profile />,
            children: profileRoutes
          }
        ]
      }
    ]
  }
])
