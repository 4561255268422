import { useEffect, useState, useContext } from 'react'
import { Auth } from 'aws-amplify'
import AuthContext from '../context/auth'

const useCognitoAuth = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const { setAuth } = useContext(AuthContext)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUser(user)
      setLoading(false)
    }).catch(() => setAuth('unauthorized'))
  }, [])

  return (!loading && {
    userId: user.username,
    email: user.attributes.email,
    orgId: user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return !el.includes('admin') }),
    isAdmin: !!user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return el.includes('admin') }),
    adminGroup: user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return el.includes('admin') }),
    loading: loading
  })
}

export default useCognitoAuth
