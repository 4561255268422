import React, { useState, useEffect } from 'react'
import useCognitoAuth from '../../hooks/useCognitoAuth'
import http from '../../utils/http'
import SbomList from '../SbomList'
import _ from 'lodash'
import { fetchVulnsForSource } from '../../utils/vulnHelpers'
import { useParams, useNavigate } from 'react-router-dom'
import CustomProgress from '../CustomProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faTags } from '@fortawesome/free-solid-svg-icons'
import VulnerabilityModal from '../VulnerabilityModal'
import dayjs from 'dayjs'
import { Button, Dropdown } from 'react-bootstrap'
import { DeleteModal } from '../DeleteModal'
import { deleteOss } from '../../services/oss'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ErrorAlert } from '../Alerts/ErrorAlert'
import { SbomTabs } from '../Sboms/SbomTabs'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { SbomTagsContext } from '../../hooks/SbomTagsContext'
import { SelectTag } from '../Tags/SelectTag'
import { fetchTags } from '../../services/tags'
import { SbomTagsInLine } from '../Tags/SbomTagsInLine'
import Breadcrumbs from '../Breadcrumbs'
import { useMediaQuery } from 'react-responsive'
import { SelectTagsDrawer } from '../Tags/SelectTag/SelectTagsDrawer'

function fetchSbom(props) {
  const { repo, orgId } = props
  return http('/sbom', 'get', {
    repo: repo,
    orgId: orgId
  })
    .then(response => {
      if (!response.data) {
        return {
          sbom: {},
          prettySPDX: 'No package manifests found in this repository.',
          creationInfo: {
            createdAt: '',
            updatedAt: '',
            documentName: '',
            _id: ''
          },
          tags: []
        }
      }
      const sbom = response.data.sbom
      const creationInfo = {
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
        documentName: response.data.sbom.documentName,
        _id: response.data._id
      }
      const prettySPDX = JSON.stringify(sbom, null, 2)
      const tags = response.data.tags
      return {
        sbom,
        prettySPDX,
        creationInfo,
        tags
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export const PublicUrlDetails = () => {
  const { url } = useParams()
  const cleanUrl = url.replaceAll('%2F', '/')

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const [openSelectTags, setOpenSelectTags] = useState(false)

  const cognito = useCognitoAuth()
  const [spdx, setSpdx] = useState([])
  const [packages, setPackages] = useState([])
  const [vulns, setVulns] = useState([])
  const [ossLoading, setOssLoading] = useState(true)
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [creationData, setCreationData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [tags, setTags] = useState([])
  const [sbomTags, setSbomTags] = useState({})

  const tagsFeature = useFeatureIsOn('tags')

  const navigate = useNavigate()
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      Promise.all([
        fetchSbom({
          repo: cleanUrl,
          orgId: cognito.orgId
        }),
        fetchVulnsForSource(cognito.orgId, cleanUrl),
        fetchTags({ orgId: cognito.orgId })
      ]).then((result) => {
        const [sboms, vulns, orgTags] = result
        const { prettySPDX, sbom, creationInfo, tags: repoTags } = sboms
        const vulnsByName = _.keyBy(vulns, 'packageName')
        setTags(orgTags.data)
        setSbomTags(repoTags)
        setSpdx(prettySPDX)
        setVulns(vulnsByName)
        setCreationData(creationInfo)
        setPackages(sbom.packages)
        setOssLoading(false)
      })
    }
  }, [cognito.orgId, cognito.loading])

  const deleteRepo = async () => {
    setShowDeleteModal(false)
    try {
      await deleteOss({
        orgId: cognito.orgId,
        currentOssUrl: cleanUrl
      })
      navigate('/oss')
    } catch (error) {
      setShowAlert(true)
      console.log(error)
    }
  }

  const repoUrl = url.replaceAll('%2F', '/')
  const idHelper = `${creationData._id}-${creationData.documentName}`
  const parentRoutes = [
    {
      title: 'Home',
      href: 'https://traxiom.io'
    },
    {
      title: 'factory',
      href: '/'
    },
    {
      title: 'oss',
      href: '/oss'
    }
  ]

  return (
    <>
      <ErrorAlert
        show={showAlert}
        setShow={setShowAlert}
        message={`Sorry we can't delete ${cleanUrl} now. Please try again later.`}
      />
      <Breadcrumbs parents={parentRoutes} active={cleanUrl} />
      <div className="card p-0 shadow-sm">
        <SbomTagsContext.Provider value={sbomTags}>
          <div className="card-header d-flex flex-row justify-content-between align-items-center bg-succes">
            <div className='col text-truncate'>
              <FontAwesomeIcon
                onClick={() => navigate(-1)}
                className='btn btn-sm me-1'
                icon={faArrowLeftLong} />
              <span>
                <b className='mx-1'>{cleanUrl}</b>
              </span>
            </div>
            { isCompact
              ? <>
                <Dropdown align={'end'}>
                  <Dropdown.Toggle variant='light' />
                  <Dropdown.Menu>
                    { tagsFeature && !ossLoading
                      ? <Dropdown.Item className='d-flex flex-row align-items-center' onClick={() => setOpenSelectTags(true)}>
                        <FontAwesomeIcon icon={faTags} width={24} />
                        <Dropdown.ItemText>Tags</Dropdown.ItemText>
                      </Dropdown.Item>
                      : null
                    }
                    <Dropdown.Item className='d-flex flex-row align-items-center' onClick={() => setShowDeleteModal(true)}>
                      <FontAwesomeIcon icon={faTrashAlt} width={24} />
                      <Dropdown.ItemText>Delete</Dropdown.ItemText>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                { tagsFeature && !ossLoading
                  ? <SelectTagsDrawer
                    repo={repoUrl}
                    cognito={cognito}
                    tags={tags}
                    setSbomTags={setSbomTags}
                    show={openSelectTags}
                    setShow={setOpenSelectTags}
                    type='repo'
                  />
                  : null
                }
              </>
              : <>
                { tagsFeature && !ossLoading
                  ? <div className='d-flex flex-row justify-content-end'>
                    <SelectTag repo={repoUrl} cognito={cognito} tags={tags} updateOrgTags={setTags} setSbomTags={setSbomTags} type='repo' />
                  </div>
                  : null
                }
                <Button
                  variant='link'
                  className='link-body-emphasis'
                  onClick={() => setShowDeleteModal(true)}
                >
                  <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
              </>
            }
          </div>
          { tagsFeature && !ossLoading && sbomTags
            ? <SbomTagsInLine orgTags={tags} orgId={cognito.orgId} />
            : null }
        </SbomTagsContext.Provider>
        <div className="card-body">
          <div className='d-flex flex-row justify-content-between p-2 mb-2'>
            <div>
              <span className='fw-bold me-2'>Created: </span>
              <span className='font-monospace'>{creationData.createdAt ? dayjs(creationData.createdAt).format('MM/DD/YYYY HH:mm:ss') : ''}</span>
            </div>
            <div>
              <span className='fw-bold me-2'>Updated: </span>
              <span className='font-monospace'>{creationData.updatedAt ? dayjs(creationData.updatedAt).format('MM/DD/YYYY HH:mm:ss') : ''}</span>
            </div>
          </div>
          <SbomTabs
            idHelper={idHelper}
            spdx={spdx}
          >
            {ossLoading
              ? <CustomProgress className='pt-4 pb-2'/>
              : packages
                ? <SbomList
                  setCurrentVuln={setCurrentVuln}
                  setIsOpen={setIsOpen}
                  packages={packages}
                  vulns={vulns}
                />
                : <p>No package manifests found in this repository.</p>}
          </SbomTabs>
        </div>
        <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>
        <DeleteModal
          handleClose={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          currentItem={cleanUrl}
          deleteItem={deleteRepo}
        />
      </div>
    </>
  )
}
