import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { copyHandler } from '../../utils/copyHandler'
import { useMediaQuery } from 'react-responsive'
import DesktopApiKey from './DesktopVersion'
import MobileVersion from './MobileVersion'

const AddedApiKey = ({ apiKey: apiKeyData, setCurrent, toggleDelete }) => {
  const { apiKey, apiKeyId } = apiKeyData
  const [keyVisible, setKeyVisible] = useState(false)
  const toggleVisible = () => setKeyVisible(prevState => !prevState)
  const apiKeyRef = useRef()
  const isLaptopOrDesktop = useMediaQuery({ query: '(min-width: 800px)' })

  const copyKey = async () => {
    return await copyHandler(apiKeyRef.current.innerHTML)
  }
  const handleDelete = () => {
    setCurrent(apiKeyData)
    toggleDelete()
  }

  const visibilityKey = keyVisible ? 'visible' : 'hidden'
  const iconEye = keyVisible ? faEye : faEyeSlash

  if (isLaptopOrDesktop) {
    return (
      <DesktopApiKey
        apiKeyId={apiKeyId}
        apiKeyRef={apiKeyRef}
        apiKey={apiKey}
        handleDelete={handleDelete}
        visibilityKey={visibilityKey}
        iconEye={iconEye}
        toggleVisible={toggleVisible}
        copyKey={copyKey}
      />
    )
  }

  return (
    <MobileVersion
      apiKeyId={apiKeyId}
      apiKeyRef={apiKeyRef}
      apiKey={apiKey}
      handleDelete={handleDelete}
      visibilityKey={visibilityKey}
      iconEye={iconEye}
      toggleVisible={toggleVisible}
      copyKey={copyKey}
    />
  )
}

AddedApiKey.propTypes = {
  apiKey: PropTypes.object,
  setCurrent: PropTypes.func,
  toggleDelete: PropTypes.func
}

export default AddedApiKey
