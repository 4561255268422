import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Container,
  Form,
  Alert
} from 'react-bootstrap'
import {
  Link
} from 'react-router-dom'
import SubmitButton from '../SubmitButton'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

function VerifyCode({ from, navigate }) {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm({
    defaultValues: {
      code: ''
    },
    criteriaMode: 'all',
    mode: 'onSubmit'
  })

  const onSubmit = (data) => {
    clearErrors('root')
    verifyCode(data.code)
  }

  async function verifyCode(code) {
    setLoading(true)
    try {
      // To verify attribute with the code
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
      setLoading(false)
      navigate(from)
    } catch (error) {
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }

  return (
    <>
      <h6 className='login-header__login'>Enter the code</h6>
      <Container as={Form} onSubmit={handleSubmit(onSubmit)} fluid className='d-grid gap-3'>
        <p className='lead mb-0'>Enter the code that was sent to your email.</p>

        <Form.Group>
          <Form.Control
            className='custom-input__login'
            autoFocus
            placeholder='Code'
            isInvalid={errors.code?.type === 'required'}
            { ...register('code', {
              required: {
                value: true,
                message: 'Please enter valid code'
              }
            }) }
          />
          <Form.Control.Feedback type='invalid' >{errors.code?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Control.Feedback type="invalid">{errors?.code?.message}</Form.Control.Feedback>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton
          rule={errors?.code?.message}
          loading={loading}
        >
          Verify
        </SubmitButton>

        <Form.Text as={Link} to={from} className='hoverable-link__login'>Skip</Form.Text>
      </Container>
    </>
  )
}

VerifyCode.propTypes = {
  navigate: PropTypes.func,
  from: PropTypes.string
}

export default VerifyCode
