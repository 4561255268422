import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap'

const TokenInputRS = ({
  register,
  controlId,
  name,
  label = '',
  edit = false,
  rules = {},
  error = false,
  errorMessage = ''
}) => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(prevState => !prevState)

  return (
    <FormGroup controlId={controlId}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Button variant='light' className='border' size='sm' onClick={toggleVisible}>
          <FontAwesomeIcon width={26} icon={visible ? faEyeSlash : faEye}/>
        </Button>
        <FormControl type={visible ? 'text' : 'password'} {...register(name, rules)} isInvalid={error} disabled={!edit} className="shadow-none" />
      </InputGroup>
      <Form.Text className='text-danger'>{errorMessage}</Form.Text>
    </FormGroup>
  )
}

TokenInputRS.propTypes = {
  register: PropTypes.func.isRequired,
  controlId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  edit: PropTypes.bool,
  rules: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
}

export default TokenInputRS
