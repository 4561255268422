import http from '../utils/http'
import _ from 'lodash'

// -- function to trigger BE to create sboms export --
async function requestExport ({ sboms, orgId, email, vex }) {
  const filteredData = _.map(sboms, 'repo')
  const response = await http('/export', 'post', undefined, {
    orgId,
    email,
    sbomList: filteredData,
    vex
  })
  return response
}

export default requestExport
