import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { Python } from './Python'

export const Platforms = () => {
  const [currentTab, setCurrentTab] = useState('python')
  const langs = [
    {
      title: 'Python',
      name: 'python',
      content: <Python />
    }
    // {
    //   title: 'Second',
    //   name: 'second',
    //   content: <Component2 />
    // },
    // {
    //   title: 'Third',
    //   name: 'third',
    //   content: <Component3 />
    // }
  ]
  const handleSelect = (key) => setCurrentTab(key)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span className="h2"><FontAwesomeIcon icon={faBoxArchive} /> Platforms </span>
      </div>
      <Tabs
        id="platforms-tabs"
        activeKey={currentTab}
        onSelect={handleSelect}
        className="my-2"
      >
        { langs.map((lang) => (
          <Tab key={`lang-tab-${lang.name}`} eventKey={lang.name} title={lang.title}>
            {lang.content}
          </Tab>
        ))}
      </Tabs>
    </>
  )
}
