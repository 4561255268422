import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Stack, Form, Button } from 'react-bootstrap'
import { Tag } from '../Tag'
import TagTooltip from '../TagTooltip'
import dayjs from 'dayjs'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOutletContext } from 'react-router-dom'
import tagDataHelper from '../../../utils/tagDataHelper'
import { useMediaQuery } from 'react-responsive'

export const SbomByLabel = ({ data, isLast, setChecked, checked, setCurrent, onDelete }) => {
  const isCompact = useMediaQuery({ query: '(max-width: 600px)' })
  const { tags: repoTags, repo, createdAt, orgId } = data
  const formattedDate = createdAt ? dayjs(createdAt).format('MMMM DD, YYYY HH:mm') : 'unknown'
  const isChecked = checked.filter(value => value.repo === data.repo).length
  const context = useOutletContext()
  const { orgTags } = context
  const tags = tagDataHelper({ orgTags, repoTags })

  const handleDelete = () => {
    setCurrent(data.repo)
    onDelete()
  }

  const selectOne = (event) => {
    if (event.target.checked) {
      return setChecked([...checked, data])
    }
    const newCheckedRepos = checked.filter(value => value.repo !== data.repo)

    return setChecked(newCheckedRepos)
  }
  const provider = data.provider === 'oss' ? 'oss' : 'repositories'
  const repoUrl = data.provider === 'oss' ? repo.replaceAll('/', '%2F') : repo.replaceAll('/', '%2F')

  return (
    <div className={ isLast ? '' : 'border-bottom' }>
      <Row className='py-2 px-3'>
        <Col xs='auto'>
          <Form.Check.Input
            className='my-2'
            checked={isChecked}
            aria-label={`${repo}`}
            type='checkbox'
            id={`check-${repo.replaceAll('/', '_')}`}
            onChange={selectOne}
          />
        </Col>
        <Col xs>
          <div className='d-flex flex-row flex-wrap align-items-baseline'>
            <a href={`/${provider}/${repoUrl}`} className='h6 my-1 me-2 lh-1 text-break'>{repo}</a>
            <div className='custom-wrap mb-2'>
              {tags.map((tag, index) => (
                <TagTooltip key={`${repo}-${tag.labelName}-${index}`}>
                  <Tag title={tag.labelName} {...tag} href={`/tags/${orgId}/labels/${tag.labelName}`} className='me-2 my-1' />
                </TagTooltip>
              ))}
            </div>
          </div>
          <Stack direction='horizontal' gap={2}>
            <p className='small text-muted m-0'>Provider: <span className='link-secondary'>{data.provider}</span></p>
            <span className='vr' />
            <p className='small text-muted m-0'>Created: <i>{formattedDate}</i></p>
          </Stack>
        </Col>
        <Col xs='auto' className={`text-end${isCompact ? ' gx-0' : ''}`}>
          <Button
            variant='link'
            className={`text-dark shadow-none cursor-pointer${!isCompact ? ' me-3' : ''}`}
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrashCan} width={20} height={20} />
          </Button>
        </Col>
      </Row>
    </div>
  )
}

SbomByLabel.propTypes = {
  data: PropTypes.object,
  checkbox: PropTypes.node,
  isLast: PropTypes.bool,
  cognito: PropTypes.object,
  setChecked: PropTypes.func,
  checked: PropTypes.any,
  setCurrent: PropTypes.func,
  onDelete: PropTypes.func
}
