import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Options, SearchField } from './index'
import { OffcanvasBody } from 'react-bootstrap'

const SelectTagForMany = ({ orgTags, currentTag, setCurrentTag }) => {
  const [filterText, setFilterText] = useState('')

  const filteredItems = orgTags?.filter(
    item => item?.labelName && item?.labelName.toLowerCase().includes(filterText.toLowerCase())
  )

  const handleChange = (newValue) => {
    return setCurrentTag(newValue)
  }

  return (
    <>
      <SearchField filterText={filterText} setFilterText={setFilterText} />
      <OffcanvasBody className='px-0'>
        <div className='d-flex flex-column'>
          <Options data={filteredItems} onSelect={handleChange} selected={currentTag} />
        </div>
      </OffcanvasBody>
    </>
  )
}

SelectTagForMany.propTypes = {
  orgTags: PropTypes.arrayOf(PropTypes.object),
  currentTag: PropTypes.object,
  setCurrentTag: PropTypes.func
}

export default SelectTagForMany
