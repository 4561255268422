import React, { useState, useEffect, useMemo, useCallback } from 'react'
import '../../custom.css'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGithub, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'
import { faFileCode, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import FilterComponent from '../FilterComponent'
import { Button, FormCheck } from 'react-bootstrap'
import { TagsCell, customStyles, center } from '../TablesSharedComponents'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useMediaQuery } from 'react-responsive'
import { isEmpty, map } from 'lodash'
import { TableActions } from '../MultiSelectFeatureComponents'
import { SelectTagDrawer } from '../Tags/SelectTagDrawer'
import { SelectTagForMany } from '../Tags/SelectTagForMany'
import { DeleteItemsModal } from '../DeleteModal'
import { putTagsForRepos, removeTagsFromRepos } from '../../services/tags'
import { deleteManyOss } from '../../services/oss'
import { ErrorNotification } from '../Alerts'

const PublicUrlTable = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const { publicUrls, orgTags, deleteHandler, updateTable, cognito } = props
  const navigate = useNavigate()
  const tagsFeatureIsEnabled = useFeatureIsOn('tags')

  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [expandOptions, setExpandOptions] = useState(false)
  const [selectedRepos, setSelectedRepos] = useState([])
  const [currentTag, setCurrentTag] = useState({})
  const [openApplyTag, setOpenApplyTags] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [error, setError] = useState({
    message: '',
    type: 'idle'
  })
  const showError = (type, message) => setError({
    message: message,
    type: type
  })
  const hideError = () => setError({
    message: '',
    type: 'idle'
  })

  const toggleApplyTags = () => setOpenApplyTags(prevState => !prevState)
  const toggleDeleteModal = () => {
    setOpenDeleteModal(prevState => !prevState)
    hideError()
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRepos(state.selectedRows)
  }, [])

  const reposList = useMemo(() => map(selectedRepos, (repo) => (repo.url)), [selectedRepos])

  useEffect(() => {
    if (selectedRepos.length > 0 && expandOptions === false) {
      return setExpandOptions(true)
    }
    if (selectedRepos.length === 0) {
      return setExpandOptions(false)
    }
  }, [selectedRepos.length])

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  const filteredItems = publicUrls.filter(
    item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())
  )

  const tableStyles = useMemo(() => {
    return customStyles({ isCompact })
  }, [isCompact])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value)
        }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle])

  const columns = [
    {
      id: 'publicUrls',
      name: 'Sort by url',
      minWidth: '300px',
      grow: 2,
      selector: row => row.url,
      sortable: true
    },
    {
      id: 'tags',
      grow: 2,
      minWidth: '200px',
      selector: row => row.repoId,
      cell: row => orgTags && tagsFeatureIsEnabled ? <TagsCell row={row} orgTags={orgTags} /> : null
    },
    {
      id: 'details',
      width: 'min-content',
      style: center,
      cell: row => (
        <Button
          variant='link'
          className='link-dark'
          onClick={() => navigate(`/oss/${row.url.replaceAll('/', '%2F')}`)}
        >
          <FontAwesomeIcon icon={faFileCode} />
        </Button>
      )
    },
    {
      id: 'delete',
      width: 'min-content',
      cell: row => (
        <Button
          variant="link"
          className='link-body-emphasis'
          onClick={() => {
            deleteHandler(row.url)
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      ),
      style: center
    }
  ]

  const handleApplyTag = async () => {
    try {
      await putTagsForRepos({
        orgId: cognito.orgId,
        repos: reposList,
        tag: currentTag
      })
      toggleApplyTags()
      setClearSelectedRows(true)
      updateTable()
    } catch (error) {
      console.log('Apply tags error:', error)
      showError('tags', error?.message)
    }
  }

  const handleRemoveTag = async () => {
    try {
      await removeTagsFromRepos({
        orgId: cognito.orgId,
        repos: reposList,
        tag: currentTag
      })
      toggleApplyTags()
      setClearSelectedRows(true)
      updateTable()
    } catch (error) {
      console.log('Remove tags error:', error)
      showError('tags', error?.message)
    }
  }

  const handleDeleteRepos = async () => {
    try {
      await deleteManyOss({
        orgId: cognito.orgId,
        urls: reposList
      })
      toggleDeleteModal()
      setClearSelectedRows(true)
      updateTable()
    } catch (error) {
      console.log('deleteManyOss error:', error)
      showError('delete', error?.message)
    }
  }

  const clearStates = () => {
    setCurrentTag({})
    toggleApplyTags()
  }

  return (
    <>
      <TableActions
        show={expandOptions}
        selectedRows={selectedRepos}
        toggleApplyTags={toggleApplyTags}
        toggleDeleteModal={toggleDeleteModal}
        oss
      />
      <DataTable
        title='OSS'
        noHeader
        keyField='url'
        defaultSortFieldId={'url'}
        customStyles={tableStyles}
        columns={columns}
        data={filteredItems}
        selectableRows={true}
        selectableRowsComponent={FormCheck}
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleRowSelected}
        responsive
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
      <>
        <SelectTagDrawer
          show={openApplyTag}
          onHide={toggleApplyTags}
          actions={
            <>
              <Button variant='outline-danger' onClick={handleRemoveTag} disabled={isEmpty(currentTag)}>
                Remove tag
              </Button>
              <Button variant='outline-success' onClick={handleApplyTag} disabled={isEmpty(currentTag)}>
                Apply tag
              </Button>
              <Button variant='link' className='ms-auto dark-link-button shadow-none' onClick={clearStates}>
                Cancel
              </Button>
            </>
          }
        >
          <SelectTagForMany
            orgTags={orgTags}
            selectedRepos={selectedRepos}
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
          />
        </SelectTagDrawer>
        <DeleteItemsModal
          show={openDeleteModal}
          handleClose={toggleDeleteModal}
          deleteHandler={handleDeleteRepos}
          itemsType='selected repos'
          currentItems={reposList}
          showError={error.type === 'delete'}
          // errorMessage={error.message}
        />
        <ErrorNotification
          show={error.type === 'tags'}
          onHide={hideError}
          // message={error.message}
        />
      </>
    </>
  )
}

PublicUrlTable.propTypes = {
  publicUrls: PropTypes.array,
  orgTags: PropTypes.array,
  deleteHandler: PropTypes.func,
  updateTable: PropTypes.func,
  cognito: PropTypes.any
}

export default PublicUrlTable
