import React from 'react'
import { InfoAlert } from '../components/InfoAlert'
import { Outlet } from 'react-router-dom'
import { faSpider, faCode, faSitemap, faTruck, faLink, faTags } from '@fortawesome/free-solid-svg-icons'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import _ from 'lodash'
import PrivateLayout from './PrivateLayout'

const FactoryLayout = () => {
  const visibleOSSFeature = useFeatureIsOn('public-repo-from-url')
  const visibleTags = useFeatureIsOn('tags')
  const newRouterExample = useFeatureIsOn('new-router-feature')

  const list = [
    {
      title: 'Repositories',
      icon: faCode,
      route: '/repositories' || '/',
      enabled: true
    },
    {
      title: 'Vendors',
      icon: faTruck,
      route: '/vendors',
      enabled: true
    },
    {
      title: 'OSS',
      icon: faLink,
      route: '/oss',
      enabled: visibleOSSFeature
    },
    {
      title: 'OrgBom',
      icon: faSitemap,
      route: '/orgBom',
      enabled: true
    },
    {
      title: 'Vulnerabilities',
      icon: faSpider,
      route: '/vulnerabilities',
      enabled: true
    },
    {
      title: 'Tags',
      icon: faTags,
      route: '/tags',
      enabled: visibleTags
    },
    {
      title: 'Secret Vuln Route',
      icon: faSpider,
      route: '/vulnerabilities_2',
      enabled: newRouterExample
    }
  ]
  const filteredList = _.filter(list, { enabled: true })

  return (
    <>
      <PrivateLayout list={filteredList}>
        <InfoAlert />
        <div className="mx-auto">
          <Outlet />
        </div>
      </PrivateLayout>
    </>
  )
}

export default FactoryLayout
