import http from '../utils/http'
import { get } from 'lodash'

export const fetchUsers = async (group) => {
  const { data } = await http(`/user/${group}`)

  return get(data, 'data.Users', [])
}

export const preparedUsersData = async (cognito) => {
  const users = await fetchUsers(cognito.orgId)
  const admins = await fetchUsers(cognito.adminGroup)

  if (!!users && !!admins) {
    const attributes = []
    for (const attribute of users) {
      const admin = admins.filter(el => el.Username === attribute.Username)
      attributes.push(
        {
          email: attribute.Attributes.find(el => el.Name === 'email').Value,
          userId: attribute.Username,
          enabled: attribute.Enabled ? 'Yes' : 'No',
          status: attribute.UserStatus,
          role: admin.length > 0 ? 'Admin' : 'Operator'
        }
      )
    }
    return attributes
  }
}
