import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import NavItem from './NavItem'
import { Col } from 'react-bootstrap'

const SideBar = ({ list }) => {
  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapsed = () => setCollapsed(prevState => (!prevState))
  const isCollapsed = collapsed ? ' collapsed' : ''
  return (
    <Col
      xs={'auto'}
      className={`gx-0 menu-wrapper${isCollapsed}`}
    >
      <>
        {list.map((item) => (
          <NavItem key={`nav-item-${item.title}`} {...item} isCollapsed={collapsed} />
        ))}
        <div
          className='p-4 d-flex flex-row align-items-center justify-content-center collapse-toggle-wrapper'
          onClick={toggleCollapsed}
        >
          <FontAwesomeIcon
            className={`d-flex align-items-center justify-content-center text-decoration-none toggle-sidebar${isCollapsed}`}
            icon={faChevronLeft}
          />
        </div>
      </>
    </Col>
  )
}

SideBar.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object)
}

export default SideBar
