import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import useCognitoAuth from '../hooks/useCognitoAuth'
import CustomProgress from './CustomProgress'
import http from '../utils/http'

const Notifications = () => {
  const cognito = useCognitoAuth()
  const [notifications, setNotifications] = useState({})
  const [notifsLoading, setNotifsLoading] = useState(true)
  const [notifsChange, setNotifsChange] = useState(0)

  const onNotifChangeHandler = (e) => {
    http('/notifications', 'patch', undefined, {
      userId: cognito.userId,
      integration: e.target.id,
      event: e.target.value,
      eventValue: e.target.checked
    })
      .then(response => {
        setNotifsChange(notifsChange + 1)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  useEffect(() => {
    if (!cognito.loading && cognito.userId) {
      http(`/notifications/${cognito.userId}`)
        .then(response => {
          setNotifications(response.data)
          setNotifsLoading(false)
        })
        .catch(error => {
          console.log('Notifications [error]', error.message)
          setNotifsLoading(false)
        })
    }
  }, [cognito.loading, cognito.userId, notifsChange])

  // useEffect(() => {
  //   console.log(JSON.stringify(notifications, null, 2))
  // }, [notifications])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span className="h2"><FontAwesomeIcon icon={faBell} /> Notifications</span>
      </div>
      {notifsLoading
        ? <CustomProgress className='align-items-center min-heigth-200' />
        : notifications.length
          ? <table className="table">
            <tbody>
              <tr>
                <td></td>
                <td><FontAwesomeIcon icon={faEnvelope} /></td>
              </tr>
              <tr>
                <td>Org Repo Added</td>
                <td>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onClick={onNotifChangeHandler} value="orgRepoAdded" id="email" defaultChecked={notifications.email.orgRepoAdded} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Org Repo Removed</td>
                <td>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onClick={onNotifChangeHandler} value="orgRepoRemoved" id="email" defaultChecked={notifications.email.orgRepoRemoved} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          : null
      }
    </>
  )
}

export default Notifications
