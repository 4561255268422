import React, { useState } from 'react'
import LoginForm from './LoginForm'
import CompleteNewPassword from './CompleteNewPassword'
import { isEmpty } from 'lodash'
import VerifyUserAttritutes from './VerifyUserAttributes'

function LoginPage() {
  const [userFirstSession, setUserFirstSession] = useState({})
  const [verification, setVerification] = useState({})

  if (!isEmpty(userFirstSession)) {
    return <CompleteNewPassword user={userFirstSession} clearUser={() => setUserFirstSession({})} />
  }

  if (!isEmpty(verification)) {
    return <VerifyUserAttritutes user={verification} setVerificationFalse={() => setVerification({})} />
  }

  return (
    <LoginForm setUserFirstSession={setUserFirstSession} setVerification={setVerification} />
  )
}

export default LoginPage
