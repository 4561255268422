import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import HostInputRS from './HostInputRS'
import TokenInputRS from './TokenInputRS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faFloppyDisk, faPen } from '@fortawesome/free-solid-svg-icons'
import { commonRules } from './formRules'
import { isEmpty } from 'lodash'

const GitLabForm = ({ data: { hostname, token }, response: { status: response, message }, saveChanges, repo = 'gitlab' }) => {
  const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    defaultValues: {
      token,
      hostname
    }
  })
  const [edit, setEdit] = useState(false)

  const toggleEdit = () => setEdit(!edit)

  const onSubmit = useCallback((values) => {
    setEdit(false)
    return saveChanges({ repo: repo, data: values })
  }, [])

  const onReset = () => {
    reset(
      { token, hostname },
      { keepDefaultValues: true }
    )
  }
  const onError = () => {
    if (!isEmpty(errors)) {
      setEdit(true)
    }
  }
  useEffect(() => {
    onError()
  }, [errors])

  const repoTitle = {
    gitlab: 'GitLab',
    bitbucket: 'Bitbucket'
  }

  return (
    <Stack as={'form'} gap={3} onSubmit={handleSubmit(onSubmit)}>
      <p className="lead mb-1">Set your {repoTitle[repo]} hostname (for self-hosted {repoTitle[repo]}), e.g. https://{repo}.example.com</p>
      <HostInputRS
        controlId={`${repo}-hostname-control`}
        label='Hostname'
        register={register}
        name='hostname'
        edit={edit}
        rules={commonRules.hostname}
        error={Boolean(errors?.hostname)}
        errorMessage={errors?.hostname?.message}
        response={response}
        saveHandler={handleSubmit(onSubmit)}
      />
      <Alert show={response === 'error' && !!message?.length} variant='danger'>{message}</Alert>
      <TokenInputRS
        controlId={`${repo}-token-control`}
        register={register}
        edit={edit}
        rules={commonRules.token}
        error={Boolean(errors?.token)}
        errorMessage={errors?.token?.message}
        name='token'
        label='Token'
        saveHandler={handleSubmit(onSubmit)}
      />
      <Stack direction='horizontal' gap={3} className='py-2'>
        <Button type='button' variant='outline-dark' onClick={toggleEdit}>
          <FontAwesomeIcon icon={faPen} width={24} /><span className='ms-2 pe-2'>Edit</span>
        </Button>
        <Button variant='primary' type='submit'>
          <FontAwesomeIcon icon={faFloppyDisk} width={24} /><span className='ms-2 pe-2'>Save</span>
        </Button>
        {isDirty &&
          <Button variant='link' onClick={onReset}>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Button>}
      </Stack>
    </Stack>
  )
}

GitLabForm.propTypes = {
  data: PropTypes.shape({
    hostname: PropTypes.string,
    token: PropTypes.string
  }),
  repo: PropTypes.string,
  response: PropTypes.shape({
    status: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }),
  saveChanges: PropTypes.func
}

export default GitLabForm
