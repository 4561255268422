import React, { useEffect, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import useCognitoAuth from '../hooks/useCognitoAuth'
import UserTable from './UserTable'
import AddUser from './AddUserModal'
import CustomProgress from './CustomProgress'
import { Button, Container, Stack } from 'react-bootstrap'
import { UserManagementModal } from './UserManagementModal'
import UsersContext from '../hooks/UserManagementContext'
import { preparedUsersData } from '../services/users'

const UserManagement = () => {
  const cognito = useCognitoAuth()
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [showManageModal, setShowManageModal] = useState(false)
  const [currentUser, setCurrentUser] = useState({
    email: 'mock email',
    role: '',
    enabled: ''
  })
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const toggleShowAddUserModal = () => setShowAddUserModal(prevState => !prevState)

  const toggleManageModal = () => setShowManageModal(prevState => !prevState)

  const prepareData = async () => {
    setDataLoading(true)
    try {
      const attributes = await preparedUsersData(cognito)
      setData(attributes)
      setDataLoading(false)
    } catch (error) {
      console.log(error.response)
      setDataLoading(false)
    }
  }

  const value = useMemo(
    () => ({ currentUser, setCurrentUser, showManageModal, toggleManageModal, prepareData }),
    [currentUser, showManageModal]
  )

  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      prepareData()
    }
  }, [cognito.loading, cognito.adminGroup, cognito.orgId])

  return (
    <UsersContext.Provider value={value}>
      <Stack direction="horizontal" gap={3}>
        <h2><FontAwesomeIcon icon={faUsers} /> Users</h2>
        <div className='ms-auto'>
          <Button
            variant='info'
            className="text-white"
            onClick={toggleShowAddUserModal}
          >
            Add New User
          </Button>
        </div>
      </Stack>
      <Container fluid className='mt-2 overflow-auto'>
        {dataLoading && !!data
          ? <CustomProgress />
          : <UserTable tableData={data} />
        }
      </Container>
      <UserManagementModal />
      <AddUser onUserChange={prepareData} show={showAddUserModal} toggleShow={toggleShowAddUserModal} />
    </UsersContext.Provider>
  )
}

export default UserManagement
