import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { CopyApiKeyButton } from './CopyApiKeyButton'
import { Button, ListGroup, Stack } from 'react-bootstrap'

const MobileVersion = ({ apiKeyId, apiKeyRef, apiKey, visibilityKey, iconEye, toggleVisible, copyKey, handleDelete }) => {
  return (
    <ListGroup.Item as='div' className='px-0 py-2'>
      <p className='mb-2'>{apiKeyId}</p>
      <div className={'api-key-style mobile mb-2'}>
        <pre
          ref={apiKeyRef}
          style={{ visibility: visibilityKey }}
        >
          {apiKey}
        </pre>
      </div>
      <Stack direction='horizontal' className='justify-content-end mb-2'>
        <Button
          variant='danger'
          className="ms-0 me-auto"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrashAlt} width={24} height={24}/>
        </Button>
        <Button
          variant={'light'}
          className='me-3 border'
          onClick={toggleVisible}
        >
          <FontAwesomeIcon icon={iconEye} width={24} height={24} />
        </Button>
        <CopyApiKeyButton
          copyKey={copyKey}
          variant={'light'}
          className='border'
        />
      </Stack>
    </ListGroup.Item>
  )
}

MobileVersion.propTypes = {
  apiKeyId: PropTypes.string,
  apiKeyRef: PropTypes.object,
  apiKey: PropTypes.string,
  visibilityKey: PropTypes.string,
  iconEye: PropTypes.object,
  toggleVisible: PropTypes.func,
  copyKey: PropTypes.func,
  handleDelete: PropTypes.func
}

export default MobileVersion
