import axios from 'axios'
import { Auth } from 'aws-amplify'

const http = async (endpoint, method = 'get', params = undefined, data = undefined) => {
  try {
    const baseUrl = process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'test' ? 'dev.' : ''
    const session = await Auth.currentSession()

    return axios({
      url: `https://api.${baseUrl}traxiom.io/api${endpoint}`,
      method: method,
      headers: {
        Authorization: session.getAccessToken().getJwtToken()
      },
      params: params,
      data: data
    })
  } catch (err) {
    return window.location.reload()
  }
}

export default http
