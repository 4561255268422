import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { Button, Form, FormCheck, FormGroup, Modal } from 'react-bootstrap'

const AddUser = ({ show, toggleShow, onUserChange }) => {
  const cognito = useCognitoAuth()
  const [userName, setUserName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [fieldError, setFieldError] = useState('Email must not be empty.')
  const [disableAddButton, setDisableAddButton] = useState(true)

  const addUser = () => {
    if (!cognito.loading) {
      http('/user', 'put', undefined, {
        orgId: cognito.orgId,
        email: userName,
        admin: isAdmin
      })
        .then(response => {
          onUserChange()
          toggleShow()
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }

  const validationHandler = (e) => {
    const inputValue = e.target.value
    if (inputValue.length === 0) {
      setFieldError('Email must not be empty.')
      setDisableAddButton(true)
    } else if (!inputValue.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setFieldError('Invalid email address.')
      setDisableAddButton(true)
    } else {
      setFieldError('')
      setDisableAddButton(false)
    }
  }

  return (
    <Modal show={show} onHide={toggleShow} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title" id="addUserModalLabel">
          Add User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <FormGroup className="mb-2">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control type="text" id="email" aria-describedby="emailHelp" placeholder="user@example.com" value={userName} onInput={e => setUserName(e.target.value)} onChange={validationHandler} />
          <Form.Text id="emailHelp" className="small text-danger">{fieldError}</Form.Text>
        </FormGroup>
        <FormCheck>
          <FormCheck.Input type="checkbox" id="isAdmin" onClick={(e) => setIsAdmin(e.target.checked)} />
          <FormCheck.Label htmlFor="isAdmin">
            Admin
          </FormCheck.Label>
        </FormCheck>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='info' className="text-white" onClick={addUser} disabled={disableAddButton}>
          Add
        </Button>
        <Button variant='secondary' onClick={toggleShow}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AddUser.propTypes = {
  onUserChange: PropTypes.func,
  show: PropTypes.bool,
  toggleShow: PropTypes.func
}
export default AddUser
