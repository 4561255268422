import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover } from 'react-bootstrap'

function TagTooltip({ children }) {
  const { props } = children
  const target = useRef(null)
  return (
    <>
      {props.description
        ? <OverlayTrigger
          placement='bottom'
          overlay={
            <Popover id={`tooltip-${props.labelName}`}>
              <Popover.Body className='tooltip-description'>
                {props.description}
              </Popover.Body>
            </Popover>
          }
        >
          <a ref={target} href={props.href} className={props.className}>
            {children}
          </a>
        </OverlayTrigger>
        : <a ref={target} href={props.href} className={props.className}>
          {children}
        </a>
      }
    </>
  )
}

TagTooltip.propTypes = {
  children: PropTypes.node,
  labelName: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string
}

export default TagTooltip
