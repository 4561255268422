import { useState, useEffect } from 'react'
import useCognitoAuth from './useCognitoAuth'
import http from '../utils/http'

const useSCM = (count = 0) => {
  const cognito = useCognitoAuth()
  const [loading, setLoading] = useState(true)
  const [repoSettings, setRepoSettings] = useState(
    {
      github: {
        token: '',
        hostname: ''
      },
      gitlab: {
        token: '',
        hostname: ''
      },
      bitbucket: {
        token: '',
        hostname: ''
      }
    }
  )

  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      http(`/reposetting/${cognito.orgId}`)
        .then(response => {
          if (!response.data) {
            return
          }
          setRepoSettings(response.data)
        })
        .catch(error => {
          console.error('[reposetting][error]', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [cognito.loading, cognito.orgId, count])

  return (!loading && {
    githubToken: repoSettings.github.token,
    githubHostname: repoSettings.github.hostname,
    gitlabToken: repoSettings.gitlab.token,
    gitlabHostname: repoSettings.gitlab.hostname,
    bitbucketToken: repoSettings.bitbucket.token,
    bitbucketHostname: repoSettings.bitbucket.hostname
  })
}

export default useSCM
