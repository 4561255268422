import React, { useState, useEffect } from 'react'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import SbomList from './SbomList'
import _ from 'lodash'
import { fetchVuln } from '../utils/vulnHelpers'
import { useParams, useNavigate } from 'react-router-dom'
import CustomProgress from './CustomProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import VulnerabilityModal from '../components/VulnerabilityModal'
import { SbomTabs } from './Sboms/SbomTabs'

function fetchOrgBomSbom(props) {
  const { orgId } = props
  return http(`/orgbom/${orgId}`, 'get')
    .then(response => {
      const sbom = response.data.sbom
      const prettySPDX = JSON.stringify(sbom, null, 2)
      return {
        sbom,
        prettySPDX
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export const OrgBomItemDetails = () => {
  const { orgId } = useParams()
  const [reposLoading, setReposLoading] = useState(true)

  const cognito = useCognitoAuth()
  const [spdx, setSpdx] = useState('')
  const [vulns, setVulns] = useState()
  const [packages, setPackages] = useState()
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (cognito.loading === false) {
      Promise.all([
        fetchOrgBomSbom({
          orgId: cognito.orgId
        }),
        fetchVuln(cognito.orgId)
      ]).then((result) => {
        const [sboms, vulns] = result
        const { prettySPDX, sbom } = sboms
        const { packages } = sbom
        const vulnsByName = _.keyBy(vulns, 'packageName')
        setSpdx(prettySPDX)
        setVulns(vulnsByName)
        setReposLoading(false)
        setPackages(packages)
      })
    }
  }, [cognito.orgId, cognito.loading])

  const OrgBomId = orgId.replaceAll('/', '-')

  return (
    <div className="card p-0 shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center bg-success text-light">
        <div className='col'>
          <FontAwesomeIcon
            onClick={() => navigate(-1)}
            className='btn btn-sm me-1 text-light'
            icon={faArrowLeftLong} />
          <span>
            <b className='mx-1'>{orgId}</b>
          </span>
        </div>
      </div>
      <div className="card-body">
        <SbomTabs
          idHelper={OrgBomId}
          spdx={spdx}
        >
          {reposLoading
            ? <CustomProgress className='pt-4 pb-2'/>
            : packages
              ? <SbomList
                setCurrentVuln={setCurrentVuln}
                setIsOpen={setIsOpen}
                packages={packages}
                vulns={vulns}
              />
              : <p>No package manifests found in this repository.</p>}
        </SbomTabs>
      </div>
      <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div>
  )
}
