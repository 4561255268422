import React, { useState, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Container, Stack } from 'react-bootstrap'
import useCognitoAuth from '../../../hooks/useCognitoAuth'
import { fetchTags } from '../../../services/tags'
import _ from 'lodash'
import TagTooltip from '../TagTooltip'
import { Tag } from '../Tag'

export const OrgLabelsTable = () => {
  const params = useParams()
  const { id: labelName } = params
  const cognito = useCognitoAuth()
  const [orgTags, setOrgTags] = useState([])
  const [tagData, setTagData] = useState({})

  const updateTags = async () => {
    try {
      const { data } = await fetchTags({ orgId: cognito.orgId })
      const labelIdChain = _
        .chain(data)
        .find(['labelName', labelName])
        .pick(['labelId', 'color', 'textColor', 'labelName', 'description'])
        .value()
      setTagData(labelIdChain)
      setOrgTags(data)
    } catch (error) {
      console.log('fetch tags [error]', error)
    }
  }
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      updateTags()
    }
  }, [cognito.loading])
  return (
    <>
      <Container fluid className='gx-0'>
        <Stack direction='horizontal' gap={3} className='mb-3 pb-1'>
          <h2 className='m-0'>Export</h2>
          { tagData.labelName
            ? <TagTooltip>
              <Tag title={tagData.labelName} {...tagData}/>
            </TagTooltip>
            : null }
        </Stack>
      </Container>
      <Container fluid className='position-relative gx-0'>
        { tagData.labelId && !cognito.loading
          ? <Outlet context={{ labelId: tagData.labelId, cognito: cognito, orgTags }} />
          : null }
      </Container>
    </>
  )
}
