import React from 'react'
import PropTypes from 'prop-types'
import FilterComponent from '../../FilterComponent'

const SearchField = ({ filterText, setFilterText }) => {
  const handleClear = () => {
    if (filterText) {
      setFilterText('')
    }
  }

  return (
    <FilterComponent
      classNames={'px-3'}
      onFilter={(e) => {
        setFilterText(e.target.value)
      }}
      onClear={handleClear}
      filterText={filterText} />
  )
}
SearchField.propTypes = {
  filterText: PropTypes.string,
  setFilterText: PropTypes.func
}

export default SearchField
