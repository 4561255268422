import React from 'react'
import { Offcanvas, Button, Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export const SelectTagDrawer = ({ show, onHide, children, actions }) => {
  return (
    <Offcanvas show={show} onHide={onHide} backdrop='static' className='overflow-x-clip'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Select a tag
        </Offcanvas.Title>
      </Offcanvas.Header>
      <>
        {children}
      </>
      <div className='d-flex flex-column py-3 box-shadow-custom'>
        <Button
          as={Link}
          to='/tags'
          className='dark-link-button shadow-none text-start px-3'
          variant='link'
        >
          <FontAwesomeIcon icon={faWandMagicSparkles} className='me-2' />Edit tags
        </Button>
        <hr />
        <Stack direction='horizontal' gap={3} className='justify-items-between px-3'>
          {actions}
        </Stack>
      </div>
    </Offcanvas>
  )
}

SelectTagDrawer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  actions: PropTypes.node
}
