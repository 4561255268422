const githubRules = {
  hostname: (watchSelfHosted) => ({
    required: {
      value: watchSelfHosted,
      message: 'Hostname is required if your github is self-hosted'
    }
  }),
  organization: {
    required: { value: true, message: 'Organization is required' }
  }
}

const commonRules = {
  token: { required: { value: true, message: 'Token is required' } },
  hostname: { required: { value: true, message: 'Hostname is required' } }
}

export { githubRules, commonRules }
