import React from 'react'
import PropTypes from 'prop-types'
import { ActionButton, TableOptions } from './index'
import { faTags, faTrashCan } from '@fortawesome/free-solid-svg-icons'

const TableActions = ({ show, selectedRows, toggleApplyTags, toggleDeleteModal, oss = false }) => {
  const selectedText = selectedRows.length === 1 ? 'item selected' : 'items selected'

  return (
    <TableOptions show={show} oss={oss}>
      <div className='selected-rows'>{selectedRows.length} {selectedText}</div>
      <ActionButton
        title='Apply / Remove Tag'
        onClick={toggleApplyTags}
        icon={faTags}
      />
      <ActionButton
        title='Delete Repos'
        className='bg-danger text-white'
        onClick={toggleDeleteModal}
        icon={faTrashCan}
      />
    </TableOptions>
  )
}

TableActions.propTypes = {
  show: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  toggleApplyTags: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  oss: PropTypes.bool
}

export default TableActions
