import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'

const Integrations = () => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span className="h2"><FontAwesomeIcon icon={faPuzzlePiece} /> Integrations</span>
      </div>
    </>
  )
}

export default Integrations
