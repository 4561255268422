import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Alert, Collapse } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useMediaQuery } from 'react-responsive'

const DeleteItemsModal = ({
  handleClose,
  show,
  currentItems,
  deleteHandler,
  itemsType = 'these items',
  showError = false,
  errorMessage = "Oops! We've got an error!"
}) => {
  const isCompact = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <>
      <Modal centered size='lg' show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className={`${isCompact ? 'h6' : 'h5'} text-truncate`}><FontAwesomeIcon icon={faTrashAlt} className='me-2'/>Delete {itemsType}</Modal.Title>
        </Modal.Header>
        <Collapse in={showError}>
          <div id='error-alert'>
            <Alert variant="danger" className="text-center m-3">
              {errorMessage}
            </Alert>
          </div>
        </Collapse>
        <Modal.Body>
          <p>Are you sure you want to delete {itemsType}?</p>
          <ul className='ps-3'>
            {currentItems?.map((item, index) => (
              <li key={`${item}-${index}`}>{item}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteHandler}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

DeleteItemsModal.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  currentItems: PropTypes.arrayOf(PropTypes.string),
  itemsType: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string
}

export default DeleteItemsModal
