import React, { useContext } from 'react'
import AuthContext from '../context/auth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import CustomProgress from './CustomProgress'

const ProtectedRoute = ({
  redirectPath = '/login',
  children
}) => {
  const location = useLocation()
  const authContext = useContext(AuthContext)

  if (authContext.auth === 'unauthorized') {
    return <Navigate to={redirectPath} state={{ from: location }} replace />
  }

  if (isEmpty(authContext.auth)) {
    return <CustomProgress className={'vw-100 vh-100 bg-light align-items-center'}/>
  }

  return children || <Outlet />
}
ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.node
}

export default ProtectedRoute
