import React from 'react'
import { useRouteError } from 'react-router-dom'

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  // Uncaught ReferenceError: path is not defined
  return (
    <div className='p-2'>
      <p className='display-3'>404</p>
      <p className='display-6'>These are not the Droids you are looking for.</p>
    </div>
  )
}

export default ErrorBoundary
