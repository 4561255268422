import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faPlus } from '@fortawesome/free-solid-svg-icons'
import AddedApiKey from './AddedApiKey'
import DeleteApiKey from './DeleteApiKey'
import AddApiKeyModal from './AddApiKeyModal'
import CustomProgress from './CustomProgress'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { Button, Container, ListGroup } from 'react-bootstrap'

const ApiKeyManagement = () => {
  const cognito = useCognitoAuth()
  const [apiKeys, setKeys] = useState()
  const [apiKeysLoading, setApiKeysLoading] = useState(true)
  const [apiKeyChange, setApiKeyChange] = useState(0)
  const [showAddApiKeyModal, setShowAddApiKeyModal] = useState(false)
  const [showDeleteApiKeyModal, setShowDeleteApiKeyModal] = useState(false)
  const [currentApiKey, setCurrentApiKey] = useState({})

  const toggleShowAddApiKeyModal = () => setShowAddApiKeyModal(prevState => !prevState)
  const toggleShowDeleteApiKeyModal = () => setShowDeleteApiKeyModal(prevState => !prevState)

  const onKeysChangeHandler = () => {
    setApiKeyChange(apiKeyChange + 1)
  }

  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      setApiKeysLoading(true)
      http(`/apikey/${cognito.orgId}`)
        .then(response => {
          setKeys(response.data)
          setApiKeysLoading(false)
        })
        .catch(error => {
          setApiKeysLoading(false)
          console.log(error.response)
        })
    }
  }, [cognito.loading, cognito.orgId, apiKeyChange])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span className="h2"><FontAwesomeIcon icon={faKey} /> API Keys</span>
        <Button
          variant='primary'
          size='sm'
          onClick={toggleShowAddApiKeyModal}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <Container fluid className='overflow-auto'>
        {apiKeysLoading
          ? <CustomProgress className='align-items-center min-heigth-200' />
          : <ListGroup variant='flush'>
            {apiKeys &&
              apiKeys.map((apiKey, _i) => (
                <AddedApiKey key={`loading-${apiKey.apiKey}`}
                  apiKey={apiKey}
                  setCurrent={setCurrentApiKey}
                  toggleDelete={toggleShowDeleteApiKeyModal} />
              ))
            }
          </ListGroup>
        }
      </Container>
      <AddApiKeyModal onApiKeysChange={onKeysChangeHandler} show={showAddApiKeyModal} toggleShow={toggleShowAddApiKeyModal} />
      <DeleteApiKey
        show={showDeleteApiKeyModal}
        toggleShow={toggleShowDeleteApiKeyModal}
        apiKey={currentApiKey}
        onApiKeysChange={onKeysChangeHandler} />
    </>
  )
}

export default ApiKeyManagement
