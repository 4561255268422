import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Collapse, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export const ExportDialog = ({ handleClose, show, onSubmit, email, children, isDisabled, resetResponse }) => {
  const [open, setOpen] = useState(false)
  const [vexChecked, setVexChecked] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const currentEmail = useRef()
  const handleSubmit = async () => {
    if (isValid) {
      const isEmailAdded = Boolean(currentEmail?.current?.value)
      await onSubmit(vexChecked, isEmailAdded ? currentEmail.current.value : undefined)
      // resetState()
      // handleClose()
      return
    }
    if (!isValid && open) {
      return currentEmail.current.focus()
    }
    await onSubmit(vexChecked)
    // resetState()
    // handleClose()
  }
  const resetState = () => {
    setVexChecked(false)
    setIsValid(true)
    setOpen(false)
  }
  const onCansel = () => {
    resetState()
    handleClose()
  }

  return (
    <>
      <Modal
        centered
        size='lg'
        show={show}
        onHide={onCansel}
        onExited={() => {
          resetState()
          resetResponse()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFileDownload} className='me-2'/>
            Export SBOMs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-1'>Your export is being generated and you will receive an email once it is complete.</p>
          <p className='mb-3'>You will receive an email at the registered email address or you can enter another email address below.</p>
          <Form.Check
            type='checkbox'
            className='pt-1 pb-2'
            id='user-email-export'
            label='I want to receive SBOMs export at another email'
            checked={open}
            onChange={(e) => {
              setOpen(e.target.checked)
              resetResponse()
            }}
          ></Form.Check>
          <Collapse mountOnEnter unmountOnExit in={open}>
            <Form.Group id='collapse-email-input' className='pt-1 pb-3'>
              <Form.Label>
                Enter email you want to receive generated export:
              </Form.Label>
              <Form.Control
                type='email'
                name='email'
                required
                isValid={isValid}
                isInvalid={!isValid}
                ref={currentEmail}
                defaultValue={email}
                onChange={(e) => {
                  setIsValid(e.currentTarget.checkValidity())
                  resetResponse()
                }}
              />
            </Form.Group>
          </Collapse>
          <Form.Check
            label='Export VEX'
            id='export-vex'
            className='pt-1 pb-2'
            name='vex'
            checked={vexChecked}
            onChange={(e) => {
              setVexChecked(e.target.checked)
              resetResponse()
            }}
          ></Form.Check>
          <div className='status-response-wrapper'>
            {children}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={handleSubmit} disabled={isDisabled}>
            Export
          </Button>
          <Button variant='secondary' onClick={onCansel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

ExportDialog.propTypes = {
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  email: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  resetResponse: PropTypes.func
}
