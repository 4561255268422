import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCode, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import FilterComponent from './FilterComponent'
import { Button } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

const VendorSbomsTable = (props) => {
  const { vendors, deleteHandler } = props
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  const filteredItems = vendors.filter(
    vendor => vendor.name && vendor.name.toLowerCase().includes(filterText.toLowerCase())
  )
  const customStyles = {
    subHeader: {
      style: {
        padding: 0
      }
    },
    headCells: {
      style: {
        fontSize: isCompact ? 14 : 16,
        fontWeight: 'bold'
      }
    },
    cells: {
      style: {
        fontSize: isCompact ? 14 : 16
      }
    }
  }
  const buttonCell = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value)
        }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle])

  const columns = [
    {
      id: 'sbom',
      name: 'Sort by SBOM name',
      minWidth: '60%',
      selector: row => row.name,
      sortable: true
    },
    {
      id: 'details',
      minWidth: 'fit-content',
      grow: 0,
      style: buttonCell,
      selector: row => (
        <Button
          variant="link"
          className='link-body-emphasis'
          onClick={() => navigate(`/vendors/${row.name}`)}
        >
          <FontAwesomeIcon icon={faFileCode} width={14} height={16} />
        </Button>
      )
    },
    {
      id: 'delete',
      minWidth: 'fit-content',
      grow: 0,
      selector: row => (
        <Button
          variant="link"
          className='link-body-emphasis'
          onClick={() => {
            deleteHandler(row.name)
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} width={14} height={16} />
        </Button>
      ),
      style: buttonCell
    }
  ]

  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={filteredItems}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
    />
  )
}

VendorSbomsTable.propTypes = {
  vendors: PropTypes.array,
  deleteHandler: PropTypes.func
}

export default VendorSbomsTable
