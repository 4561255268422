import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import http from '../utils/http'
import { Modal, Button } from 'react-bootstrap'
import UsersContext from '../hooks/UserManagementContext'
import useCognitoAuth from '../hooks/useCognitoAuth'
import CustomProgress from './CustomProgress'

export const UserManagementModal = () => {
  const cognito = useCognitoAuth()
  const { currentUser, showManageModal, toggleManageModal, prepareData } = useContext(UsersContext)
  const { email, enabled, role } = currentUser
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onUserChangeHandler = () => {
    setLoading(false)
    toggleManageModal()
    prepareData()
  }
  const onClose = () => {
    setLoading(false)
    setErrorMessage('')
    toggleManageModal()
  }

  const makeAdmin = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user/addUserToGroup', 'post', undefined, {
        username: email,
        groupname: `${cognito.orgId}-admin`
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(`Unable to make ${email} Admin`)
      console.log(error.response)
    }
  }

  const makeOperator = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user/removeUserFromGroup', 'post', undefined, {
        username: email,
        groupname: `${cognito.orgId}-admin`
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(`Unable to make ${email} Operator`)
      console.log(error.response)
    }
  }

  const disableUser = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user/disableUser', 'post', undefined, {
        username: email
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(`Unable to make disable ${email}`)
      console.log(error.response)
    }
  }

  const enableUser = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user/enableUser', 'post', undefined, {
        username: email
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(`Unable to make enable ${email}`)
      console.log(error.response)
    }
  }

  const deleteUser = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user', 'delete', undefined, {
        email: email
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(`Unable to delete ${email}`)
      console.log(error.response)
    }
  }

  const resetPassword = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await http('/user', 'patch', undefined, {
        email: email
      })
      onUserChangeHandler()
    } catch (error) {
      setLoading(false)
      setErrorMessage(error?.response?.data?.error?.message || `Unable to reset password for ${email}`)
      console.log(error.response)
    }
  }

  return (
    <Modal size='lg' show={showManageModal} onHide={onClose}>
      <Modal.Header closeButton>
        <h5 className="modal-title">
          <FontAwesomeIcon icon={faUsers} /> {email}
        </h5>
      </Modal.Header>
      {(loading || !!errorMessage.length) &&
        <Modal.Body>
          {loading && <CustomProgress />}
          {errorMessage && <p>{errorMessage}</p>}
        </Modal.Body>
      }
      <Modal.Footer>
        <Button
          variant={role === 'Admin' ? 'secondary' : 'dark'}
          onClick={role === 'Admin' ? makeOperator : makeAdmin}
        >
          Make {role === 'Admin' ? 'Operator' : 'Admin'}
        </Button>
        <Button
          variant="warning"
          className='text-dark'
          onClick={enabled === 'Yes' ? disableUser : enableUser}
        >
          {enabled === 'Yes' ? 'Disable' : 'Enable'} User
        </Button>
        <Button variant="danger" onClick={deleteUser}>
          Delete User
        </Button>
        <Button variant="info" onClick={resetPassword}>
          Reset Password
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
