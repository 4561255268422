import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import OrgBomItem from './OrgBomItem'
import CustomProgress from './CustomProgress'

import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { useMediaQuery } from 'react-responsive'

const OrgBoM = () => {
  const cognito = useCognitoAuth()
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const [orgBomLoading, setOrgBomLoading] = useState(true)
  const [orgBom, setOrgBom] = useState({})

  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      http(`/orgbom/${cognito.orgId}`)
        .then(response => {
          setOrgBom(response.data)
          setOrgBomLoading(false)
        })
        .catch(error => {
          setOrgBomLoading(false)
          console.log(error.response)
        })
    }
  }, [cognito.loading, cognito.orgId])

  return (
    <div className='p-0 m-0'>
      <div className="card shadow-sm border-success">
        <div className="card-header d-flex justify-content-between align-items-center bg-success text-light">
          <span>
            <FontAwesomeIcon className='mx-1' icon={faSitemap} /> <b className='mx-1'>OrgBoM</b>
          </span>
        </div>
        <div className="card-body px-2 overflow-auto">
          {orgBomLoading
            ? <CustomProgress />
            : orgBom && orgBom.orgId
              ? <table className="table">
                <thead>
                  <tr className={isCompact ? 'small fw-bold' : 'fs-6'}>
                    <th>ID</th>
                    <th className='text-center'>Data</th>
                    <th className='text-center'>Packages</th>
                    <th>Updated</th>
                  </tr>
                </thead>
                <OrgBomItem orgBom={orgBom} />
              </table>
              : <p className="text-muted p-4"><small>Add repos and/or vendor SBoMs to generate the OrgBoM. Learn more.</small></p>
          }
        </div>
      </div>
    </div>
  )
}

export default OrgBoM
