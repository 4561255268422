import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Collapse, Button, Fade, ListGroup } from 'react-bootstrap'
import { Tag } from './Tag'
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TagEditor } from './TagEditor'
import { Link } from 'react-router-dom'

export const TagsTableRow = ({ tag, cognito, handleDelete, updateTags }) => {
  const [openEditor, setOpenEditor] = useState(false)
  const handleEdit = () => setOpenEditor(true)
  return (
    <ListGroup.Item
      key={tag.labelName}
    >
      <Collapse in={openEditor} mountOnEnter unmountOnExit>
        <Col xs={12} id={`${tag.labelName}-collapse-editor`} className={'py-3 d-flex flex-justify-between flex-md-items-baseline flex-items-center flex-wrap'}>
          <TagEditor
            tag={tag}
            cognito={cognito}
            handleDelete={handleDelete}
            updateTags={updateTags}
            setOpenEditor={setOpenEditor}
          />
        </Col>
      </Collapse>
      <Fade in={!openEditor} unmountOnExit>
        <div
          className={'py-3 d-flex flex-justify-between flex-md-items-baseline flex-items-center flex-wrap'}>
          <Col xs={12} md={4} className='paddingY-bs-btn'>
            <Link to={`/tags/${cognito.orgId}/labels/${tag.labelName}`}>
              <Tag
                color={tag.color}
                textColor={tag.textColor}
                title={tag.labelName}
              />
            </Link>
          </Col>
          <Col xs md className='text-wrap paddingY-bs-btn'>
            <span>{tag.description}</span>
          </Col>
          <Col className='text-end px-0 px-md-2 ms-auto' xs={'auto'}>
            <Button
              variant='link'
              className='link-body-emphasis'
              onClick={handleEdit}
              aria-controls={`${tag.labelName}-collapse-editor`}
              aria-expanded={openEditor}
            >
              <FontAwesomeIcon icon={faPenToSquare} width={14} height={16}/>
            </Button>
          </Col>
          <Col className='text-end px-0 px-md-2' xs={'auto'}>
            <Button
              variant='link'
              className='link-body-emphasis'
              onClick={() => handleDelete(tag)}
            >
              <FontAwesomeIcon icon={faTrashCan} width={14} height={16}/>
            </Button>
          </Col>
        </div>
      </Fade>
    </ListGroup.Item>
  )
}

TagsTableRow.propTypes = {
  tag: PropTypes.object,
  isLast: PropTypes.bool,
  cognito: PropTypes.object,
  handleDelete: PropTypes.func,
  updateTags: PropTypes.func
}
