import { Auth } from 'aws-amplify'

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const cognito = {
      userId: user.username,
      email: user.attributes.email,
      orgId: user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return !el.includes('admin') }),
      isAdmin: !!user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return el.includes('admin') }),
      adminGroup: user.signInUserSession.accessToken.payload['cognito:groups'].find(el => { return el.includes('admin') })
    }
    return cognito
  } catch (error) {
    console.error(error)
    return false
  }
}
