import React from 'react'
import http from '../../../utils/http'
import { Navigate } from 'react-router-dom'
import { getUser } from './getUser'

export const getSCM = async (cognito) => {
  try {
    const { data: repoSettings } = await http(`/reposetting/${cognito.orgId}`)
    const scm = {
      githubToken: repoSettings?.github?.token || '',
      githubHostname: repoSettings?.github?.hostname || '',
      gitlabToken: repoSettings?.gitlab?.token || '',
      gitlabHostname: repoSettings?.gitlab?.hostname || '',
      bitbucketToken: repoSettings?.bitbucket?.token || '',
      bitbucketHostname: repoSettings?.bitbucket?.hostname || ''
    }
    return scm
  } catch (error) {
    console.error(error)
    return false
  }
}

// -- combines SCM and Cognito data into one object for use in useLoaderData --
export const scmLoader = async () => {
  const cognito = await getUser()

  if (!cognito) {
    return <Navigate to={'/login'} replace />
  }
  const scm = getSCM(cognito)
  return { cognito, scm }
}
