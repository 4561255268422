import React from 'react'
import './global.scss'
import { render } from 'react-dom'
import { RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import growthbook from './growthBook.config'
import { router } from './routes'
// import Redirect from './views/Redirect'

let awsconfig
if (process.env.REACT_APP_DEPLOY_ENVIRONMENT === 'test') {
  awsconfig = {
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_LiUKtcrEI',
    aws_user_pools_web_client_id: '5jlacr8knnn2e1m3pm27hd3ui9',
    aws_cognito_identity_pool_id: 'us-east-1:ec818670-ef37-4f27-9635-1aa5b37298ae',
    aws_mandatory_sign_in: 'enable'
  }
} else {
  awsconfig = {
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_yQzRSwlNS',
    aws_user_pools_web_client_id: '1jgb0r0qumqppth73ctqn4s8j8',
    aws_cognito_identity_pool_id: 'us-east-1:33e33490-9eee-4d86-b43a-4a5d9fe64b33',
    aws_mandatory_sign_in: 'enable'
  }
}

Amplify.configure(awsconfig)

render(
  <React.StrictMode>
    <GrowthBookProvider growthbook={growthbook}>
      <RouterProvider router={router} />
    </GrowthBookProvider>
  </React.StrictMode>,
  document.getElementById('app')
)
