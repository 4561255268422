import PropTypes from 'prop-types'
import http from '../utils/http'

// -- fetch current Reposettings --

const fetchReposettings = async ({ orgId }) => {
  const response = await http(`/reposetting/${orgId}`)
  return response
}

fetchReposettings.propTypes = {
  orgId: PropTypes.string
}

// -- update or remove Reposettings with new or empty data --
const updateReposetting = async ({ orgId, repo, data }) => {
  const response = await http(`/reposetting/${repo}`, 'put', undefined, {
    orgId,
    [repo]: data
  })
  return response
}

updateReposetting.propTypes = {
  orgId: PropTypes.string,
  repo: PropTypes.string,
  data: PropTypes.object
}

// -- ping reposetting host --

const pingHost = async ({ hostData }) => {
  const response = await http('/reposetting/verify', 'post', undefined, hostData)
  // console.log('pingHost [payload]', hostData, 'pingHost [response]', response)
  return response
}
pingHost.propTypes = {
  hostData: PropTypes.object.isRequired
}

export { fetchReposettings, updateReposetting, pingHost }
