import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from 'react-bootstrap'

const NoDataComponent = ({ message, children }) => {
  return (
    <Stack direction='vertical' gap={2} className='text-center p-2'>
      <div className='p-2'><span>{message}</span></div>
      <div className='flex'>
        {children}
      </div>
    </Stack>
  )
}
NoDataComponent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node
}

export default NoDataComponent
