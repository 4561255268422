import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ColorPicker from '../ColorPicker'
import newShade, { colorValidation } from '../../../utils/colorHelper'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { Tag } from '../Tag'
import { addTag } from '../../../services/tags'
import { SelectOwner } from './TagCreator.styled'
import { get } from 'lodash'
import { preparedUsersData } from '../../../services/users'

const initialTagValues = {
  color: '#ab79ec',
  textColor: '#5b299c',
  labelName: '',
  description: ''
}

const TagCreatorMobileVersion = ({ cognito, toggleCreator, createLabel, updateTags }) => {
  const [topLabel, setToplabel] = useState({
    color: '#ab79ec',
    textColor: '#5b299c',
    labelName: createLabel,
    description: '',
    businessOwner: '',
    technicalOwner: ''
  })
  const [color, setColor] = useState(initialTagValues.color)
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    placement: null
  })
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    try {
      const data = await preparedUsersData(cognito)
      setUsers(data)
    } catch (error) {
      console.log('fetch users [error]', error)
    }
  }

  const onCleanError = () => {
    setErrorMessage({
      message: '',
      placement: null
    })
  }

  const handleColorChange = (e) => {
    const { value } = e.target
    return setColor(value)
  }

  const handleSelectChange = (value, name) => {
    return setToplabel((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'labelName') {
      onCleanError()
    }
    return setToplabel(() => ({
      ...topLabel,
      [name]: value
    }))
  }

  useEffect(() => {
    const newColor = colorValidation(color)
    if (newColor) {
      const darkenColor = newShade(`${newColor}`, -50)
      const lightenColor = newShade(`${newColor}`, 50)
      const currentTextColor = darkenColor.length < 7 ? lightenColor : darkenColor
      return setToplabel(() => ({
        ...topLabel,
        color: `#${newColor}`,
        textColor: currentTextColor
      }))
    }
  }, [color])
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      fetchUsers()
    }
  }, [cognito.loading])

  const onSubmit = async () => {
    onCleanError()
    try {
      await addTag({
        orgId: cognito.orgId,
        tagData: {
          ...topLabel,
          businessOwner: get(topLabel, ['businessOwner', 'value'], ''),
          technicalOwner: get(topLabel, ['technicalOwner', 'value'], '')
        }
      })
      toggleCreator()
      updateTags()
    } catch (error) {
      // if (!error.response.data.message) {
      //   setErrorMessage({
      //     message: 'Sorry :( Something went wrong. Please, contact us and describe what`s happened',
      //     placement: 'upper_level'
      //   })
      // }
      if (error.response.data.message) {
        setErrorMessage({
          message: error.response.data.message,
          placement: 'labelName'
        })
      }
      console.log('submit tag [error]', error)
    }
  }
  const editableTitle = topLabel.labelName.length ? topLabel.labelName : 'Label preview'
  const isDisabled = !topLabel.labelName || !topLabel.businessOwner || !topLabel.technicalOwner

  return (
    <>
      <Modal.Header closeButton>Create new tag</Modal.Header>
      <Modal.Body className='p-3 bg-light'>
        <Row className='mt-0 mb-3'>
          <div><Tag title={editableTitle} color={topLabel.color} textColor={topLabel.textColor} /></div>
        </Row>
        <Form as={Row}>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="TagLabel">
              <Form.Label>Label name</Form.Label>
              <Form.Control
                name='labelName'
                onChange={handleChange}
                type="text"
                placeholder="Label name"
                maxLength={50}
                value={topLabel.labelName}
              />
              <Form.Text className='text-danger'>{errorMessage.placement === 'labelName' ? errorMessage.message : ''}</Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="TagDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name='description'
                onChange={handleChange}
                type="text"
                placeholder="Description (optional)"
                maxLength={100}
                value={topLabel.description}
              />
              <Form.Text className='text-danger'>{errorMessage.placement === 'description' ? errorMessage.message : ''}</Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3">
            <SelectOwner
              name='businessOwner'
              value={topLabel.businessOwner}
              options={users}
              label={'Business Owner'}
              handleSelectChange={handleSelectChange}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <SelectOwner
              name='technicalOwner'
              value={topLabel.technicalOwner}
              options={users}
              label={'Technical Owner'}
              handleSelectChange={handleSelectChange}
            />
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="Color">
              <Form.Label>Color</Form.Label>
              <Row>
                <Col xs='auto'>
                  <ColorPicker handleChange={handleColorChange} name='color' color={topLabel.color}/>
                </Col>
                <Col>
                  <Form.Control
                    onChange={handleColorChange}
                    type="text"
                    value={color}
                    placeholder={topLabel.color}
                    name='color'/>
                  {/* <Form.Text>Hex format only</Form.Text> */}
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='success'
          disabled={isDisabled}
          onClick={onSubmit}>Save</Button>
      </Modal.Footer>
    </>
  )
}

TagCreatorMobileVersion.propTypes = {
  cognito: PropTypes.any,
  toggleCreator: PropTypes.func,
  createLabel: PropTypes.string,
  direction: PropTypes.string,
  updateTags: PropTypes.func
}

export default TagCreatorMobileVersion
