import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const ButtonModal = ({ icon, ...props }) => {
  return (
    <Button
      className='link-body-emphasis'
      variant='link'
      {...props}
    >
      <FontAwesomeIcon icon={icon} width={20} height={20} />
    </Button>
  )
}
ButtonModal.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.element,
    PropTypes.string
  ])
}

export default ButtonModal
