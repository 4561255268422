import React, { useState, useCallback, useEffect, useMemo } from 'react'
import '../custom.css'
import propTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'
import { faFileCode, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import FilterComponent from './FilterComponent'
import { Button, FormCheck } from 'react-bootstrap'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useMediaQuery } from 'react-responsive'
import { SelectTagForMany } from './Tags/SelectTagForMany'
import { isEmpty, map } from 'lodash'
import { putTagsForRepos, removeTagsFromRepos } from '../services/tags'
import { SelectTagDrawer } from './Tags/SelectTagDrawer'
import { DeleteItemsModal } from './DeleteModal'
import { deleteManyRepos } from '../services/repos'
import { TableActions } from './MultiSelectFeatureComponents'
import { center, customStyles, TagsCell } from './TablesSharedComponents'
import { ErrorNotification } from './Alerts'

const ReposTable = ({ repos, orgTags, deleteHandler, edit, updateTable, cognito }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const navigate = useNavigate()
  const tagsFeatureIsEnabled = useFeatureIsOn('tags')
  const [currentTag, setCurrentTag] = useState({})

  const [expandOptions, setExpandOptions] = useState(false)
  const [selectedRepos, setSelectedRepos] = useState([])
  const [openApplyTag, setOpenApplyTags] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const toggleApplyTags = () => setOpenApplyTags(prevState => !prevState)
  const toggleDeleteModal = () => {
    setOpenDeleteModal(prevState => !prevState)
    hideError()
  }

  const [error, setError] = useState({
    message: '',
    type: 'idle'
  })
  const showError = (type, message) => setError({
    message: message,
    type: type
  })
  const hideError = () => setError({
    message: '',
    type: 'idle'
  })

  const handleRowSelected = useCallback(state => {
    setSelectedRepos(state.selectedRows)
  }, [])

  const reposList = useMemo(() => map(selectedRepos, (repo) => (repo.repo)), [selectedRepos])

  useEffect(() => {
    if (selectedRepos.length > 0 && expandOptions === false && edit) {
      return setExpandOptions(true)
    }
    if (selectedRepos.length === 0 || !edit) {
      return setExpandOptions(false)
    }
  }, [selectedRepos.length, edit])

  // --- clears selected rows on toggle Edit --- //
  useEffect(() => {
    if (edit === false) {
      setSelectedRepos([])
    }
  }, [edit])

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const filteredItems = repos.filter(
    item => item.repo && item.repo.toLowerCase().includes(filterText.toLowerCase())
  )
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value)
        }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle])

  const tableStyles = useMemo(() => {
    return customStyles({ isCompact })
  }, [isCompact])

  const columns = [
    {
      minWidth: isCompact ? '48px' : '60px',
      grow: 0,
      cell: row => (
        <span>
          {row.provider === 'github'
            ? <FontAwesomeIcon icon={faGithub} maskId={'github'} />
            : row.provider === 'gitlab'
              ? <FontAwesomeIcon icon={faGitlab} maskId={'gitlab'} />
              : row.provider === 'bitbucket'
                ? <FontAwesomeIcon icon={faBitbucket} maskId={'bitbucket'} />
                : ''}
        </span>
      ),
      style: center,
      id: 'versionControl'
    },
    {
      id: 'repo',
      sortField: 'repo',
      grow: 2,
      minWidth: '300px',
      name: 'Sort by repository name',
      selector: row => row.repo,
      cell: row => row.repo,
      sortable: true
    },
    {
      id: 'tags',
      grow: 2,
      minWidth: '200px',
      wrap: true,
      selector: row => row.repoId,
      cell: row => orgTags && tagsFeatureIsEnabled ? <TagsCell row={row} orgTags={orgTags} /> : null
    },
    {
      id: 'details',
      width: 'fit-content',
      grow: 0,
      compact: true,
      cell: row => (
        <Button
          variant="link"
          className='link-body-emphasis'
          onClick={() => navigate(`/repositories/${row.repo.replaceAll('/', '%2F')}`)}
        >
          <FontAwesomeIcon icon={faFileCode} width={24} height={24}/>
        </Button>
      )
    },
    {
      id: 'delete',
      width: 'fit-content',
      grow: 0,
      compact: true,
      cell: row => (
        <Button
          variant="link"
          className='link-body-emphasis'
          onClick={() => {
            deleteHandler(row.repo)
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} width={24} height={24}/>
        </Button>
      )
    }
  ]

  const handleApplyTag = async () => {
    try {
      await putTagsForRepos({
        orgId: cognito.orgId,
        repos: reposList,
        tag: currentTag
      })
      toggleApplyTags()
      updateTable()
    } catch (error) {
      console.log('Apply tags error:', error)
      showError('tags', error?.message)
    }
  }

  const handleRemoveTag = async () => {
    try {
      await removeTagsFromRepos({
        orgId: cognito.orgId,
        repos: reposList,
        tag: currentTag
      })
      toggleApplyTags()
      updateTable()
    } catch (error) {
      console.log('Remove tags error:', error)
      showError('tags', error?.message)
    }
  }

  const handleDeleteRepos = async () => {
    try {
      await deleteManyRepos({
        orgId: cognito.orgId,
        repos: reposList
      })
      toggleDeleteModal()
      updateTable()
    } catch (error) {
      console.log('deleteManyRepos error:', error?.response?.data || error)
      showError('delete', error?.message)
    }
  }
  const clearStates = () => {
    setCurrentTag({})
    toggleApplyTags()
  }

  return (
    <>
      <TableActions
        show={expandOptions}
        selectedRows={selectedRepos}
        toggleApplyTags={toggleApplyTags}
        toggleDeleteModal={toggleDeleteModal} />
      <DataTable
        title='Repos'
        noHeader
        keyField='repo'
        customStyles={tableStyles}
        columns={columns}
        responsive
        data={filteredItems}
        defaultSortFieldId={'repo'}
        // selectableRows={isMultiSelectEnabled ? edit : false}
        selectableRows={edit}
        clearSelectedRows={!edit}
        onSelectedRowsChange={handleRowSelected}
        selectableRowsComponent={FormCheck}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
      <>
        <SelectTagDrawer
          show={openApplyTag}
          onHide={toggleApplyTags}
          actions={
            <>
              <Button variant='outline-danger' onClick={handleRemoveTag} disabled={isEmpty(currentTag)}>
                Remove tag
              </Button>
              <Button variant='outline-success' onClick={handleApplyTag} disabled={isEmpty(currentTag)}>
                Apply tag
              </Button>
              <Button variant='link' className='ms-auto dark-link-button shadow-none' onClick={clearStates}>
                Cancel
              </Button>
            </>
          }
        >
          <SelectTagForMany
            orgTags={orgTags}
            selectedRepos={selectedRepos}
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
          />
        </SelectTagDrawer>
        <DeleteItemsModal
          show={openDeleteModal}
          handleClose={toggleDeleteModal}
          deleteHandler={handleDeleteRepos}
          itemsType='selected repos'
          currentItems={reposList}
          showError={error.type === 'delete'}
          // errorMessage={error.message}
        />
        <ErrorNotification
          show={error.type === 'tags'}
          onHide={hideError}
          // message={error.message}
        />
      </>
    </>
  )
}

ReposTable.propTypes = {
  repos: propTypes.any,
  orgTags: propTypes.array,
  orgId: propTypes.string,
  deleteHandler: propTypes.func,
  edit: propTypes.bool,
  toggleEdit: propTypes.func,
  updateTable: propTypes.func,
  cognito: propTypes.any
}

export default ReposTable
