import React from 'react'
import PropTypes from 'prop-types'
import { Option } from './index'

const Options = ({ data, onSelect, selected }) => {
  if (!data?.length) {
    return <div className='p-3 text-muted text-center'>No options</div>
  }

  return (
    <>
      {data.map((option) => {
        const isSelected = selected?.labelName === option?.labelName
        return (
          <Option
            key={option.labelName}
            onClick={() => onSelect(option)}
            data={option}
            isSelected={isSelected} />
        )
      })}
    </>
  )
}
Options.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.object
}

export default Options
