import React from 'react'
import AuthenticationButton from './AuthenticationButton'
import { Nav, Spinner } from 'react-bootstrap'
import useCognitoAuth from '../hooks/useCognitoAuth'

const AuthNav = () => {
  const cognito = useCognitoAuth()

  return (
    <Nav className="text-uppercase ms-auto py-4 py-lg-0">
      {!cognito.loading && cognito.userId &&
        <>
          <Nav.Item><Nav.Link href="/">Factory</Nav.Link></Nav.Item>
          <Nav.Item>
            <Nav.Link href="/profile">
              { cognito.loading ? <Spinner animation='border' size="sm" variant='light' /> : cognito.email }
            </Nav.Link>
          </Nav.Item>
        </>
      }
      <Nav.Item>
        <AuthenticationButton />
      </Nav.Item>
    </Nav>
  )
}

export default AuthNav
