import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './select-styles.css'

const Option = ({ data: { color, labelName, description }, isSelected, onClick }) => {
  return (
    <Dropdown.Item as='div' bsPrefix='custom-dropdown-select-item' onClick={onClick}>
      <div
        aria-labelledby='row-select'
        className='d-flex flex-row border-bottom py-2 gap-2 dropdown-row' style={{ minHeight: '60px' }}>
        <div className='text-center select-node'>
          {isSelected && <FontAwesomeIcon icon={faCheck} width={20} className='py-1' />}
        </div>
        <div className='pe-3 cursor-pointer select-tag-option'>
          <div className='d-flex flex-row align-items-center gap-2'>
            <div className='color-indicator' style={{ backgroundColor: color }} />
            <div className='text-truncate cursor-pointer'>
              {labelName}
            </div>
          </div>
          <div className='flex-row text-truncate small'>{description}</div>
        </div>
      </div>
    </Dropdown.Item>
  )
}
Option.propTypes = {
  data: PropTypes.object,
  color: PropTypes.string,
  labelName: PropTypes.string,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
}

export default Option
