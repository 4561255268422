import React from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LoginFooter } from '../components/LoginPage/LoginFooter'

export const ErrorPage404 = () => {
  const navigate = useNavigate()

  return (
    <div className='d-flex flex-column min-vh-100 gap-2 justify-content-between'>
      <Link to={'https://traxiom.io'} style={{ zIndex: 9999 }} className='mx-auto'>
        <div className='logo__login'>
          <Image thumbnail className='border-0' src='/static/image/logo.png' alt='Traxiom' height={75}/>
        </div>
      </Link>
      <Container className='text-center py-5'>
        <h1 className='display-3'>404</h1>
        <p className='display-6'>There is nothing here</p>
        <div className='py-5'>
          <Button variant='link' size='lg' onClick={() => navigate(-1, { replace: true })}>
            Return to previous page
          </Button>
        </div>
      </Container>
      <LoginFooter />
    </div>
  )
}
