import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Collapse, FormCheck, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import HostInputRS from './HostInputRS'
import TokenInputRS from './TokenInputRS'
import { isEmpty, pick } from 'lodash'
import { faArrowsRotate, faFloppyDisk, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { githubRules } from './formRules'

export const GitHubForm = ({ data: { hostname, organization, token }, response: { status: response, message }, saveChanges }) => {
  const defaultHost = 'https://github.com/'
  const isHostnameNotSelfHosted = (hostname) => hostname?.length && hostname?.startsWith(defaultHost)

  const [edit, setEdit] = useState(false)
  const toggleEdit = () => setEdit(!edit)

  const setDefaultValues = () => {
    if (isHostnameNotSelfHosted(hostname)) {
      const url = new URL(hostname)
      const orgSplited = url.pathname.split('/', 2)[1]
      return {
        hostname: '',
        organization: orgSplited,
        selfHosted: false,
        token: token || ''
      }
    }
    return {
      hostname: hostname || '',
      organization: organization || '',
      selfHosted: Boolean(hostname) || false,
      token: token || ''
    }
  }

  const { register, watch, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    defaultValues: setDefaultValues()
  })
  const watchSelfHosted = watch('selfHosted')

  const reducedData = (data) => {
    return watchSelfHosted && !isHostnameNotSelfHosted(data.hostname) ? pick(data, ['hostname', 'organization', 'token']) : pick(data, ['organization', 'token'])
  }

  const onSubmit = (values) => {
    return saveChanges({
      repo: 'github',
      data: reducedData(values)
    })
  }

  const onReset = () => {
    reset(
      { ...setDefaultValues() },
      { keepDefaultValues: true }
    )
  }
  const onError = () => {
    if (!isEmpty(errors)) {
      setEdit(true)
    }
  }
  useEffect(() => {
    onError()
  }, [errors])

  // ToDo: we can add validation to UI by these rules if it's needed - https://github.com/dead-claudia/github-limits#github-limits
  return (
    <Stack as={'form'} gap={3} onSubmit={handleSubmit(onSubmit)}>
      <p className="lead mb-1">Set your GitHub Organization</p>
      <HostInputRS
        controlId={'github-org-control'}
        label={'Github Organization'}
        rules={githubRules.organization}
        error={Boolean(errors?.organization)}
        errorMessage={errors?.organization?.message}
        register={register}
        name={'organization'}
        response={response}
        saveHandler={handleSubmit(onSubmit)}
        edit={edit}
        adds={!watchSelfHosted ? defaultHost : null}
        ping={!watchSelfHosted}
      />
      <Collapse in={watchSelfHosted}>
        <Stack>
          <p className="lead mb-1">Set your GitHub hostname (only for self-hosted GitHub), e.g. https://github.example.com</p>
          <HostInputRS
            type='url'
            controlId='github-hosthame-control'
            label={'Hostname'}
            register={register}
            name={'hostname'}
            rules={githubRules.hostname(watchSelfHosted)}
            error={Boolean(errors?.hostname)}
            response={response}
            saveHandler={handleSubmit(onSubmit)}
            edit={edit}
            ping={watchSelfHosted}
            errorMessage={errors?.hostname?.message}
          />
        </Stack>
      </Collapse>
      <Alert show={response === 'error' && !!message?.length} variant='danger'>{message}</Alert>
      <FormCheck
        {...register('selfHosted')}
        label='This Github is self-hosted'
        id='github-self-hosted-form-check'
      />
      <TokenInputRS
        controlId={'github-token'}
        label={'Token'}
        name={'token'}
        edit={edit}
        rules={{ required: { value: true, message: 'Token is reqired' } }}
        error={Boolean(errors?.token)}
        errorMessage={errors?.token?.message}
        saveHandler={handleSubmit(onSubmit)}
        register={register}
      />
      <Stack direction='horizontal' gap={3} className='py-2'>
        <Button type='button' variant='outline-dark' onClick={toggleEdit}>
          <FontAwesomeIcon icon={faPen} width={24} /><span className='ms-2 pe-2'>Edit</span>
        </Button>
        <Button variant='primary' type='submit'>
          <FontAwesomeIcon icon={faFloppyDisk} width={24} /><span className='ms-2 pe-2'>Save</span>
        </Button>
        {isDirty &&
          <Button variant='link' onClick={onReset}>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Button>}
      </Stack>
    </Stack>
  )
}

GitHubForm.propTypes = {
  data: PropTypes.shape({
    hostname: PropTypes.string,
    organization: PropTypes.string,
    token: PropTypes.string
  }),
  response: PropTypes.shape({
    status: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }),
  saveChanges: PropTypes.func
}

export default GitHubForm
