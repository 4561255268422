import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import newShade, { colorValidation } from '../../../utils/colorHelper'
import { Col, Form, Row, Container } from 'react-bootstrap'
import { Tag } from '../Tag'
import { addTag } from '../../../services/tags'
import { get } from 'lodash'
import { ButtonsSection, ColorSection, ColorFormLayout, SelectOwner } from './TagCreator.styled'
import { UsersContext } from '../TagsCreation'

const TagCreator = ({ cognito, updateTags, toggleCreator, createLabel }) => {
  const [topLabel, setToplabel] = useState({
    color: '#ab79ec',
    textColor: '#5b299c',
    labelName: createLabel || '',
    description: '',
    businessOwner: undefined,
    technicalOwner: undefined
  })
  const [color, setColor] = useState(topLabel.color)
  const users = useContext(UsersContext)
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    placement: null
  })
  const onCleanError = () => {
    setErrorMessage({
      message: '',
      placement: null
    })
  }

  const handleColorChange = (e) => {
    const { value } = e.target
    return setColor(value)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'labelName') {
      onCleanError()
    }
    return setToplabel((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSelectChange = (value, name) => {
    return setToplabel((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const onCancel = () => {
    toggleCreator()
  }
  useEffect(() => {
    const newColor = colorValidation(color)
    if (newColor) {
      const darkenColor = newShade(`${newColor}`, -50)
      const lightenColor = newShade(`${newColor}`, 50)
      const currentTextColor = darkenColor.length < 7 ? lightenColor : darkenColor
      return setToplabel(() => ({
        ...topLabel,
        color: `#${newColor}`,
        textColor: currentTextColor
      }))
    }
  }, [color])

  const onSubmit = async () => {
    onCleanError()
    try {
      await addTag({
        orgId: cognito.orgId,
        tagData: {
          ...topLabel,
          businessOwner: get(topLabel, ['businessOwner', 'value'], ''),
          technicalOwner: get(topLabel, ['technicalOwner', 'value'], '')
        }
      })
      toggleCreator()
      updateTags()
    } catch (error) {
      if (!error.response.data.message) {
        setErrorMessage({
          message: 'Sorry :( Something went wrong. Please, contact us and describe what`s happened',
          placement: 'upper_level'
        })
      }
      if (error.response.data.message) {
        setErrorMessage({
          message: error.response.data.message,
          placement: 'labelName_helper_text'
        })
      }
    }
  }

  const editableTitle = topLabel.labelName.length ? topLabel.labelName : 'Tag Preview'
  const styledColumn = 'my-1 my-lg-0 mb-lg-3 mb-3'
  const isDisabled = !topLabel.labelName || !topLabel.businessOwner || !topLabel.technicalOwner

  return (
    <>
      { errorMessage.placement === 'upper_level'
        ? <div className='alert alert-danger'>{errorMessage.message}</div>
        : null }
      <div className='custom-bg-wrapper bg-light p-2 p-lg-3 mb-3'>
        <Row className='mt-0 mb-2'>
          <div>
            <Tag title={editableTitle} color={topLabel.color} textColor={topLabel.textColor} />
          </div>
        </Row>
        <Form as={Container}>
          <Row>
            <Col xs={12} lg={{ span: 4, order: 'first' }} className={styledColumn}>
              <Form.Group controlId="TagLabel">
                <Form.Label>Tag Name</Form.Label>
                <Form.Control
                  name='labelName'
                  onChange={handleChange}
                  type="text"
                  placeholder="Tag Name"
                  maxLength={50}
                  value={topLabel.labelName}
                />
                { errorMessage.placement === 'labelName_helper_text'
                  ? <Form.Text className='text-danger'>{errorMessage.message}</Form.Text>
                  : null }
              </Form.Group>
            </Col>
            <Col xs={12} lg={{ span: 4, order: '1' }} className={styledColumn}>
              <Form.Group controlId="TagDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name='description'
                  onChange={handleChange}
                  type="text"
                  placeholder="Description (optional)"
                  maxLength={100}
                  value={topLabel.description}
                />
              </Form.Group>
            </Col>
            <Col xs={{ span: 12, order: '4' }} lg={{ span: 4, order: '2' }} className={styledColumn}>
              <ColorFormLayout>
                <ColorSection
                  handleChange={handleColorChange}
                  color={color}
                  state={topLabel}
                />
              </ColorFormLayout>
            </Col>
            <Col xs={12} lg={{ span: 4, order: '3' }} className={styledColumn}>
              <SelectOwner
                name='businessOwner'
                value={topLabel.businessOwner}
                options={users}
                label={'Business Owner'}
                handleSelectChange={handleSelectChange}
              />
            </Col>
            <Col xs={12} lg={{ span: 4, order: '4' }} className={styledColumn}>
              <SelectOwner
                name='technicalOwner'
                value={topLabel.technicalOwner}
                options={users}
                label={'Technical Owner'}
                handleSelectChange={handleSelectChange}
              />
            </Col>
            <Col xs={{ span: 12, order: 'last' }} lg className='my-1 my-lg-0 mb-lg-3 mb-3 d-flex flex-column justify-content-end'>
              <ButtonsSection
                onCancel={onCancel}
                onSubmit={onSubmit}
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

TagCreator.propTypes = {
  cognito: PropTypes.any,
  updateTags: PropTypes.func,
  toggleCreator: PropTypes.func,
  createLabel: PropTypes.string,
  direction: PropTypes.string
}

export default TagCreator
