import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'

import FactoryRepos from '../views/FactoryRepos'
import FactoryVulnerabilities from '../views/FactoryVulnerabilities'
import FactoryVendors from '../views/FactoryVendors'
import FactoryOrgBom from '../views/FactoryOrgBom'

import { RepoDetails } from '../components/RepoDetails'
import { VendorSbomDetail } from '../components/VendorSbomDetail'
import { OrgBomItemDetails } from '../components/OrgBomItemDetails'
import { PublicURL } from '../components/PublicURL/PublicURL'
import { PublicUrlDetails } from '../components/PublicURL/PublicURLDetails'
import { TagsCreation } from '../components/Tags/TagsCreation'
import { LabelsById } from '../components/Tags/Labels/LabelsById'
import { OrgLabelsTable } from '../components/Tags/Labels/OrgLabelsTable'
import FactoryVulnerabilitiesExample from './routeFeature/FactoryVulnerabilitiesExample'
import { vulnLoader } from './routeFeature/utils/getVulns'
import ErrorBoundary from './routeFeature/ErrorBoundary'
import SelectRepos from '../components/SelectRepos'
import { ResolveSCM } from '../components/SelectRepos/ResolveScmProxyRoute'
import { scmLoader } from './routeFeature/utils/getSCM'

export const factoryRoutes = [
  {
    index: true,
    element: <Navigate to='/repositories' replace={true} />
  },
  {
    path: '/repositories',
    element: <FactoryRepos />
  },
  { path: '/repositories/:repo', element: <RepoDetails /> },
  {
    path: 'repositories/select-repos/:scm',
    loader: scmLoader,
    errorElement: <ErrorBoundary />,
    element: <ResolveSCM><SelectRepos /></ResolveSCM>
  },
  {
    path: '/vendors',
    element: <FactoryVendors />
  },
  { path: '/vendors/:vendor', element: <VendorSbomDetail /> },
  {
    path: '/orgBom',
    element: <FactoryOrgBom />
  },
  { path: '/orgBom/:orgId', element: <OrgBomItemDetails /> },
  {
    path: '/vulnerabilities',
    element: <FactoryVulnerabilities />
  },
  {
    path: '/vulnerabilities_2',
    loader: vulnLoader,
    errorElement: <ErrorBoundary />,
    element: <IfFeatureEnabled feature='new-router-feature'><FactoryVulnerabilitiesExample /></IfFeatureEnabled>
  },
  {
    path: '/tags',
    element: <IfFeatureEnabled feature='tags'><Outlet /></IfFeatureEnabled>,
    children: [
      {
        index: true,
        element: <TagsCreation />
      },
      {
        path: '/tags/:orgId/labels',
        element: <OrgLabelsTable />,
        children: [
          { path: '/tags/:orgId/labels/:id', element: <LabelsById /> }
        ]
      }
    ]
  },
  {
    path: '/oss',
    element: <IfFeatureEnabled feature='public-repo-from-url'><Outlet /></IfFeatureEnabled>,
    children: [
      {
        index: true,
        element: <PublicURL />
      },
      {
        path: '/oss/:url',
        element: <PublicUrlDetails />
      }
    ]
  }
]
