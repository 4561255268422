import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { Button, Modal } from 'react-bootstrap'

const DeleteApiKey = ({ show, toggleShow, apiKey, onApiKeysChange }) => {
  const cognito = useCognitoAuth()

  const deleteApiKey = () => {
    if (!cognito.loading) {
      http('/apikey', 'delete', undefined, {
        orgId: cognito.orgId,
        apiKey: apiKey?.apiKey
      })
        .then(response => {
          onApiKeysChange()
          toggleShow()
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }

  return (
    <Modal show={show} onHide={toggleShow} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faTrashAlt} className='me-2' />{apiKey?.apiKeyId} API Key
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        Are you sure you want to delete the <b>{apiKey?.apiKeyId}</b> key?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={deleteApiKey}>
          Delete
        </Button>
        <Button
          variant="secondary"
          onClick={toggleShow}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DeleteApiKey.propTypes = {
  apiKey: PropTypes.objectOf(PropTypes.string),
  show: PropTypes.bool,
  toggleShow: PropTypes.func,
  onApiKeysChange: PropTypes.func
}

export default DeleteApiKey
