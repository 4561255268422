import React, { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Button } from 'react-bootstrap'
import { SbomByLabel } from './SbomByLabel'
import { fetchSbomsByTagLabel } from '../../../services/tags'
import CustomProgress from '../../CustomProgress'
import { useOutletContext } from 'react-router-dom'
import { DeleteModal } from '../../DeleteModal'
import { deleteRepos } from '../../../services/repos'
import Notification from './CustomNotification'
import requestExport from '../../../services/export'
import { ExportDialog } from '../ExportDialog'

const DELAY = 4000

export const LabelsById = () => {
  const context = useOutletContext()
  const { labelId, cognito, orgTags } = context
  const [loading, setLoading] = useState(true)
  const [sboms, setSboms] = useState([])
  const [count, setCount] = useState(0)
  const [checked, setChecked] = useState([])
  const [currentSbom, setCurrentSbom] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)
  const [processDialog, setProcessDialog] = useState(false)
  const [response, setResponse] = useState('idle')
  const notification = {
    idle: {
      status: 'idle',
      bg: 'white',
      message: ''
    },
    success: {
      status: 'Success',
      bg: 'success',
      // message: 'Your export will be generated and you will receive an email once it is complete.'
      message: 'We have received your request and will reply shortly.'
    },
    error: {
      status: 'Error',
      bg: 'danger',
      message: 'An error has occurred, please contact an Admin.'
    }
  }

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  // -- fetches labels by orgId with queries/params {labelId: labelId} --
  const updateTags = async () => {
    setLoading(true)
    try {
      const { data } = await fetchSbomsByTagLabel({ orgId: cognito.orgId, labelId: labelId })
      const { count, sboms } = data
      if (!count || !sboms) {
        return
      }
      setSboms(sboms)
      setCount(count)
    } catch (error) {
      console.log('fetch sboms by label [error]', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      updateTags()
    }
  }, [cognito.loading])
  const selectAll = () => {
    if (checked.length === sboms.length) {
      setChecked([])
      return
    }
    setChecked(sboms)
  }

  const deleteSbom = async () => {
    setShowDeleteModal(false)
    try {
      await deleteRepos({
        email: cognito.email,
        orgId: cognito.orgId,
        currentRepo: currentSbom
      })
      updateTags()
    } catch (error) {
      console.log('delete sbom [error]', error)
    }
  }
  const onSubmit = async (vex = false, email = cognito.email) => {
    setProcessDialog(true)
    try {
      await requestExport({
        email: email,
        orgId: cognito.orgId,
        sboms: checked,
        vex: vex
      })
      setResponse('success')
      setProcessDialog(false)
      handleDelay()
      setChecked([])
    } catch (error) {
      console.log('export sboms [error]', error)
      setResponse('error')
      setProcessDialog(false)
    }
  }

  const handleDelay = () => {
    const timer = setTimeout(() => {
      setOpenExportDialog(false)
      setResponse('idle')
    }, DELAY)
    return () => clearTimeout(timer)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className='align-items-center'>
            <Col xs='auto'>
              <Form.Check.Input type='checkbox' id='select-all' checked={checked?.length === sboms.length} onChange={selectAll}/>
            </Col>
            <Col xs>
              <Form.Check.Label htmlFor='select-all' ><b>Select to Export</b></Form.Check.Label>
            </Col>
            <Col xs='auto' className='ms-auto'>
              <Button variant='secondary' disabled={!checked.length} onClick={() => setOpenExportDialog(true)}>
                Export
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className='p-0'>
          { loading
            ? <div className='p-4'>
              <CustomProgress />
            </div>
            : <>
              {sboms.map((sbom, index) => (
                <SbomByLabel
                  key={`${sbom.labelName}-${index}`}
                  data={sbom}
                  orgTags={orgTags}
                  cognito={cognito}
                  isLast={index === sboms.length - 1}
                  setChecked={setChecked}
                  checked={checked}
                  setCurrent={setCurrentSbom}
                  onDelete={toggleDeleteModal}
                />
              ))}
            </>
          }
        </Card.Body>
        <Card.Footer>{count} SBOM{count === 1 ? '' : 's'}</Card.Footer>
      </Card>
      <DeleteModal
        deleteItem={deleteSbom}
        currentItem={currentSbom}
        handleClose={toggleDeleteModal}
        show={showDeleteModal}
      />
      <ExportDialog
        onSubmit={onSubmit}
        resetResponse={() => setResponse('idle')}
        show={openExportDialog}
        email={cognito.email}
        handleClose={() => setOpenExportDialog(false)}
        isDisabled={response === 'error'}
      >
        { processDialog
          ? <CustomProgress />
          : <Notification state={notification[response]}/>
        }
      </ExportDialog>
    </>
  )
}
