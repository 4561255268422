import React from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

const ColorPicker = ({ color, handleChange }) => {
  return (
    <>
      <Form.Control
        className='shadow-none'
        onChange={(e) => handleChange(e)}
        value={color}
        name='color'
        type="color"
        title="Choose your color"
      />
    </>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.any,
  handleChange: PropTypes.func
}

export default ColorPicker
