import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Modal, ButtonGroup } from 'react-bootstrap'
import CustomProgress from '../../CustomProgress'
import CreatableSelect from 'react-select/creatable'
import {
  fetchTags,
  fetchTagsforSbom,
  putTagsForSbom
} from '../../../services/tags'
import TagCreator from '../TagCreator/TagCreatorModalVersion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWandMagicSparkles, faTags } from '@fortawesome/free-solid-svg-icons'
import { selectStyles, SelectOption } from './SelectTag.styles.js'
import _ from 'lodash'

export const SelectTag = ({ cognito, repo, tags: initialOrgTags, setSbomTags, type, updateOrgTags }) => {
  const [tagListValues, setTagListValues] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [openCreationModal, setOpenCreationModal] = useState(false)
  const [createLabel, setCreateLabel] = useState('')
  const [initialValueIds, setInitialValueIds] = useState([])
  const [orgTags, setOrgTags] = useState(initialOrgTags)

  const getTags = async (incomingOrgTags) => {
    setLoading(true)
    try {
      const sbomTags = await fetchTagsforSbom({ orgId: cognito.orgId, sbom: repo, type: type })
      setSbomTags(sbomTags)
      const preparedOptions = stateOptions(incomingOrgTags, sbomTags)
      setTags(preparedOptions)
      const filteredOptions = filteredValues(preparedOptions)
      setTagListValues(filteredOptions)
      setInitialValueIds(_.map(filteredOptions, 'labelId'))
    } catch (error) {
      console.log('get tags [error]', error)
    } finally {
      setLoading(false)
    }
  }
  const updateTags = async () => {
    try {
      const tagListPickedValues = _.map(tagListValues, (tag) => (
        _.pick(tag, 'labelId')
      ))
      await putTagsForSbom({ orgId: cognito.orgId, repo, tagList: tagListPickedValues })
      getTags(orgTags)
    } catch (error) {
      console.log(error.message)
      getTags(orgTags)
    }
  }
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      getTags(orgTags)
    }
  }, [cognito.loading, orgTags])

  const stateOptions = (tagsData, sbomData) => {
    const alreadySelected = _.keyBy(sbomData, 'labelId')

    return tagsData.map((tag) => {
      return ({
        ...tag,
        label: tag.labelName,
        value: tag.labelName,
        name: tag.labelName,
        isSelected: Boolean(alreadySelected[tag.labelId])
      })
    })
  }
  const filteredValues = values => (values.filter((value) => (value.isSelected)))
  const onCreateOption = (label) => {
    // -- opens modal for creation new tag --
    setOpenCreationModal(true)
    setCreateLabel(label)
  }

  const onToggleMenu = () => {
    const valueDifference = _
      .chain(tagListValues)
      .map('labelId')
      .xor(initialValueIds)
      .value()
    if (valueDifference.length) {
      updateTags()
    }
  }
  const onChangeOrgTags = async () => {
    // -- updates orgTags after added new tag from modal Creator --
    try {
      const { data } = await fetchTags({ orgId: cognito.orgId })
      setOrgTags(data)
      getTags(data)
      updateOrgTags(data)
    } catch (error) {
      console.log('onChangeOrgTags [error]', error)
    }
  }

  return (
    <Dropdown
      bsPrefix='custom-drop-down'
      as={ButtonGroup}
      drop='down'
      align='end'
      className='d-inline mx-2'
      onToggle={onToggleMenu}
      autoClose='outside'>
      <Dropdown.Toggle
        variant='outline-secondary'
        className='dropdown-btn-toggle shadow-none'
        id='dropdown-toggle'>
        Tags <FontAwesomeIcon icon={faTags} className='ms-1' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu'>
        <Dropdown.Header>Apply tags to Sbom</Dropdown.Header>
        <Dropdown.Divider />
        { loading
          ? <CustomProgress />
          : <CreatableSelect
            isMulti
            autoFocus
            backspaceRemovesValue={false}
            components={{ Option: SelectOption, IndicatorSeparator: null, DropdownIndicator: null }}
            controlShouldRenderValue={false}
            onCreateOption={onCreateOption}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={(newValue) => setTagListValues(newValue)}
            options={tags}
            placeholder='Filter...'
            styles={selectStyles}
            tabSelectsValue={false}
            value={tagListValues}
          />
        }
        <Dropdown.Item as='a' href='/tags' className='px-4 py-3'>
          <FontAwesomeIcon icon={faWandMagicSparkles} className='me-2' />Edit tags
        </Dropdown.Item>
      </Dropdown.Menu>
      <Modal className='create-tag__modal' size='md' show={openCreationModal} onHide={() => setOpenCreationModal(!openCreationModal)}>
        <TagCreator
          cognito={cognito}
          createLabel={createLabel}
          toggleCreator={() => setOpenCreationModal(!openCreationModal)}
          updateTags={onChangeOrgTags}
        />
      </Modal>
    </Dropdown>
  )
}

SelectTag.propTypes = {
  repo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  cognito: PropTypes.any.isRequired,
  setSbomTags: PropTypes.func,
  updateOrgTags: PropTypes.func
}
