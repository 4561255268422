import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, Overlay, OverlayTrigger } from 'react-bootstrap'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CopyButton = ({ copyText, buttonProps, tooltipProps }) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const copyHandler = async () => {
    const isCopied = await copyText()
    setShow(isCopied)
  }
  const toggleShow = () => setShow(!show)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click me to copy!
    </Tooltip>
  )

  return (
    <>
      <OverlayTrigger
        placement='top'
        delay={{ show: 150, hide: 200 }}
        overlay={renderTooltip}
        rootClose
        rootCloseEvent='click'
      >
        <Button variant='light' className='shadow-none border-opacity-light-btn' onClick={copyHandler} ref={target} {...buttonProps}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </OverlayTrigger>
      <Overlay
        {...tooltipProps}
        target={target.current}
        show={show}
        rootClose
        onHide={toggleShow}
        placement='left'
      >
        {(props) => (
          <Tooltip id='copied-tooltip' {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}

CopyButton.propTypes = {
  copyText: PropTypes.func,
  buttonProps: PropTypes.object,
  tooltipProps: PropTypes.object
}
