import React from 'react'
import './loadingPageStyles.scss'

export const LoadingPage = () => {
  return (
    <div className='d-flex flex-column min-vh-100 gap-3 justify-content-center p-5 text-center'>
      <div className='lead text-body-secondary text-pulse'>
        Loading...
      </div>
    </div>
  )
}
