import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'
import UsersContext from '../hooks/UserManagementContext'
import { Table } from 'react-bootstrap'

const UserTable = (props) => {
  const { setCurrentUser, toggleManageModal } = useContext(UsersContext)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        id: 'email',
        Cell: ({ value, row }) => {
          return (
            <div
              className="text-start text-dark cursor-pointer"
              onClick={() => {
                setCurrentUser(row.values)
                toggleManageModal()
              }}
            >{value}</div>
          )
        }
      },
      {
        Header: 'Enabled',
        accessor: 'enabled'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Role',
        accessor: 'role'
      }
    ],
    []
  )

  const data = React.useMemo(
    () => props.tableData,
    [props.tableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  return (
    <Table {...getTableProps()} responsive hover>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={`header-groups-${i}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th
                key={`header-th-${i}`}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={`row-${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td
                    key={`cell-${i}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

UserTable.propTypes = {
  tableData: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.object
}

export default UserTable
