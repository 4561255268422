import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from 'react-bootstrap'

const SubmitButton = ({ children, loading = false, rule = false, ...props }) => {
  return (
    <Button type='submit' className='button-primary__login' size='lg' {...props} disabled={rule || loading}>
      {loading && <Spinner animation='border' size="sm" variant='light' className='me-2'/>}
      {children}
    </Button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  loading: PropTypes.bool,
  rule: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ])
}

export default SubmitButton
