import React from 'react'
import PropTypes from 'prop-types'
import { Button, ListGroupItem, Stack } from 'react-bootstrap'
import { severities } from '../SeveritiesBadges'

export const VulnListItem = ({ packageName, vulnData, setCurrentVuln, setIsOpen }) => {
  return (
    <ListGroupItem>
      <Stack direction='horizontal'>
        <div>
          {packageName}
        </div>
        <Stack
          direction="horizontal"
          gap={2}
          className='ms-auto'
        >
          {Object.keys(severities).map((severity) =>
            (vulnData[severity] > 0 &&
              <Button
                key={`${vulnData?.packageName}-${severity}`}
                className="btn shadow-none p-0"
                style={{ border: 'none', background: 'none' }}
                type="button"
                onClick={() => {
                  setCurrentVuln(vulnData)
                  setIsOpen(true)
                }}
              >
                {severities[severity]}
              </Button>
            ))}
        </Stack>
      </Stack>
    </ListGroupItem>
  )
}

VulnListItem.propTypes = {
  packageName: PropTypes.string,
  vulnData: PropTypes.object,
  setCurrentVuln: PropTypes.func,
  setIsOpen: PropTypes.func
}
