import React from 'react'
import PropTypes from 'prop-types'

const TableOptions = ({ show, children, oss = false }) => {
  return (
    <div className={`table-options${show ? ' expanded' : ''}${oss ? ' oss' : ''}`}>
      {children}
    </div>
  )
}

TableOptions.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
  oss: PropTypes.bool
}
export default TableOptions
