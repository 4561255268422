import React from 'react'
import { Alert } from 'react-bootstrap'

export const InfoAlert = () => {
  return (
    <Alert
      dismissible
      variant='success'
      className='text-center'>
      We are releasing new features and updates! We apologize for any service disruptions.
    </Alert>
  )
}
