import React from 'react'
import { Toast } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './styles.css'

const ErrorNotification = ({ message = "Oops! We've got an error!", title = 'Error', show, onHide }) => {
  return (
    <Toast onClose={onHide} show={show} delay={3000} autohide className='toast-position'>
      <Toast.Header ><strong className='me-auto text-danger'>{title}</strong></Toast.Header>
      <Toast.Body className='text-danger bg-white'>{message}</Toast.Body>
    </Toast>
  )
}

ErrorNotification.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}

export default ErrorNotification
