import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import propTypes from 'prop-types'

const Breadcrumbs = ({ parents, active }) => {
  return (
    <Breadcrumb label='Current location' listProps={{ className: 'p-2' }}>
      { parents.map((parent, index) => (
        <Breadcrumb.Item key={`${parent.title}-${index}`} href={parent.href} linkProps={{ className: 'breadcrumb-link-blue' }}>
          {parent.title}
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item active>
        {active}
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

Breadcrumbs.propTypes = {
  parents: propTypes.array.isRequired,
  active: propTypes.string.isRequired
}

export default Breadcrumbs
