import PropTypes from 'prop-types'
import http from '../utils/http'
// import _ from 'lodash'

// -- fetch all tags list for Org --
const fetchTags = async ({ orgId }) => {
  try {
    const tags = await http(`/tags/${orgId}`)
    return tags
  } catch (error) {
    console.log('tags error', error)
    return []
  }
}

fetchTags.propTypes = {
  orgId: PropTypes.string | PropTypes.number
}

// -- fetch all tags for the Sbom -- do we still need this?
const fetchTagsforSbom = async ({ orgId, sbom, type }) => {
  const query = `${type}=${sbom}`
  try {
    const { data: tags } = await http(`/sbom/tags?${query}&orgId=${orgId}`)
    if (!tags) {
      return []
    }
    return tags
  } catch (error) {
    console.log('tags error', error)
    return []
  }
}

fetchTagsforSbom.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  sbom: PropTypes.string
}

// -- for given orgId and labelId fetch list of sboms/repos that are marked with this tag label --
const fetchSbomsByTagLabel = async ({ orgId, labelId }) => {
  try {
    const tags = await http(`/tags/${orgId}?labelId=${labelId}`)
    return tags
  } catch (error) {
    console.log('tags error', error)
    return {}
  }
}

fetchSbomsByTagLabel.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  labelId: PropTypes.string | PropTypes.number
}

// -- Post new tag to Org --
const addTag = async ({ orgId, tagData }) => {
  const response = await http('/tags', 'put', undefined, {
    orgId,
    ...tagData
  })
  return response
}

addTag.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  tagData: PropTypes.object
}

// -- Changes tag data for Org --
const editTag = async ({ orgId, tagData }) => {
  const response = await http('/tags', 'patch', undefined, {
    orgId,
    ...tagData
  })
  return response
}

editTag.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  tagData: PropTypes.object
}

// -- Remove tag from Org --
const deleteTag = async ({ orgId, tagData }) => {
  const response = await http('/tags', 'delete', undefined, {
    orgId,
    labelId: tagData.labelId
  })
  return response
}

deleteTag.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  tagData: PropTypes.object
}

// -- Changes tag list for Sbom --
const putTagsForSbom = async ({ orgId, repo, tagList }) => {
  const response = await http('/sbom/tag', 'put', undefined, {
    orgId,
    repo: repo,
    tags: tagList.filter(tag => tag.labelId)
  })
  return response
}

putTagsForSbom.propTypes = {
  orgId: PropTypes.string | PropTypes.number,
  sbom: PropTypes.string,
  tagList: PropTypes.array
}

// -- Applies tag for many repos --
const putTagsForRepos = async ({ orgId, repos, tag: { labelId } }) => {
  const response = await http('/sbom/tags', 'put', undefined, {
    orgId,
    repos,
    labelId
  })

  return response
}

putTagsForRepos.propTypes = {
  orgId: PropTypes.string,
  repos: PropTypes.array,
  tag: PropTypes.object
}

// -- Removes tag from many repos --
const removeTagsFromRepos = async ({ orgId, repos, tag: { labelId } }) => {
  const response = await http('/sbom/tags', 'delete', undefined, {
    orgId,
    repos,
    labelId
  })

  return response
}
removeTagsFromRepos.propTypes = {
  orgId: PropTypes.string,
  repos: PropTypes.arrayOf(PropTypes.string),
  tag: PropTypes.object
}

export {
  fetchTags,
  fetchTagsforSbom,
  fetchSbomsByTagLabel,
  addTag,
  editTag,
  deleteTag,
  putTagsForSbom,
  putTagsForRepos,
  removeTagsFromRepos
}
