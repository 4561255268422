import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, ListGroup } from 'react-bootstrap'
import { TagsTableRow } from './TagsTableRow'
import { DeleteModal } from '../DeleteModal'
import { deleteTag } from '../../services/tags'

export const TagsTable = ({ data: tags, cognito, updateTags }) => {
  const [currentRow, setCurrentRow] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)
  const handleDelete = async (value) => {
    setCurrentRow(value)
    toggleDeleteModal()
  }
  const onDeleteSubmit = async (tagData) => {
    try {
      await deleteTag({ orgId: cognito.orgId, tagData: tagData })
      toggleDeleteModal()
      updateTags()
    } catch (error) {
      toggleDeleteModal()
      console.log('delete tag [error]', error)
    }
  }
  return (
    <Container fluid className='px-0'>
      {tags?.length
        ? <ListGroup variant='flush'>
          { tags.map((tag) => (
            <TagsTableRow
              key={tag.labelName}
              tag={tag}
              cognito={cognito}
              handleDelete={handleDelete}
              updateTags={updateTags}
            />
          ))}
        </ListGroup>
        : <div className='p-3 text-center'>Here will be tags, when you have add the first one.</div>
      }
      <DeleteModal
        currentItem={currentRow.labelName}
        deleteItem={() => {
          onDeleteSubmit(currentRow)
        }}
        show={showDeleteModal}
        handleClose={toggleDeleteModal}
      />
    </Container>
  )
}

TagsTable.propTypes = {
  data: PropTypes.array,
  cognito: PropTypes.object,
  updateTags: PropTypes.func
}
