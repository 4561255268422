import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import growthbook from '../growthBook.config'
import AuthContext from '../context/auth'
import { FeaturesReady } from '@growthbook/growthbook-react'
import { LoadingPage } from '../views/LoadingPage'

export const AuthProvider = () => {
  const [auth, setAuth] = useState({})

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures()
    checkCurrentUser()
  }, [])

  const checkCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      if (currentUser) {
        setAuth(currentUser)
      }
    } catch (error) {
      setAuth('unauthorized')
    }
  }

  return (
    <FeaturesReady fallback={<LoadingPage />}>
      <AuthContext.Provider value={{ auth, setAuth }}>
        <Outlet />
      </AuthContext.Provider>
    </FeaturesReady>
  )
}
