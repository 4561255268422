import React from 'react'
import UserProfile from '../components/UserProfile'
import Notifications from '../components/Notifications'
import RepositorySettings from '../components/RepositorySettings_3'
import { Platforms } from '../components/LanguageSupportSection/Platforms'
import UserManagement from '../components/UserManagement'
import ApiKeyManagement from '../components/ApiKeyManagement'
import Integrations from '../components/Integrations'

import { Navigate } from 'react-router-dom'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'

export const profileRoutes = [
  {
    index: true,
    element: <Navigate to='/profile/user' replace={true} />
  },
  { path: 'user', element: <UserProfile /> },
  { path: 'notifications', element: <Notifications /> },
  { path: 'repositories', element: <RepositorySettings /> },
  { path: 'platforms', element: <Platforms /> },
  { path: 'users', element: <UserManagement /> },
  {
    path: 'apikeys',
    element: <IfFeatureEnabled feature='api-key-management'><ApiKeyManagement /></IfFeatureEnabled>
  },
  {
    path: 'integrations',
    element: <IfFeatureEnabled feature='show-integrations'><Integrations /></IfFeatureEnabled>
  }
]
