import React, { useState, useEffect } from 'react'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import SbomList from './SbomList'
import _, { isEmpty } from 'lodash'
import { fetchVulnsForSource } from '../utils/vulnHelpers'
import { useParams, useNavigate } from 'react-router-dom'
import CustomProgress from './CustomProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faTags } from '@fortawesome/free-solid-svg-icons'
import VulnerabilityModal from './VulnerabilityModal'
import dayjs from 'dayjs'
import { Button, Card, Dropdown, Stack } from 'react-bootstrap'
import { DeleteModal } from './DeleteModal'
import { deleteRepos } from '../services/repos'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ErrorAlert } from './Alerts/ErrorAlert'
import { SelectTag } from './Tags/SelectTag'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { fetchTags } from '../services/tags'
import { SbomTabs } from './Sboms/SbomTabs'
import { SbomTagsInLine } from './Tags/SbomTagsInLine'
import { SbomTagsContext } from '../hooks/SbomTagsContext'
import Breadcrumbs from './Breadcrumbs'
import { useMediaQuery } from 'react-responsive'
import { SelectTagsDrawer } from './Tags/SelectTag/SelectTagsDrawer'
import { SbomVulns } from './SbomVulns'

function fetchSbom(props) {
  const { repo, orgId } = props
  return http('/sbom', 'get', {
    repo: repo,
    orgId: orgId
  })
    .then(response => {
      if (!response.data) {
        return {
          sbom: {},
          prettySPDX: 'No package manifests found in this repository.',
          creationInfo: {
            createdAt: '',
            updatedAt: ''
          },
          tags: []
        }
      }
      const sbom = response.data.sbom
      const creationInfo = {
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt
      }
      const prettySPDX = JSON.stringify(sbom, null, 2)
      const tags = response.data.tags
      return {
        sbom,
        prettySPDX,
        creationInfo,
        tags
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export const RepoDetails = () => {
  const { repo } = useParams()
  const repoUrl = repo.replaceAll('%2F', '/')
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape

  const [openSelectTags, setOpenSelectTags] = useState(false)

  const cognito = useCognitoAuth()
  const [spdx, setSpdx] = useState()
  const [packages, setPackages] = useState()
  const [vulns, setVulns] = useState()
  const [reposLoading, setReposLoading] = useState(true)
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [creationData, setCreationData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [tags, setTags] = useState([])
  const [sbomTags, setSbomTags] = useState([])
  const tagsFeature = useFeatureIsOn('tags')

  const navigate = useNavigate()
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      Promise.all([
        fetchSbom({
          repo: repoUrl,
          orgId: cognito.orgId
        }),
        fetchVulnsForSource(cognito.orgId, repoUrl),
        fetchTags({ orgId: cognito.orgId })
      ]).then((result) => {
        const [sboms, vulns, orgTags] = result
        const { prettySPDX, sbom, creationInfo, tags: repoTags } = sboms
        const vulnsByName = _.keyBy(vulns, 'packageName')
        setTags(orgTags.data)
        setSpdx(prettySPDX)
        setVulns(vulnsByName)
        // setVulns({ ...vulnsByName, test: { ...vulnsByName['http-proxy'] }, test2: { ...vulnsByName['http-proxy'] } })
        setCreationData(creationInfo)
        setPackages(sbom.packages)
        setSbomTags(repoTags)
        setReposLoading(false)
      })
    }
  }, [cognito.orgId, cognito.loading])

  const deleteRepo = async () => {
    setShowDeleteModal(false)
    try {
      await deleteRepos({
        email: cognito.email,
        orgId: cognito.orgId,
        currentRepo: repoUrl
      })
      navigate('/repositories')
    } catch (error) {
      setShowAlert(true)
      console.log(error)
    }
  }

  const idHelper = repo.replaceAll('/', '-')

  const parentRoutes = [
    {
      title: 'Home',
      href: 'https://traxiom.io'
    },
    {
      title: 'factory',
      href: '/'
    },
    {
      title: 'repositories',
      href: '/repositories'
    }
  ]

  const createdAt = creationData.createdAt ? dayjs(creationData.createdAt).format('MM/DD/YYYY HH:mm:ss') : ''
  const updatedAt = creationData.updatedAt ? dayjs(creationData.updatedAt).format('MM/DD/YYYY HH:mm:ss') : ''

  return (
    <>
      <ErrorAlert
        show={showAlert}
        setShow={setShowAlert}
        message={`Sorry we can't delete ${repoUrl} now. Please try again later.`}
      />
      <Breadcrumbs parents={parentRoutes} active={repoUrl} />
      <Card className={`p-0 shadow-sm${isCompact ? ' small' : ''}`}>
        <SbomTagsContext.Provider value={sbomTags}>
          <Card.Header className="d-flex flex-row wrap align-items-center bg-succes">
            <div className='me-2'>
              <FontAwesomeIcon
                onClick={() => navigate(-1)}
                className='btn btn-sm'
                icon={faArrowLeftLong} />
            </div>
            <div className='flex-grow-1 text-wrap'>
              <b>{repoUrl}</b>
            </div>
            { isCompact
              ? <>
                <Dropdown align={'end'}>
                  <Dropdown.Toggle variant='light' />
                  <Dropdown.Menu>
                    { tagsFeature && !reposLoading
                      ? <Dropdown.Item className='d-flex flex-row align-items-center' onClick={() => setOpenSelectTags(true)}>
                        <FontAwesomeIcon icon={faTags} width={24} />
                        <Dropdown.ItemText>Tags</Dropdown.ItemText>
                      </Dropdown.Item>
                      : null
                    }
                    <Dropdown.Item className='d-flex flex-row align-items-center' onClick={() => setShowDeleteModal(true)}>
                      <FontAwesomeIcon icon={faTrashAlt} width={24} />
                      <Dropdown.ItemText>Delete</Dropdown.ItemText>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                { tagsFeature && !reposLoading
                  ? <SelectTagsDrawer
                    repo={repoUrl}
                    cognito={cognito}
                    tags={tags}
                    setSbomTags={setSbomTags}
                    show={openSelectTags}
                    setShow={setOpenSelectTags}
                    type='repo'
                  />
                  : null
                }
              </>
              : <>
                { tagsFeature && !reposLoading
                  ? <div className='d-flex flex-row justify-content-end'>
                    <SelectTag repo={repoUrl} cognito={cognito} tags={tags} updateOrgTags={setTags} setSbomTags={setSbomTags} type='repo' />
                  </div>
                  : null
                }
                <Button
                  variant='link'
                  className='link-body-emphasis'
                  onClick={() => setShowDeleteModal(true)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} width={14} height={16}/>
                </Button>
              </>
            }
          </Card.Header>
          { tagsFeature && !reposLoading && sbomTags?.length
            ? <SbomTagsInLine orgTags={tags} orgId={cognito.orgId} />
            : null }
        </SbomTagsContext.Provider>
        <Card.Body>
          <div className='d-flex flex-row justify-content-between p-2 mb-2'>
            <div>
              <span className='fw-bold me-2'>Created: </span>
              <span className='font-monospace'>{createdAt}</span>
            </div>
            <div>
              <span className='fw-bold me-2'>Updated: </span>
              <span className='font-monospace'>{updatedAt}</span>
            </div>
          </div>
          <SbomTabs
            idHelper={idHelper}
            spdx={spdx}
          >
            {reposLoading
              ? <CustomProgress className='pt-4 pb-2'/>
              : packages
                ? <SbomList
                  setCurrentVuln={setCurrentVuln}
                  setIsOpen={setIsOpen}
                  packages={packages}
                  vulns={vulns}
                />
                : <p className='p-2'>No package manifests found in this repository.</p>}
            { !packages && !isEmpty(vulns) &&
              <Stack direction='vertical' gap={2} className='border-top py-2'>
                <div className='px-2'>
                  <strong>
                    The vulnerabilities are surfaced in the org-wide SBOM and related to this SBOM:
                  </strong>
                </div>
                <SbomVulns
                  vulns={vulns}
                  setCurrentVuln={setCurrentVuln}
                  setIsOpen={setIsOpen} />
              </Stack>
            }
          </SbomTabs>
        </Card.Body>
      </Card>
      <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>
      <DeleteModal
        handleClose={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        currentItem={repoUrl}
        deleteItem={deleteRepo}
      />
    </>
  )
}
