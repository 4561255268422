import React from 'react'
import { Navigate } from 'react-router-dom'
import { getUser } from './getUser'
import http from '../../../utils/http'
import { flattenResponse } from '../../../utils/vulnHelpers'

export const vulnLoader = async () => {
  const cognito = await getUser()

  if (!cognito) {
    return <Navigate to={'/login'} replace />
  }

  const response = await http(`/vuln/${cognito.orgId}`)
  return flattenResponse(response.data)
}
