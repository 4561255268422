import React, { useState } from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import { useLocation } from 'react-router-dom'

function ResetPasswordPage() {
  const location = useLocation()
  const incomingStep = location.state?.step || 1
  const username = location.state?.username || ''
  const [step, setStep] = useState(incomingStep)
  const [confirmedUsername, setConfirmedUsername] = useState(username)

  return (
    <>
      <h3 className='login-header__login'>Reset password</h3>
      {step === 1 && <Step1 setConfirmedUsername={setConfirmedUsername} setStep={setStep} />}
      {step === 2 && <Step2 username={confirmedUsername} />}
    </>
  )
}

export default ResetPasswordPage
