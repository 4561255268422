import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ColorSection, ColorFormLayout, ButtonsSection, SelectOwner } from './TagCreator/TagCreator.styled'
import newShade, { colorValidation } from '../../utils/colorHelper'
import { Container, Col, Form, Row, Alert, Button } from 'react-bootstrap'
import { Tag } from './Tag'
import { editTag } from '../../services/tags'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UsersContext } from './TagsCreation'
import { get } from 'lodash'

export const TagEditor = ({ cognito, tag, handleDelete, setOpenEditor, updateTags }) => {
  const initialTagData = {
    ...tag,
    businessOwner: { value: tag.businessOwner, label: tag.businessOwner },
    technicalOwner: { value: tag.technicalOwner, label: tag.technicalOwner }
  }
  const users = useContext(UsersContext)

  const [topLabel, setToplabel] = useState(initialTagData || {
    labelId: '',
    orgId: '',
    color: '#ab79ec',
    textColor: '#5b299c',
    labelName: 'Tag preview',
    description: '',
    businessOwner: { value: users[0], label: users[0] },
    technicalOwner: { value: users[0], label: users[0] }
  })

  const [color, setColor] = useState(tag.color)
  const [error, setError] = useState({
    status: false,
    message: '',
    placement: null
  })

  const handleColorChange = (e) => {
    changeErrorStatus({})
    const { value } = e.target
    return setColor(value)
  }

  const handleChange = (e) => {
    changeErrorStatus({})
    const { name, value } = e.target
    return setToplabel(() => ({
      ...topLabel,
      [name]: value
    }))
  }

  const handleSelectChange = (value, name) => {
    return setToplabel((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const changeErrorStatus = ({ status = false, message = '', placement = null }) => {
    setError({
      message: message,
      status: status,
      placement: placement
    })
  }
  const onCancel = () => {
    setToplabel(tag)
    setOpenEditor(false)
    changeErrorStatus({})
  }

  useEffect(() => {
    const newColor = colorValidation(color)
    if (newColor) {
      const darkenColor = newShade(`${newColor}`, -50)
      const lightenColor = newShade(`${newColor}`, 50)
      const currentTextColor = darkenColor.length < 7 ? lightenColor : darkenColor
      return setToplabel((prevState) => ({
        ...prevState,
        color: `#${newColor}`,
        textColor: currentTextColor
      }))
    }
  }, [color])

  const onSubmit = async () => {
    try {
      await editTag({
        orgId: cognito.orgId,
        tagData: {
          ...topLabel,
          businessOwner: get(topLabel, ['businessOwner', 'value'], ''),
          technicalOwner: get(topLabel, ['technicalOwner', 'value'], '')
        }
      })
      updateTags()
    } catch (error) {
      if (!error.response.data.message) {
        changeErrorStatus({
          status: true,
          message: 'Sorry :( Something went wrong. Please, contact us and describe what`s happened.',
          placement: 'upper_level'
        })
      }
      if (error.response.data.message) {
        changeErrorStatus({
          status: true,
          message: error.response.data.message,
          placement: 'name_input'
        })
      }
    }
  }
  const editableTitle = topLabel.labelName.length ? topLabel.labelName : 'Tag preview'
  const isDisabled = !topLabel.labelName || !topLabel.businessOwner || !topLabel.technicalOwner
  const styledColumn = 'my-1 my-lg-0 mb-lg-3 mb-3'

  return (
    <>
      <div className='mt-0 mb-2 d-flex flex-row justify-content-between w-100'>
        <Col xs md={3} className='paddingY-bs-btn'>
          <Tag title={editableTitle} color={topLabel.color} textColor={topLabel.textColor} />
        </Col>
        { error.placement === 'upper_level' && error.status
          ? <Col>
            <Alert variant="secondary">{error.message}</Alert>
          </Col>
          : null }
        <Col className='text-end ms-auto px-0 px-md-2' xs='auto'>
          <Button variant='link' className='link-body-emphasis'>
            <FontAwesomeIcon
              icon={faTrashCan}
              width={14}
              height={16}
              onClick={() => handleDelete(tag)}
            />
          </Button>
        </Col>
      </div>
      <Form as={Container} className='mt-3 gx-0 gx-md-2 gx-lg-4'>
        <Row>
          <Col xs={12} lg={{ span: 4, order: 'first' }} className={styledColumn}>
            <Form.Group controlId="TagLabel">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                name='labelName'
                onChange={handleChange}
                type="text"
                placeholder="Tag Name"
                maxLength={50}
                value={topLabel.labelName}
              />
              { error.placement === 'name_input' && error.status
                ? <Form.Text className='text-danger'>{error.message}</Form.Text>
                : null }
            </Form.Group>
          </Col>
          <Col xs={12} lg={{ span: 4, order: '1' }} className={styledColumn}>
            <Form.Group controlId="TagDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name='description'
                onChange={handleChange}
                type="text"
                placeholder="Description (optional)"
                maxLength={100}
                value={topLabel.description}
              />
            </Form.Group>
          </Col>
          <Col xs={{ span: 12, order: '4' }} lg={{ span: 4, order: '2' }} className={styledColumn}>
            <ColorFormLayout>
              <ColorSection
                handleChange={handleColorChange}
                color={color}
                state={topLabel}
              />
            </ColorFormLayout>
          </Col>
          <Col xs={12} lg={{ span: 4, order: '3' }} className={styledColumn}>
            <SelectOwner
              name='businessOwner'
              value={topLabel.businessOwner}
              options={users}
              label={'Business Owner'}
              handleSelectChange={handleSelectChange}
            />
          </Col>
          <Col xs={12} lg={{ span: 4, order: '4' }} className={styledColumn}>
            <SelectOwner
              name='technicalOwner'
              value={topLabel.technicalOwner}
              options={users}
              label={'Technical Owner'}
              handleSelectChange={handleSelectChange}
            />
          </Col>
          <Col xs={{ span: 12, order: 'last' }} lg className='my-1 my-lg-0 mb-lg-3 mb-3 d-flex flex-column justify-content-end'>
            <ButtonsSection
              onCancel={onCancel}
              onSubmit={onSubmit}
              disabled={isDisabled}
              editor
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

TagEditor.propTypes = {
  cognito: PropTypes.any,
  tag: PropTypes.object,
  handleDelete: PropTypes.func,
  setOpenEditor: PropTypes.func,
  updateTags: PropTypes.func
}
