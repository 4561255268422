import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

function Notification ({ state }) {
  const { message, bg, status } = state
  if (status === 'idle') {
    return null
  }

  return (
    <Alert show={true} variant={bg} className={'mb-0'}>
      <span>
        <b>
          {status === 'idle' ? '' : `${status}! `}
        </b>
        {message}
      </span>
    </Alert>
  )
}

Notification.propTypes = {
  state: PropTypes.exact({
    message: PropTypes.string,
    status: PropTypes.oneOf(['idle', 'Success', 'Error']),
    bg: PropTypes.string
  })
}

export default Notification
