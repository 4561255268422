import React, { useState, useEffect } from 'react'
import useCognitoAuth from '../../hooks/useCognitoAuth'
import http from '../../utils/http'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const Python = () => {
  const cognito = useCognitoAuth()
  const [inputValues, setInputValues] = useState({ python: '' })
  const [valid, setValid] = useState(true)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e) => {
    setInputValues({ python: e.target.value })
    setSuccess(false)
    setValid(true)
  }

  const sendValues = async () => {
    const { python } = inputValues
    const regex = /^[\w-]+\.txt+$/
    const fileList = python.split('\n').map((line) => line.trim()).filter(line => line.length !== 0)

    const isValid = fileList.every(elem => regex.test(elem))

    if (!isValid) {
      setValid(false)
      return
    }
    const replacedLineBreak = fileList.join('|')

    try {
      setIsLoading(true)
      const result = await http('/platform/python', 'put', undefined,
        {
          orgId: cognito.orgId,
          pipRequirementsFilter: replacedLineBreak
        })
      if (result) {
        setSuccess(true)
      }
    } catch (error) {
      setSuccess(false)
      console.log('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (cognito.loading === false && cognito.orgId) {
      http(`/platform/${cognito.orgId}/python`)
        .then(response => {
          if (!response.data.python) {
            return
          }
          let savedPipRequirementsFilter = response.data.python.pipRequirementsFilter
          savedPipRequirementsFilter = savedPipRequirementsFilter.replaceAll('\\', '')
          savedPipRequirementsFilter = savedPipRequirementsFilter.replaceAll('|', '\n')
          setInputValues({ python: savedPipRequirementsFilter })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [cognito.orgId])

  return (
    <div className='my-4 mx-2'>
      <label
        className='mb-4'
        htmlFor='pythonTextArea'
      >Pip Requirements Filter</label>
      <div className='m-2'>
        <p className='fw-light'>Add file names used in addition to the standard requirements.txt and requirements-dev.txt naming convention (one filename per line).</p>
      </div>
      <Form.Control
        as='textarea'
        rows={6}
        className='text-code'
        id='pythonTextArea'
        value={inputValues.python}
        onChange={handleChange} />
      <div className='mt-4 d-flex flex-row justify-content-start align-items-center'>
        <div>
          <Button
            variant='primary'
            disabled={!valid || isLoading}
            onClick={sendValues}
          >
            {isLoading ? 'Sending...' : 'Confirm'}
          </Button>
        </div>
        { success && <div className='ms-4 d-flex justify-content-start align-items-center'>
          <FontAwesomeIcon icon={faCheck} width={24} height={24} className='me-2 text-success' />
          <div className='text-success'>Success</div>
        </div>
        }
        {!valid &&
          <div className='ms-4'>
            <p className='p-0 m-0 text-danger'>Invalid characters found</p>
          </div>
        }
      </div>
    </div>
  )
}
