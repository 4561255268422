const pingInitialValues = {
  gitlab: {
    status: 'idle',
    message: null
  },
  github: {
    status: 'idle',
    message: null
  },
  bitbucket: {
    status: 'idle',
    message: null
  }
}

const initialFormValues = {
  github: {
    token: '',
    hostname: '',
    organization: ''
  },
  gitlab: {
    token: '',
    hostname: ''
  },
  bitbucket: {
    token: '',
    hostname: ''
  }
}

const REPOS = ['github', 'gitlab', 'bitbucket']

export { pingInitialValues, initialFormValues, REPOS }
