import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import FilterComponent from './FilterComponent'
import { Stack } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { severities } from './SeveritiesBadges'

const SbomList = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const packages = props.packages
  const vulns = props.vulns

  const customSort = (rows, selector, direction) => {
    return _.orderBy(rows, selector, direction)
  }

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  const filteredItems = packages?.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => { setFilterText(e.target.value) }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle])

  const customStyles = {
    subHeader: {
      style: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    headCells: {
      style: {
        fontSize: isCompact ? 14 : 16,
        fontWeight: 'bold'
      }
    },
    cells: {
      style: {
        fontSize: isCompact ? 14 : 16
      }
    }
  }

  const columns = [
    {
      name: 'Component',
      selector: row => (row.name),
      sortable: true,
      grow: 3
    },
    {
      name: 'Version',
      selector: row => (row.versionInfo),
      sortable: true,
      grow: 1
    },
    {
      name: 'Vulnerabilities',
      sortable: true,
      grow: 2,
      minWidth: '150px',
      right: true,
      selector: row => {
        for (const packageName of Object.keys(vulns)) {
          const vulnerabilities = _.get(vulns, [packageName], {})
          if (packageName === row.name) {
            return (
              <Stack
                direction="horizontal"
                gap={2}
              >
                {Object.keys(severities).map((severity) =>
                  (vulnerabilities[severity] > 0 &&
                    <button
                      key={`${row.name}-${vulns[packageName]}-${severity}`}
                      className="btn shadow-none p-0"
                      style={{ border: 'none', background: 'none' }}
                      type="button"
                      onClick={() => {
                        props.setCurrentVuln(vulns[packageName])
                        props.setIsOpen(true)
                      }}
                    >
                      {severities[severity]}
                    </button>
                  ))}
              </Stack>
            )
          }
        }
      }
    }
  ]

  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={filteredItems}
      sortFunction={customSort}
      pagination
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
    />
  )
}

SbomList.propTypes = {
  vuln: PropTypes.any,
  setCurrentVuln: PropTypes.any,
  setIsOpen: PropTypes.func,
  packages: PropTypes.array,
  vulns: PropTypes.object
}

export default SbomList
