import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { CopyButton } from '../CopyButtonWithTooltip'
import { copyHandler } from '../../utils/copyHandler'

export const SbomTabs = ({ idHelper, spdx, children }) => {
  const [activeTab, setActiveTab] = useState('Components')

  const copyText = async () => {
    return await copyHandler(spdx)
  }

  return (
    <Tabs
      id={idHelper}
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
      className="mb-2"
    >
      <Tab eventKey="Components" title="Components"
        tabClassName='link-secondary'
      >
        {children}
      </Tab>
      <Tab eventKey="JSON" title="JSON" tabClassName='link-secondary'>
        <div className='px-3 py-2 position-absolute end-0'>
          <CopyButton copyText={copyText} buttonProps={{ size: 'lg' }} />
        </div>
        <pre className='m-2 small font-monospace json-format'>
          {spdx}
        </pre>
      </Tab>
    </Tabs>
  )
}

SbomTabs.propTypes = {
  idHelper: PropTypes.string,
  spdx: PropTypes.any,
  children: PropTypes.any
}
