import PropTypes from 'prop-types'

const customStyles = ({ isCompact }) => {
  return ({
    subHeader: {
      style: {
        padding: 0
      }
    },
    headCells: {
      style: {
        fontSize: isCompact ? 14 : 16,
        fontWeight: 'bold'
      }
    },
    cells: {
      style: {
        fontSize: isCompact ? 14 : 16
      }
    }
  })
}
customStyles.propTypes = { isCompact: PropTypes.bool }

const center = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

export {
  customStyles,
  center
}
