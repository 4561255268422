import React, { useState, useEffect, createContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { Card, Button, Collapse, Row, Col } from 'react-bootstrap'
import TagCreator from './TagCreator'
import useCognitoAuth from '../../hooks/useCognitoAuth'
import CustomProgress from '../CustomProgress'
import { TagsTable } from './TagsTable'
import { fetchTags } from '../../services/tags'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { preparedUsersData } from '../../services/users'

export const UsersContext = createContext()

export const TagsCreation = () => {
  const cognito = useCognitoAuth()
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([])
  const [count, setCount] = useState(0)
  const [openCreator, setOpenCreator] = useState(false)
  const toggleCreator = () => setOpenCreator(!openCreator)
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    try {
      const data = await preparedUsersData(cognito)
      setUsers(data)
    } catch (error) {
      console.log('fetch users [error]', error)
    }
  }

  const updateTags = async () => {
    setLoading(true)
    try {
      const { data } = await fetchTags({ orgId: cognito.orgId })
      setTags(data)
      setCount(data.length)
    } catch (error) {
      console.log('fetch tags [error]', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      updateTags()
      fetchUsers()
    }
  }, [cognito.loading])

  return (
    <Card className='px-0'>
      <Card.Header className='text-start' as='h5'>
        <FontAwesomeIcon icon={faTags} /> Tags
      </Card.Header>
      <Card.Body>
        <Card.Body className='px-0'>
          <Row>
            <Col>
              <Card.Text>Use tags to group repos and SBOMs belonging to the same project, team, owner, or any other category you wish.</Card.Text>
            </Col>
            <Col xs={12} md={3}>
              <div className='d-flex flex-row justify-content-end'>
                <Button
                  variant='success'
                  onClick={toggleCreator}
                  aria-controls='collapse-tag-creator'
                  aria-expanded={openCreator}
                >
                  Create new
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
        <UsersContext.Provider value={users}>
          <Collapse in={openCreator} unmountOnExit mountOnEnter>
            <div id='collapse-tag-creator'>
              <TagCreator
                cognito={cognito}
                mode={'create'}
                updateTags={updateTags}
                toggleCreator={toggleCreator}
              />
            </div>
          </Collapse>
          { loading
            ? <CustomProgress />
            : (
              <Card>
                <CardHeader>{count} tags</CardHeader>
                <TagsTable data={tags} cognito={cognito} updateTags={updateTags} />
              </Card>
            )
          }
        </UsersContext.Provider>
      </Card.Body>
    </Card>
  )
}
