import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpider } from '@fortawesome/free-solid-svg-icons'
import VulnerabilityModal from '../components/VulnerabilityModal'
import CustomProgress from '../components/CustomProgress'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { flattenResponse } from '../utils/vulnHelpers'
import VulnerabilitiesTable from '../components/VulnerabilitiesTable'

const FactoryVulnerabilities = () => {
  const cognito = useCognitoAuth()
  const [vulnsLoading, setVulnsLoading] = useState(true)
  const [vulns, setVulns] = useState()
  const [currentVuln, setCurrentVuln] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (cognito.loading === false && cognito.orgId) {
      http(`/vuln/${cognito.orgId}`)
        .then(response => {
          const flat = flattenResponse(response.data)
          setVulns(flat)
          setVulnsLoading(false)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }, [cognito.loading, cognito.orgId])

  return (
    <div className="card shadow-sm p-0">
      <div className="card-header d-flex justify-content-between align-items-center bg-danger text-light">
        <span>
          <FontAwesomeIcon className='mx-1' icon={faSpider} /> <b className='mx-1'>Vulnerabilities</b>
        </span>
      </div>
      <div className="card-body py-0 px-2">
        {vulnsLoading
          ? <CustomProgress className='py-4'/>
          : vulns.length > 0
            ? <VulnerabilitiesTable vulns={vulns} setCurrentVuln={setCurrentVuln} setIsOpen={setIsOpen}/>
            : <p className="text-muted p-4"><small>&#127881; Woo hoo! No vulnerabilities found! &#127881;</small></p>
        }
      </div>

      <VulnerabilityModal vuln={currentVuln} isOpen={isOpen} setIsOpen={setIsOpen}/>

    </div>
  )
}

export default FactoryVulnerabilities
