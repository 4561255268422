import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { Col, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const selectStyles = {
  control: (styles, state) => {
    return ({
      ...styles,
      minWidth: 240,
      margin: 8,
      borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)',
      boxShadow: state.isFocused ? '0 0 0 var(--bs-focus-ring-width) rgba(var(--bs-primary-rgb), 0.25)' : 'none',
      ':hover': {
        borderColor: 'var(--bs-primary)'
      }
    })
  },
  menu: (styles) => ({
    ...styles,
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    boxShadow: 'none',
    borderTop: '1px solid var(--bs-border-color-translucent)',
    borderRadius: 0
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0
  })
}

export const mobileSelectStyles = {
  control: (styles, state) => {
    return ({
      ...styles,
      position: 'sticky',
      minWidth: 240,
      margin: 8,
      borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)',
      boxShadow: state.isFocused ? '0 0 0 var(--bs-focus-ring-width) rgba(var(--bs-primary-rgb), 0.25)' : 'none',
      ':hover': {
        borderColor: 'var(--bs-primary)'
      }
    })
  },
  menu: (styles) => ({
    ...styles,
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    boxShadow: 'none',
    borderRadius: 0,
    height: 'fit-content',
    minHeight: 'fit-content'
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    height: 'fit-content',
    maxHeight: 'fit-content'
  })
}

export const drawerSelectStyles = {
  control: (styles, state) => {
    return ({
      ...styles,
      position: 'sticky',
      minWidth: 240,
      margin: 8,
      borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)',
      boxShadow: state.isFocused ? '0 0 0 var(--bs-focus-ring-width) rgba(var(--bs-primary-rgb), 0.25)' : 'none',
      ':hover': {
        borderColor: 'var(--bs-primary)'
      }
    })
  },
  menu: (styles) => ({
    ...styles,
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
    height: 'fit-content',
    minHeight: 'fit-content'
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    height: 'fit-content',
    maxHeight: 'fit-content'
  })
}

export const SelectOption = (props) => {
  const {
    children,
    innerRef,
    innerProps,
    isSelected,
    data
  } = props
  const { color, description } = data
  return (
    <Dropdown.Item as='div' bsPrefix='custom-dropdown-select-item'>
      <div
        aria-labelledby='row-select'
        className='d-flex flex-row border-bottom py-2 gap-2 dropdown-row' style={{ minHeight: '60px' }}>
        <Col xs className='text-center'>
          {isSelected && <FontAwesomeIcon icon={faCheck} width={20} className='py-1' />}
        </Col>
        <Col {...innerProps} xs={10} className='pe-3 cursor-pointer'>
          <div className='d-flex flex-row align-items-center gap-2'>
            <div className='color-indicator' style={{ backgroundColor: color || '#cacaca' }} />
            <div
              ref={innerRef}
              className='text-truncate cursor-pointer'
            >
              {children}
            </div>
          </div>
          <div className='flex-row text-truncate small'>{description}</div>
        </Col>
      </div>
    </Dropdown.Item>
  )
}

SelectOption.propTypes = {
  children: PropTypes.string,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  isSelected: PropTypes.bool
}

export const Menu = ({ children, ...props }) => {
  return (
    <Fragment>
      <div className='dropdown-helper-text border-top border-bottom p-2 text-center'>
        Select or remove tags for your Sbom
      </div>
      <components.Menu
        {...props}
      >
        {children}
      </components.Menu>
    </Fragment>
  )
}

Menu.propTypes = {
  children: PropTypes.any
}
