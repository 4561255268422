import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { CopyApiKeyButton } from './CopyApiKeyButton'
import { Button, ButtonGroup, Col, ListGroup, Row } from 'react-bootstrap'

const DesktopApiKey = ({ apiKeyId, apiKeyRef, apiKey, visibilityKey, iconEye, toggleVisible, copyKey, handleDelete }) => {
  return (
    <ListGroup.Item as={Row} className='d-flex'>
      <Col xs>{apiKeyId}</Col>
      <Col xs>
        <div className='d-flex flex-row align-items-stretch justify-content-center api-key-base'>
          <div className={'api-key-style'}>
            <pre
              ref={apiKeyRef}
              style={{ visibility: visibilityKey }}
            >
              {apiKey}
            </pre>
          </div>
          <ButtonGroup className='shadow-none'>
            <Button
              variant='light'
              className='shadow-none'
              onClick={toggleVisible}
            >
              <FontAwesomeIcon icon={iconEye} width={24} height={24} />
            </Button>
            <CopyApiKeyButton
              copyKey={copyKey}
              className='shadow-none'
              variant='light'
            />
          </ButtonGroup>
        </div>
      </Col>
      <Col xs='auto'>
        <Button
          variant='link'
          className="link-dark shadow-none"
          onClick={handleDelete}
        ><FontAwesomeIcon icon={faTrashAlt} width={24} height={24} /></Button>
      </Col>
    </ListGroup.Item>
  )
}

DesktopApiKey.propTypes = {
  apiKeyId: PropTypes.string,
  apiKeyRef: PropTypes.object,
  apiKey: PropTypes.string,
  visibilityKey: PropTypes.string,
  iconEye: PropTypes.object,
  toggleVisible: PropTypes.func,
  copyKey: PropTypes.func,
  handleDelete: PropTypes.func
}

export default DesktopApiKey
