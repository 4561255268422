import React, { useEffect, useState } from 'react'
import { faBitbucket, faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack } from 'react-bootstrap'
import useCognitoAuth from '../../hooks/useCognitoAuth'
import { isEmpty, pick, set } from 'lodash'
import GitHubForm from './GitHubForm'
import GitLabForm from './GitLabForm'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import CustomProgress from '../CustomProgress'
import './styles.scss'
import { fetchReposettings, updateReposetting, pingHost } from '../../services/reposettings'
import { pingInitialValues, initialFormValues, REPOS } from './constants'

const RepoSettings = () => {
  const [loading, setLoading] = useState('idle')
  const cognito = useCognitoAuth()
  const [repos, setRepos] = useState(initialFormValues)
  const [isResponse, setIsResponse] = useState(pingInitialValues)

  const pingHostName = async (repo, repoData) => {
    setIsResponse((prevState) => ({
      ...prevState,
      [repo]: set(prevState[repo], 'status', 'pending')
    }))

    if (repo === 'github' && !repoData?.organization.length) {
      return setIsResponse((prevState) => ({
        ...prevState,
        [repo]: set(prevState[repo], 'status', 'idle')
      }))
    }
    if (repo !== 'github' && !repoData?.hostname?.length) {
      return setIsResponse((prevState) => ({
        ...prevState,
        [repo]: set(prevState[repo], 'status', 'idle')
      }))
    }
    try {
      const { data } = await pingHost({
        hostData: {
          ...repoData,
          provider: repo
        }
      })
      setIsResponse((prevState) => ({
        ...prevState,
        [repo]: set(prevState[repo], 'status', data.result ? 'success' : 'error')
      }))
      if (data.result === false) {
        setIsResponse((prevState) => ({
          ...prevState,
          [repo]: set(prevState[repo], 'message', data?.message || 'Request failed with status code 401')
        }))
      }
    } catch (error) {
      console.error('RepoSettings [error] - ping hostname:', error)
      setIsResponse((prevState) => (
        {
          ...prevState,
          [repo]: {
            status: 'idle',
            message: ''
          }
        }
      ))
    }
  }
  const fetchRepoSettings = async () => {
    setLoading('pending')
    try {
      const { data } = await fetchReposettings({ orgId: cognito.orgId })

      if (!data) {
        return setLoading('success')
      }

      const result = pick(data, REPOS)
      setRepos(result)

      for (const repository of Object.entries(result)) {
        const [repositoryProvider, repositoryData] = repository
        if (repositoryData?.token?.length) {
          pingHostName(repositoryProvider, repositoryData)
        }
        if (!repositoryData.token) {
          setIsResponse((prevState) => (
            {
              ...prevState,
              [repositoryProvider]: {
                status: 'idle',
                message: ''
              }
            }
          ))
        }
      }

      setLoading('success')
    } catch (error) {
      console.error('RepoSettings [error] - fetch settings:', error)
      setLoading('error')
    }
  }

  useEffect(() => {
    if (cognito?.orgId) {
      fetchRepoSettings()
    }
  }, [cognito?.orgId])

  const saveChanges = async ({ repo, data }) => {
    try {
      await updateReposetting({ repo, data, orgId: cognito.orgId })
      fetchRepoSettings()
      pingHostName(repo, data)
    } catch (error) {
      console.error('RepoSettings [error] - save changes:', error)
    }
  }

  const removeAccess = async (repo) => {
    try {
      await updateReposetting({ repo, data: initialFormValues[repo], orgId: cognito.orgId })
      fetchRepoSettings()
      setIsResponse((prevState) => ({
        ...prevState,
        [repo]: {
          status: 'idle',
          message: ''
        }
      }))
    } catch (error) {
      console.error('RepoSettings [error] - remove access:', error)
    }
  }

  return (
    <>
      <div className='mb-5'>
        <h2><FontAwesomeIcon icon={faCode} /> Repositories</h2>
        <Stack gap={4} className='mt-3'>
          <Stack gap={2} className='p-3 border border-opacity-25 shadow-sm rounded'>
            <Stack gap={2} direction='horizontal' className='justify-content-between'>
              <h3 className='mb-3'><FontAwesomeIcon icon={faGithub} /> GitHub</h3>
              {!isEmpty(repos.github.token) &&
                <Button className='link-body-emphasis' variant='link' onClick={() => removeAccess('github')}>
                  <FontAwesomeIcon icon={faTrashAlt} height={28} width={28} />
                </Button>
              }
            </Stack>
            {loading === 'success' && <GitHubForm data={repos.github} response={isResponse.github} saveChanges={saveChanges} />}
            {loading === 'pending' && <CustomProgress className={'spinner-area'} />}
          </Stack>
          <Stack gap={2} className='p-3 border border-opacity-25 shadow-sm rounded'>
            <Stack gap={2} direction='horizontal' className='justify-content-between'>
              <h3 className='mb-3'><FontAwesomeIcon icon={faGitlab} /> GitLab</h3>
              {!isEmpty(repos.gitlab.token) &&
                <Button className='link-body-emphasis' variant='link' onClick={() => removeAccess('gitlab')}>
                  <FontAwesomeIcon icon={faTrashAlt} height={28} width={28} />
                </Button>
              }
            </Stack>
            {loading === 'success' && <GitLabForm data={repos.gitlab} response={isResponse.gitlab} saveChanges={saveChanges} />}
            {loading === 'pending' && <CustomProgress className={'spinner-area'} />}
          </Stack>
          <Stack gap={2} className='p-3 border border-opacity-25 shadow-sm rounded'>
            <Stack gap={2} direction='horizontal' className='justify-content-between'>
              <h3 className='mb-3'><FontAwesomeIcon icon={faBitbucket} /> Bitbucket</h3>
              {!isEmpty(repos.bitbucket.token) &&
                <Button className='link-body-emphasis' variant='link' onClick={() => removeAccess('bitbucket')}>
                  <FontAwesomeIcon icon={faTrashAlt} height={28} width={28} />
                </Button>
              }
            </Stack>
            {loading === 'success' && <GitLabForm repo='bitbucket' data={repos.bitbucket} response={isResponse.bitbucket} saveChanges={saveChanges} />}
            {loading === 'pending' && <CustomProgress className={'spinner-area'} />}
          </Stack>
        </Stack>
      </div>
    </>
  )
}

export default RepoSettings
