import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Container, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SubmitButton from '../SubmitButton'

function Step1({ setConfirmedUsername, setStep }) {
  const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
    defaultValues: {
      email: ''
    },
    criteriaMode: 'all',
    mode: 'onChange'
  })
  const [loading, setLoading] = useState(false)

  async function firstStep(data) {
    clearErrors('root.serverError')
    // Send confirmation code to user's email
    setLoading(true)
    try {
      await Auth.forgotPassword(data.email)
      setConfirmedUsername(data.email)
      setStep(2)
    } catch (error) {
      console.log(error)
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Container as={Form} onSubmit={handleSubmit(firstStep)} fluid className='d-grid gap-3'>

        <Form.Group>
          <Form.Control
            className='custom-input__login'
            type='email'
            placeholder='Enter your email'
            isInvalid={errors.email?.type === 'required'}
            { ...register('email', { required: { value: true, message: 'email is required' } }) }
          />
          <Form.Control.Feedback type='invalid' >{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton rule={errors?.email?.message} loading={loading}>Send code</SubmitButton>

        <Form.Text as={Link} to={'/login'} className='hoverable-link__login'>Back to Sign In?</Form.Text>
      </Container>
    </>
  )
}

Step1.propTypes = {
  setConfirmedUsername: PropTypes.func,
  setStep: PropTypes.func
}

export default Step1
