import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faPlus } from '@fortawesome/free-solid-svg-icons'
import VendorSbomsTable from '../components/VendorSbomsTable'
import CustomProgress from '../components/CustomProgress'
import useCognitoAuth from '../hooks/useCognitoAuth'
import { AddVendorSbomModal } from '../components/AddVendorSbomModal'
import { fetchVendors, deleteVendors } from '../services/vendors'
import { DeleteModal } from '../components/DeleteModal'
import { Button } from 'react-bootstrap'

const FactoryVendors = () => {
  const cognito = useCognitoAuth()
  const [vendorsLoading, setVendorsLoading] = useState(true)
  const [vendors, setVendors] = useState()
  const [visibleAlert, setVisibleAlert] = useState({
    status: false,
    message: ''
  })

  const [currentVendor, setCurrentVendor] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [showAddModal, setShowAddModal] = useState(false)
  const handleCloseAddModal = () => setShowAddModal(false)

  const onVendorsChangeHandler = () => {
    setVendorsLoading(true)
    fetchVendors({
      cognito,
      setVendors,
      setVendorsLoading
    })
  }

  const deleteModalHandler = (vendor) => {
    setCurrentVendor(vendor)
    setShow(true)
  }

  const deleteVendor = async () => {
    handleClose()
    try {
      await deleteVendors({
        orgId: cognito.orgId,
        vendor: currentVendor
      })
      onVendorsChangeHandler()
    } catch (error) {
      console.log(error)
    }
  }

  const callAddModal = () => {
    setShowAddModal(true)
    setVisibleAlert({ status: false, message: '' })
  }

  const addAlert = (message) => {
    setVisibleAlert({ status: true, message: message })
  }

  useEffect(() => {
    fetchVendors({
      cognito,
      setVendors,
      setVendorsLoading
    })
  }, [cognito.loading, cognito.orgId])

  return (
    <>
      {visibleAlert.status && <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
        {visibleAlert.message}
        <button type="button" className="btn-close" onClick={() => setVisibleAlert({ status: false, message: '' })} aria-label="Close"></button>
      </div>}
      <div className="card p-0 shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>
            <FontAwesomeIcon className='mx-1' icon={faTruck} /> <b className='mx-1'>Vendor SBoMs</b>
          </span>
          <Button
            size='sm'
            variant="primary"
            onClick={callAddModal}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <div className="card-body py-0 px-2">
          {vendorsLoading
            ? <CustomProgress className='py-4'/>
            : vendors.length > 0
              ? <VendorSbomsTable deleteHandler={deleteModalHandler} vendors={vendors} />
              : <p className="text-muted p-4"><small>Secure your supply chain by adding vendor SBoMs.</small></p>
          }
        </div>
        <DeleteModal
          handleClose={handleClose}
          show={show}
          currentItem={currentVendor}
          deleteItem={deleteVendor}
        />
        <AddVendorSbomModal
          show={showAddModal}
          handleClose={handleCloseAddModal}
          needUpdate={onVendorsChangeHandler}
          addAlert={addAlert}/>
      </div>
    </>
  )
}

export default FactoryVendors
