import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Form, FormControl, FormGroup, FormLabel, InputGroup, Spinner } from 'react-bootstrap'

export const hostStatus = (status) => {
  const variant = {
    idle: {
      className: 'text-secondary',
      icon: <FontAwesomeIcon width={18} icon={faMinus} />
    },
    pending: {
      className: 'bg-secondary border-secondary text-light',
      icon: <Spinner variant='border' size='sm' />
    },
    success: {
      className: 'bg-success border-success text-light',
      icon: <FontAwesomeIcon width={18} icon={faCheck} />
    },
    error: {
      className: 'bg-danger border-danger text-light',
      icon: <FontAwesomeIcon width={18} icon={faClose} />
    }
  }
  return variant[status] || variant.idle
}

const HostInputRS = ({
  register,
  name,
  controlId,
  response = 'idle',
  type = 'text',
  label = '',
  edit = false,
  adds = false,
  ping = true,
  rules = {},
  error = false,
  errorMessage = ''
}) => {
  return (
    <FormGroup controlId={controlId}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {ping && <InputGroup.Text className={`${hostStatus(response).className}`}>{hostStatus(response).icon}</InputGroup.Text>}
        {adds && <InputGroup.Text>{adds}</InputGroup.Text>}
        <FormControl {...register(name, rules)} isInvalid={error} type={type} className="shadow-none" disabled={!edit} />
      </InputGroup>
      <Form.Text className='text-danger'>{errorMessage}</Form.Text>
    </FormGroup>
  )
}

HostInputRS.propTypes = {
  register: PropTypes.func.isRequired,
  controlId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  response: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  edit: PropTypes.bool,
  adds: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([undefined, false])]),
  ping: PropTypes.bool,
  rules: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
}

export default HostInputRS
