import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Form, InputGroup, Alert } from 'react-bootstrap'
import SubmitButton from './SubmitButton'
import {
  Link,
  useNavigate,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import AuthContext from '../../context/auth'

const defaultValues = {
  email: '',
  password: ''
}
function LoginForm({ setUserFirstSession, setVerification }) {
  const { setAuth } = useContext(AuthContext)

  const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
    defaultValues,
    criteriaMode: 'all',
    mode: 'onChange'
  })
  const [isVisiblePassword, setIsVisiblePassvord] = useState(false)
  const toggleVisibility = () => setIsVisiblePassvord(!isVisiblePassword)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const onSubmit = async (data) => {
    clearErrors('root.serverError')
    signIn(data)
  }

  async function signIn({ email, password }) {
    setLoading(true)
    try {
      const user = await Auth.signIn(email, password)
      // console.log('user', user)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return setUserFirstSession(user)
      }
      if (!user.attributes.email_verified) {
        // console.log('need to verify email')
        setAuth(user)
        return setVerification(user)
      }

      setLoading(false)
      setAuth(user)
      navigate(from, { replace: true })
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        navigate('/reset_password', { replace: true, state: { username: email, step: 2 } })
      }

      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }

  return (
    <>
      <h3 className='login-header__login'>Sign in</h3>
      <Container as={Form} onSubmit={handleSubmit(onSubmit)} fluid className='d-grid gap-3'>

        <Form.Group>
          <Form.Control
            className='custom-input__login'
            type='email'
            placeholder='Email'
            isInvalid={errors.email?.type === 'required'}
            { ...register('email', {
              required: {
                value: true,
                message: 'email is required'
              }
            }) }
          />
          <Form.Control.Feedback type='invalid' >{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <InputGroup hasValidation className='px-0'>
            <Form.Control
              className='custom-input__login'
              type={isVisiblePassword ? 'text' : 'password'}
              placeholder='Password'
              isInvalid={errors.password?.type === 'required'}
              { ...register('password', { required: { value: true, message: 'password is required' } }) }
            />
            <Button onClick={toggleVisibility}><FontAwesomeIcon width={26} icon={isVisiblePassword ? faEyeSlash : faEye}/></Button>
            <Form.Control.Feedback type='invalid' >{errors.password?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton
          rule={errors.email?.message || errors.password?.message}
          loading={loading}
        >
          Sign in
        </SubmitButton>

        <Form.Text as={Link} to={'/reset_password'} className='hoverable-link__login'>Forgot your password?</Form.Text>
      </Container>
    </>
  )
}

LoginForm.propTypes = {
  setUserFirstSession: PropTypes.func,
  setVerification: PropTypes.func
}

export default LoginForm
