import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { Button, Form, FormCheck, FormGroup, Modal } from 'react-bootstrap'

const makeApiKey = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < 32; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

const AddApiKeyModal = ({ show, toggleShow, onApiKeysChange }) => {
  const cognito = useCognitoAuth()
  const [apiKeyName, setApiKeyName] = useState('')
  const [permission, setPermission] = useState('Admin')
  const [fieldError, setFieldError] = useState('')
  const [disableAddButton, setDisableAddButton] = useState(true)

  const addApiKey = () => {
    if (!cognito.loading) {
      const timeStampEpoch = Date.now()
      const timeStamp = new Date(timeStampEpoch)
      const timeStampISO = timeStamp.toISOString()
      http('/apikey', 'put', undefined, {
        orgId: cognito.orgId,
        apiKeyId: apiKeyName,
        apiKey: makeApiKey(),
        addedOn: timeStampISO,
        addedBy: cognito.userId,
        permission: permission
      })
        .then(response => {
          onApiKeysChange()
          toggleShow()
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }

  const validationHandler = (e) => {
    const inputValue = e.target.value

    if (inputValue.length === 0) {
      setFieldError('API Key Name must not be empty.')
      setDisableAddButton(true)
    }

    if (inputValue.length > 0 && !inputValue.match(/^[-a-zA-Z0-9]+$/)) {
      setFieldError('Only a-z, A-Z, 0-9, and hyphen (-) characters.')
      setDisableAddButton(true)
    }

    if (inputValue.length > 0 && inputValue.match(/^[-a-zA-Z0-9]+$/)) {
      setFieldError('')
      setDisableAddButton(false)
    }
  }

  const permissionLevels = ['Admin', 'CICD', 'Vendor']

  const handleChangeRadio = (e) => {
    setPermission(e.target.value)
  }

  const Radiobuttons = () => {
    return (
      permissionLevels.map((permissionLevel, index) => (
        <FormCheck
          key={`permission-key-${index}`}
          className="mb-3">
          <FormCheck.Input
            type="radio"
            id={permissionLevel}
            value={permissionLevel}
            checked={permissionLevel === permission}
            onChange={handleChangeRadio}
            name={'permissionLevel'}
            required/>
          <FormCheck.Label className="form-check-label" htmlFor={permissionLevel}>{permissionLevel}</FormCheck.Label>
        </FormCheck>
      )))
  }

  return (
    <Modal show={show} onHide={toggleShow} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add API Key
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <FormGroup>
          <Form.Label htmlFor="apiKeyName">Give your API Key a name</Form.Label>
          <Form.Control
            type="text"
            className="mb-2"
            id="apiKeyName"
            aria-describedby="apiKeyNameHelp"
            placeholder="my-api-key"
            value={apiKeyName}
            onInput={e => setApiKeyName(e.target.value)}
            onChange={validationHandler} />
          <div className='validation-field'>
            <Form.Text id="apiKeyNameHelp" className="small text-danger">{fieldError}</Form.Text>
          </div>
          <Radiobuttons />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={addApiKey} disabled={disableAddButton}>
          Add
        </Button>
        <Button variant="secondary" onClick={toggleShow}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AddApiKeyModal.propTypes = {
  onApiKeysChange: PropTypes.func,
  show: PropTypes.bool,
  toggleShow: PropTypes.func
}
export default AddApiKeyModal
