import PropTypes from 'prop-types'
import http from '../utils/http'
import { fetchTags } from './tags'

export const fetchOss = async ({ orgId }) => {
  try {
    const repos = await http(`/oss/${orgId}`)
    return repos
  } catch (error) {
    console.error('[fetchRepos][error]', error)
  }
}

fetchOss.propTypes = {
  orgId: PropTypes.string
}

export const fetchOssAndTags = async ({ cognito }) => {
  try {
    if (!cognito.loading && cognito.orgId) {
      const response = await Promise.all([
        fetchOss({ orgId: cognito.orgId }),
        fetchTags({ orgId: cognito.orgId })
      ])
      const [{ data: oss }, { data: tags }] = response
      return { oss, tags, loading: false }
    }
  } catch (error) {
    console.error('[useOssAndTags][error]', error)
    return { repos: [], tags: [], loading: false }
  }
}

fetchOssAndTags.propTypes = {
  cognito: PropTypes.any
}

export const deleteOss = async ({ orgId, currentOssUrl }) => {
  try {
    return await Promise.all([
      http('/oss', 'delete', undefined, {
        orgId: orgId,
        url: currentOssUrl
      }),
      http('/sbom', 'delete', undefined, {
        orgId: orgId,
        repo: currentOssUrl
      }),
      http('/vuln', 'delete', undefined, {
        sourceName: currentOssUrl
      })
    ])
  } catch (err) {
    return console.log(err)
  }
}

deleteOss.propTypes = {
  currentOssUrl: PropTypes.string,
  orgId: PropTypes.string
}

export const deleteManyOss = async ({ orgId, urls }) => {
  const data = {
    orgId,
    urls
  }

  const response = await Promise.all([
    http('/oss/multiple', 'delete', undefined, data),
    http('/sbom/multiple', 'delete', undefined, data),
    http('/vuln/multiple', 'delete', undefined, {
      sourceNames: urls
    })
  ])

  return response
}

deleteManyOss.propTypes = {
  urls: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  orgId: PropTypes.string.isRequired
}
