import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons'

export const ServerSearchComponent = ({ onSearch, onClean, searchString, reset = false }) => {
  const { register, handleSubmit, formState: { errors }, resetField, watch, setValue } = useForm({
    defaultValues: {
      search: ''
    }
  })

  const onSubmit = ({ search }) => {
    onSearch(search)
  }
  const rules = {
    minLength: {
      value: 2
    },
    required: true
  }

  const onReset = () => {
    resetField('search')
  }

  const handleReset = async () => {
    if (searchString?.length) {
      onClean()
    }
    onReset()
  }

  const watchField = watch('search')
  useEffect(() => {
    if (searchString?.length) {
      setValue('search', searchString)
    }
  }, [searchString])

  useEffect(() => {
    if (reset) {
      handleReset()
    }
  }, [reset])

  return (
    <div className='d-flex flex-row column-gap-3 flex-grow-1'>
      <Form onSubmit={handleSubmit(onSubmit)} className='flex-grow-1'>
        <InputGroup className='flex-nowrap'>
          <div className='d-flex flex-grow-1 position-relative align-items-center'>
            <Form.Control className='rounded-end-0 pe-5' type='text' placeholder='Search...' {...register('search', rules)} />
            <div
              className={`btn text-body-tertiary rounded-0 position-absolute end-0 ${watchField?.length > 0 || searchString?.length > 0 ? 'visible' : 'invisible'}`}
              onClick={handleReset}
            >
              <FontAwesomeIcon icon={faXmarkCircle} width={24} />
            </div>
          </div>
          <Button variant='primary' type='submit' disabled={!isEmpty(errors)}>
            <FontAwesomeIcon icon={faSearch} width={24} />
          </Button>
        </InputGroup>
      </Form>
    </div>
  )
}

ServerSearchComponent.propTypes = {
  onSearch: PropTypes.func,
  onClean: PropTypes.func,
  searchString: PropTypes.string,
  reset: PropTypes.bool
}
