import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Offcanvas } from 'react-bootstrap'
import CustomProgress from '../../CustomProgress'
import Select from 'react-select'
import {
  fetchTagsforSbom,
  putTagsForSbom
} from '../../../services/tags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { drawerSelectStyles, SelectOption } from './SelectTag.styles.js'
import _ from 'lodash'

export const SelectTagsDrawer = ({ cognito, repo, tags: orgTags, setSbomTags, type, show, setShow }) => {
  const [tagListValues, setTagListValues] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialValueIds, setInitialValueIds] = useState([])

  const getTags = async (incomingOrgTags) => {
    setLoading(true)
    try {
      const sbomTags = await fetchTagsforSbom({ orgId: cognito.orgId, sbom: repo, type: type })
      setSbomTags(sbomTags)
      const preparedOptions = stateOptions(incomingOrgTags, sbomTags)
      setTags(preparedOptions)
      const filteredOptions = filteredValues(preparedOptions)
      setTagListValues(filteredOptions)
      setInitialValueIds(_.map(filteredOptions, 'labelId'))
    } catch (error) {
      console.log('get tags [error]', error)
    } finally {
      setLoading(false)
    }
  }
  const updateTags = async () => {
    try {
      const tagListPickedValues = _.map(tagListValues, (tag) => (
        _.pick(tag, 'labelId')
      ))
      await putTagsForSbom({ orgId: cognito.orgId, repo, tagList: tagListPickedValues })
      getTags(orgTags)
    } catch (error) {
      console.log(error.message)
      getTags(orgTags)
    }
  }
  useEffect(() => {
    if (!cognito.loading && cognito.orgId) {
      getTags(orgTags)
    }
  }, [cognito.loading, orgTags])

  const stateOptions = (tagsData, sbomData) => {
    const alreadySelected = _.keyBy(sbomData, 'labelId')

    return tagsData.map((tag) => {
      return ({
        ...tag,
        label: tag.labelName,
        value: tag.labelName,
        name: tag.labelName,
        isSelected: Boolean(alreadySelected[tag.labelId])
      })
    })
  }
  const filteredValues = values => (values.filter((value) => (value.isSelected)))

  const onToggleMenu = () => {
    const valueDifference = _
      .chain(tagListValues)
      .map('labelId')
      .xor(initialValueIds)
      .value()
    if (valueDifference.length) {
      updateTags()
    }
  }

  const handleClose = () => {
    setShow(false)
    onToggleMenu()
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement='bottom' className='vh-80'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Apply tags to Sbom</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='py-0'>
        { loading
          ? <CustomProgress />
          : <Select
            isMulti
            blurInputOnSelect={false}
            backspaceRemovesValue={false}
            components={{ Option: SelectOption, IndicatorSeparator: null, DropdownIndicator: null }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={(newValue) => setTagListValues(newValue)}
            options={tags}
            placeholder='Filter...'
            styles={drawerSelectStyles}
            tabSelectsValue={false}
            value={tagListValues}
          />
        }
      </Offcanvas.Body>
      <Button variant='link' href='/tags' className='text-dark text-start p-4 border-top'>
        <FontAwesomeIcon icon={faWandMagicSparkles} className='me-2' width={20} height={20} />Create or edit tags
      </Button>
    </Offcanvas>
  )
}

SelectTagsDrawer.propTypes = {
  repo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  cognito: PropTypes.any.isRequired,
  setSbomTags: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func
}
