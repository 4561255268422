import React from 'react'
import PropTypes from 'prop-types'
import PrivateNav from '../components/PrivateNav'
import PrivateFooter from '../components/PrivateFooter'
// import { Container } from 'react-bootstrap'
import SideBar from '../components/SideBar/SideBar'
import MobileSideBar from '../components/SideBar/MobileSideBar'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'

const PrivateLayout = ({ list }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  })
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape

  return (
    <>
      <div className='main-container-wrapper p-0 m-0'>
        <PrivateNav />
        <div className={`${isDesktopOrLaptop ? 'd-flex flex-row flex-nowrap flex-grow-1' : 'flex-grow-1 mb-4'}`}>
          { isDesktopOrLaptop
            ? <SideBar list={list} />
            : <MobileSideBar list={list} />
          }
          <div className={isDesktopOrLaptop ? 'py-4 px-3 flex-grow-1 flex-shrink-2' : `px-2 px-sm-3 flex-grow-1 mb-auto${isCompact ? ' small' : ''}`}>
            <Outlet />
          </div>
        </div>
        <div className={`d-flex flex-row w-100 flex-shink-0${isCompact ? ' small' : ''}`}>
          <PrivateFooter/>
        </div>
      </div>
    </>
  )
}

PrivateLayout.propTypes = {
  list: PropTypes.any
}

export default PrivateLayout
