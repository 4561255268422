import React from 'react'
import LoginButton from './loginbutton'
import LogoutButton from './logoutbutton'
import useCognitoAuth from '../hooks/useCognitoAuth'

const AuthenticationButton = () => {
  const cognito = useCognitoAuth()

  return (cognito.userId && !cognito.loading) ? <LogoutButton /> : <LoginButton />
}

export default AuthenticationButton
