import React from 'react'
import propTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import _ from 'lodash'
import FilterComponent from './FilterComponent'
import { Stack } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { severities } from './SeveritiesBadges'

const VulnerabilitiesTable = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isMobileLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: '420px' })
  const isCompact = isMobile || isMobileLandscape
  const { vulns, setCurrentVuln, setIsOpen } = props

  const customSort = (rows, selector, direction) => {
    return _.orderBy(rows, selector, direction)
  }
  const customStyle = {
    subHeader: {
      style: {
        padding: 0
      }
    },
    headCells: {
      style: {
        fontSize: isCompact ? 14 : 16,
        fontWeight: 'bold'
      }
    },
    cells: {
      style: {
        fontSize: isCompact ? 14 : 16
      }
    }
  }

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  const filteredItems = vulns.filter(
    item => item.packageName && item.packageName.toLowerCase().includes(filterText.toLowerCase())
  )

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => { setFilterText(e.target.value) }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle])

  const columns = [
    {
      name: 'Package name',
      id: 'packageName',
      minWidth: '110px',
      sortable: true,
      grow: 3,
      selector: row => row.packageName
    },
    {
      name: 'Type',
      id: 'packageManager',
      sortable: true,
      selector: row => (
        row.packageManager
      ),
      grow: 0
    },
    {
      name: 'Repo',
      id: 'repo',
      sortable: true,
      grow: 3,
      minWidth: '280px',
      selector: row => row.repo
    },
    {
      id: 'severity',
      right: true,
      minWidth: '150px',
      grow: 2,
      selector: row => (
        <Stack
          direction="horizontal"
          gap={2}
        >
          {Object.keys(severities).map((severity) =>
            (row[severity] > 0 &&
              <button
                key={`${row}-${severity}`}
                className="btn shadow-none p-0"
                style={{ border: 'none', background: 'none' }}
                type="button"
                onClick={() => {
                  setCurrentVuln(row)
                  setIsOpen(true)
                }}
              >
                {severities[severity]}
              </button>
            ))}
        </Stack>
      )
    }
  ]
  return (
    <DataTable
      customStyles={customStyle}
      columns={columns}
      data={filteredItems}
      sortFunction={customSort}
      pagination
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
    />
  )
}

VulnerabilitiesTable.propTypes = {
  vulns: propTypes.array,
  setCurrentVuln: propTypes.any,
  setIsOpen: propTypes.func
}

export default VulnerabilitiesTable
