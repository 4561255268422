import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import useCognitoAuth from '../hooks/useCognitoAuth'
import { faUser, faBell, faCode, faUsers, faKey, faBoxArchive, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { filter, concat } from 'lodash'
import PrivateLayout from './PrivateLayout'
import { InfoAlert } from '../components/InfoAlert'

const Profile = () => {
  const cognito = useCognitoAuth()
  const location = useLocation()
  const adminRoutes = [
    '/profile/repositories',
    '/profile/platforms',
    '/profile/users',
    '/profile/apikeys',
    '/profile/integrations'
  ]
  const visibleIntegrations = useFeatureIsOn('show-integrations')
  const visibleApiKeys = useFeatureIsOn('api-key-management')
  const allUsersList = [
    {
      title: 'Profile',
      icon: faUser,
      route: '/profile/user',
      enabled: true
    },
    {
      title: 'Notifications',
      icon: faBell,
      route: '/profile/notifications',
      enabled: true
    }
  ]

  const adminList = [
    {
      title: 'Repositories',
      icon: faCode,
      route: '/profile/repositories',
      enabled: true
    },
    {
      title: 'Platforms',
      icon: faBoxArchive,
      route: '/profile/platforms',
      enabled: true
    },
    {
      title: 'Users',
      icon: faUsers,
      route: '/profile/users',
      enabled: true
    },
    {
      title: 'API Keys',
      icon: faKey,
      route: '/profile/apikeys',
      enabled: visibleApiKeys
    },
    {
      title: 'Integrations',
      icon: faPuzzlePiece,
      route: '/profile/integrations',
      enabled: visibleIntegrations
    }
  ]

  const filteredList = filter(adminList, { enabled: true })

  if (adminRoutes.includes(location.pathname)) {
    if (!cognito.loading && !cognito.isAdmin) {
      return <Navigate to='/profile/user' replace={true} />
    }
  }
  const routeList = cognito.isAdmin ? concat(allUsersList, filteredList) : allUsersList

  return (
    <>
      <PrivateLayout list={routeList}>
        <InfoAlert />
        <div className="row mx-auto">
          <Outlet />
        </div>
      </PrivateLayout>
    </>
  )
}

export default Profile
