import React from 'react'
import { Row } from 'react-bootstrap'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'

export const LoginFooter = () => {
  const currentYear = dayjs().year().toString()
  return (
    <>
      <footer className='footer pb-2 w-100 container'>
        <div className='d-flex flex-row justify-content-center gap-2 my-3'>
          <a
            className='btn button-social__login btn-social mx-2'
            href='https://twitter.com/traxiom'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            className='btn button-social__login btn-social mx-2'
            href='https://linkedin.com/company/traxiom-sbom'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
        <div className='container px-0 border-top border-secondary-subtle'>
          <Row className='align-items-center my-2'>
            <div className='col px-0 d-flex flex-row justify-content-end gap-3 align-items-center'>
              <div>
                <a
                  href='https://docs.traxiom.io/privacy-policy/'
                  target='_blank'
                  rel='noreferrer'
                  className='small text-break text-dark'
                >
                  Privacy Policy
                </a>
              </div>
              <div>
                <a href='https://docs.traxiom.io' target='_blank' rel='noreferrer' className='small text-dark'>
                  Docs
                </a>
              </div>
            </div>
            <div className='col-auto px-0 mx-3 custom-color__vr'/>
            <div className='col px-0 text-start'><small>Copyright &copy; Traxiom</small></div>
          </Row>
          <div className='text-center text-muted my-2 small'>2022-{currentYear}</div>
        </div>
      </footer>
    </>
  )
}
