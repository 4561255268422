import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import useCognitoAuth from '../hooks/useCognitoAuth'
import CustomProgress from './CustomProgress'

const UserProfile = () => {
  const cognito = useCognitoAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!cognito.loading) {
      setLoading(false)
    }
  }, [cognito.loading])

  return (
    <>
      <h2><FontAwesomeIcon icon={faUser} /> User Profile</h2>
      {loading && cognito === false
        ? <CustomProgress className='align-items-center min-heigth-200' />
        : <>
          <table className="table mb-4">
            <tbody>
              <tr>
                <td>Email</td>
                <td>{cognito.email}</td>
              </tr>
              <tr>
                <td>User ID</td>
                <td>{cognito.userId}</td>
              </tr>
              <tr>
                <td>Organization ID</td>
                <td>{cognito.orgId}</td>
              </tr>
              <tr>
                <td>Access</td>
                <td>
                  {cognito.isAdmin
                    ? <span className="badge bg-dark">Admin</span>
                    : <span className="badge bg-secondary">Operator</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </>
      }
    </>
  )
}

export default UserProfile
