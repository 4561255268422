import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import PropTypes from 'prop-types'

const ProgressContainer = ({ children, invisible }) => (
  <div className={`w-100 ${!invisible ? 'visible' : 'invisible'}`}>
    {children}
  </div>
)
ProgressContainer.propTypes = {
  children: PropTypes.node,
  invisible: PropTypes.bool
}

export const ProgressLoadingBar = ({ loading, queued, error }) => {
  const [invisible, setInvisible] = useState(true)
  const message = 'Please wait until we process your request...'

  useEffect(() => {
    if (error) {
      return setInvisible(true)
    }
    if (queued && !loading) {
      return setInvisible(false)
    }

    if (!queued && loading < 100) {
      setInvisible(false)
    }
    if (!queued && loading === 100) {
      const delay = setTimeout(() => {
        setInvisible(true)
      }, 2500)
      return () => clearTimeout(delay)
    }
  }, [error, loading, queued])

  return (
    <ProgressContainer invisible={invisible}>
      { queued && <div className='alert alert-info p-2 w-100 text-center'>{message}</div> }
      { !queued
        ? <ProgressBar variant='info' animated now={loading} label={`${loading}%`} visuallyHidden={loading === null} />
        : null
      }
    </ProgressContainer>
  )
}
ProgressLoadingBar.propTypes = {
  loading: PropTypes.number,
  queued: PropTypes.bool,
  error: PropTypes.bool
}
