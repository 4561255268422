import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { Tooltip, Overlay, Button } from 'react-bootstrap'

export const CopyApiKeyButton = ({ copyKey, ...props }) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const handleCopyKey = async () => {
    const isCopied = await copyKey()
    setShow(isCopied)
  }
  const toggleShow = () => setShow(!show)

  return (
    <>
      <Button
        onClick={handleCopyKey}
        {...props}
        type="button"
        ref={target}
      ><FontAwesomeIcon icon={faCopy} width={24} height={24}/></Button>
      <Overlay
        target={target.current}
        show={show}
        rootClose
        onHide={toggleShow}
        placement='top'
      >
        {(props) => (<Tooltip id='copied-tooltip' {...props}>Copied!</Tooltip>)}
      </Overlay>
    </>
  )
}

CopyApiKeyButton.propTypes = {
  copyKey: PropTypes.func
}
