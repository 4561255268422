import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useCognitoAuth from '../hooks/useCognitoAuth'
import http from '../utils/http'
import { Modal, Form, Button } from 'react-bootstrap'

export const AddVendorSbomModal = (props) => {
  const { needUpdate, addAlert, show, handleClose } = props
  const cognito = useCognitoAuth()
  const [sbomName, setSbomName] = useState('')
  const [sbomFile, setSbomFile] = useState({})
  const [valid, setValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const addVendorSbom = () => {
    handleClose()
    if (!cognito.loading) {
      const formData = new FormData()
      formData.append('vendorSbom', sbomFile)
      formData.append('sbomName', sbomName)
      formData.append('orgId', cognito.orgId)
      http('/vendor', 'put', undefined, formData)
        .then(response => {
          if (response.data.status === false) {
            addAlert(response.data.message)
            return
          }
          needUpdate()
          setSbomFile({})
        })
        .catch(error => {
          console.log('Add Sbom error: ', error)
          addAlert('Error: spdxVersion is a required field')
          setSbomFile({})
        })
    }
  }
  const handleChange = (e) => {
    const inputValue = e.target.value
    const regex = /^[a-z][a-z0-9-]*$/g
    const isValid = regex.test(inputValue)
    if (!isValid) {
      setValid(false)
      setErrorMessage('Please use only a-z, 0-9, hyphen (-) symbols')
    }
    if (isValid) {
      setValid(true)
      setErrorMessage('')
    }
    setSbomName(inputValue)
  }

  const handleFileChange = (event) => {
    setSbomFile(event.target.files[0])
  }

  return (
    <Modal centered size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><h5>Add SBOM</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="sbomName">
            <Form.Label>SBOM Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="example-sbom"
              autoFocus
              value={sbomName}
              onChange={handleChange}
            />
            <div className='validation-field text-danger my-1'>
              <small>{errorMessage}</small>
            </div>
          </Form.Group>
          <Form.Group controlId="addJSON" className="mb-3">
            <Form.Label
              className='fw-light'
            >
              Please notice that the JSON must be a properly formatted SPDX or CycloneDX SBOM.
            </Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="info"
          disabled={!valid}
          onClick={addVendorSbom}
        >
          Add
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AddVendorSbomModal.propTypes = {
  // onUserChange: PropTypes.any,
  needUpdate: PropTypes.func,
  addAlert: PropTypes.func,
  show: PropTypes.bool,
  handleClose: PropTypes.func
}
