import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Container,
  Form,
  Alert
} from 'react-bootstrap'

import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import SubmitButton from '../SubmitButton'

function VerifyEmail({ email, setCodeSent, from }) {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm({
    defaultValues: {
      email: false
    },
    criteriaMode: 'all',
    mode: 'onChange'
  })

  const onSubmit = async (data) => {
    clearErrors('root.serverError')
    verifyEmail(data)
  }

  async function verifyEmail() {
    setLoading(true)
    try {
      // To initiate the process of verifying the attribute like 'phone_number' or 'email'
      const response = await Auth.verifyCurrentUserAttribute('email')
      // console.log('a verification code is sent', response)
      setCodeSent(response)
      setLoading(false)
    } catch (error) {
      setError('root.serverError', {
        type: 400,
        message: error.message
      })
      setLoading(false)
    }
  }

  return (
    <>
      <h6 className='login-header__login'>Verify account?</h6>
      <Container as={Form} onSubmit={handleSubmit(onSubmit)} fluid className='d-grid gap-3'>
        <p className='lead mb-0'>Account recovery requires verified contact information.</p>

        <Form.Check id='check-email' className='my-3'>
          <Form.Check.Input
            isInvalid={errors?.email}
            type='checkbox'
            {...register('email', {
              required: {
                value: true,
                message: 'Please select an email.'
              }
            })}
          />
          <Form.Check.Label>{email}</Form.Check.Label>
          <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
        </Form.Check>

        <Alert
          show={errors?.root?.serverError?.type === 400}
          variant='danger'
          className='mb-0'
        >
          {errors.root?.serverError?.message}
        </Alert>

        <SubmitButton
          rule={errors?.email?.message}
          loading={loading}
        >
          Send Code
        </SubmitButton>

        <Form.Text as={Link} to={from} className='hoverable-link__login'>Skip</Form.Text>
      </Container>
    </>
  )
}

VerifyEmail.propTypes = {
  setCodeSent: PropTypes.func,
  from: PropTypes.string,
  email: PropTypes.string
}

export default VerifyEmail
