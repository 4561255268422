import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpider } from '@fortawesome/free-solid-svg-icons'
import Markdown from 'marked-react'
import { Modal, Button, Badge, Stack } from 'react-bootstrap'

const VulnerabilityModal = ({ vuln, isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false)

  const severityBadges = {
    critical: <Badge bg="danger" className='mt-1'>critical</Badge>,
    high: <Badge bg="warning" className='mt-1' text="dark">high</Badge>,
    moderate: <Badge bg="info" className='mt-1'>moderate</Badge>,
    low: <Badge bg="secondary" className='mt-1'>low</Badge>,
    info: <Badge bg="dark" className='mt-1'>info</Badge>
  }

  const cweLink = (cwe) => {
    const [, cweNumber] = cwe.split('-')
    return `https://cwe.mitre.org/data/definitions/${cweNumber}.html`
  }

  return (
    <Modal scrollable size='xl' show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className='mb-0'>
            <FontAwesomeIcon icon={faSpider} /> {vuln.packageName}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='vulnerability-modal'>
        {vuln.vulnerabilities && vuln.vulnerabilities.length > 0 &&
          vuln.vulnerabilities.map((vulnerability, index) => (
            <div key={vulnerability.id}>
              {/* <img src="/static/image/npm.png" style={{ width: 32 }} /> */}
              <Stack
                direction="horizontal"
                gap={2}
                className="align-items-start mb-4"
              >
                {severityBadges[vulnerability.ratings.severity]}
                {/* &nbsp; */}
                {' '}
                <h3 className="mb-0">{vulnerability.description}</h3>
              </Stack>
              {vulnerability.cwes && vulnerability.cwes.length > 0 &&
                vulnerability.cwes.map((cwe, j) => {
                  const link = cweLink(cwe)
                  return (
                    <Stack
                      key={`cwe-${vulnerability.id}-${cwe}-${j}`}
                      direction="horizontal"
                      gap={2}
                      className="align-items-baseline mb-2"
                    >
                      <Badge bg="dark">
                        <a className="custom-link" href={link} target='_blank' rel="noreferrer">{cwe}</a>
                      </Badge>
                    </Stack>
                  )
                })}
              <div className="my-4 custom-card-light">
                <div className="mb-3">
                  <span className='mb-2 me-2'>Repository:</span>
                  <span className='font-monospace custom-code-view'>
                    {vuln.repo}
                  </span>
                </div>
                {vulnerability.introduced &&
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="align-items-baseline mb-3"
                  >
                    <span>Introduced:</span><Badge pill bg="dark">{vulnerability.introduced}</Badge>
                  </Stack>
                }
                {
                  vulnerability.fixed &&
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="align-items-baseline mb-3"
                  >
                    <span>Fixed:</span><Badge pill bg="success">{vulnerability.fixed}</Badge>
                  </Stack>
                }
                {vulnerability.versions && vulnerability.versions.length > 0 &&
                  <div className='mb-3'>
                    <div className="d-flex flex-row justify-content-flex-start align-items-baseline flex-wrap custom-gap-for-pills">
                      <span>Versions affected:</span>
                      {vulnerability.versions.map((version) => (
                        <Badge key={`versions-${vulnerability.id}-${version}`} pill bg="light" text="dark">{version}</Badge>
                      ))}
                    </div>
                  </div>
                }
              </div>
              <Markdown>{vulnerability.detail}</Markdown>
              {vulnerability.advisories && vulnerability.advisories.length > 0 &&
                <>
                  <h4 className="mt-4">Advisories</h4>
                  <ul>
                    {vulnerability.advisories.map((advisory, _i) => (
                      <li key={advisory}><a href={advisory} target="_blank" rel="noreferrer" className='link-dark link-opacity-75 link-opacity-100-hover'>{advisory}</a></li>
                    ))}
                  </ul>
                </>
              }
              <h4 className="mt-4">References</h4>
              <ul>{vulnerability.references &&
                vulnerability.references.map((reference, _i) => (
                  <li key={reference}><a href={reference} target="_blank" rel="noreferrer" className='link-dark link-opacity-75 link-opacity-100-hover'>{reference}</a></li>
                ))}
              </ul>
              {index < vuln.vulnerabilities.length - 1 && <hr className="mb-4"/>}
            </div>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

VulnerabilityModal.propTypes = {
  vuln: PropTypes.any,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default VulnerabilityModal
